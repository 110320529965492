import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-right-paginator',
  templateUrl: './right-paginator.component.html',
  styleUrls: ['./right-paginator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RightPaginatorComponent),
      multi: true,
    },
  ],
})
export class RightPaginatorComponent implements OnInit, ControlValueAccessor {
  @Input() pageSizeOptions: number[];
  control: FormControl;
  subscription: Subscription;

  onTouch = () => {};

  constructor() {
    this.control = new FormControl();
    this.subscription = new Subscription();
  }

  ngOnInit(): void {}

  registerOnChange(fn): void {
    const sub = this.control.valueChanges.subscribe((v) => fn(v));
    this.subscription.add(sub);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(pageSize: number): void {
    this.control.setValue(pageSize);
  }
}
