import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MyAccountAccountWithUserViewModel, MyAccountContainsFusionAccountWithAutoPayViewModel, MyAccountFusionAccountViewModel, MyAccountFusionClient, MyAccountUsersClient } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import { ConfirmFusionProfileDeleteDialogComponent, IConfirmFusionProfileDeleteDialog } from './confirm-fusion-profile-delete-dialog/confirm-fusion-profile-delete-dialog.component';

@Component({
  selector: 'app-my-account-fusion-account-lookup',
  templateUrl: './my-account-fusion-account-lookup.component.html',
  styleUrls: ['./my-account-fusion-account-lookup.component.css']
})
export class MyAccountFusionAccountLookupComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  constructor(private snackBarService: SnackBarService,
    private myAccountFusionClient: MyAccountFusionClient,
    private dialog: MatDialog,
    private authService: AuthService) { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toolDescription = "This tool is meant to find the Fusion Account related to the inputted Account Number. \n This tool will then show the Account information (Username, AccountName, Ids). \n We can then choose to delete the Autopay for the Fusion Account."

  isLoading = false;

  myAccountFusionAccount: MyAccountContainsFusionAccountWithAutoPayViewModel;

  get isAllowedToDeleteFusionAccount(): boolean {
    return (
      this.authService.isAllowedToDeleteFusionAutoPay
    );
  }

  get fusionAccountFound() {
    return (
      this.myAccountFusionAccount &&
      this.myAccountFusionAccount.fusionAccount
    )
  }

  accountNumber = new FormControl("", Validators.minLength(9));

  get isValidAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0 && this.accountNumber.valid;
  }

  displayedFusionAccountColumns: string[] = [
    "accountNumber",
    "enrollmentProfileId",
    "fusionMyAccountUserProfileId",
    "customerName",
    "emailAddress",
    "hasAutoPay",
    "deleteProfile"
  ];

  deleteProfile(element: MyAccountFusionAccountViewModel) {
    var profileId: number = +element.enrollmentProfileId;

    const dialogConfig = new MatDialogConfig();

    const data: IConfirmFusionProfileDeleteDialog = {
      accountNumber: element.accountNumber,
      fusionProfileId: element.enrollmentProfileId.toString()
    }

    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "600px";
    dialogConfig.maxHeight = "800px";
    dialogConfig.data = data;

    this.subscription.add(
      this.dialog
        .open(ConfirmFusionProfileDeleteDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isLoading = true;
            this.myAccountFusionClient
              .deleteFusionEnrollmentWithAutoPay(element.accountNumber, profileId, element.fusionMyAccountUserProfileId)
              .subscribe(
                x => {
                  this.isLoading = false;
                  this.snackBarService.ShowSuccess("Fusion Autopay Account deleted!");
                  this.myAccountFusionAccount = null;
                  this.accountNumber.patchValue("");
                },
                (error) => {
                  this.isLoading = false;
                  this.snackBarService.ShowError(
                    error
                  );
                }
              )
          }
        }));
  }

  search() {
    this.myAccountFusionAccount = null;
    this.isLoading = true;
    this.subscription.add(
      this.myAccountFusionClient
        .searchForMyAccountFusionEnrollmentWithAutoPay(this.accountNumber.value.trim())
        .subscribe(
          (x) => {
            this.myAccountFusionAccount = x.result;
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError(
              error
            );
          }
        ));
  }

  ngOnInit() {
  }

}
