import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-left-paginator',
  templateUrl: './left-paginator.component.html',
  styleUrls: ['./left-paginator.component.scss'],
})
export class LeftPaginatorComponent implements OnInit {
  @Input() total: number;
  @Input() pageSize: number;
  @Input() currentPageIndex: number;
  @Output() changePageNumber: EventEmitter<number> = new EventEmitter<number>();

  pageChange(pageIndex: number): void {
    setTimeout(() => {
      this.changePageNumber.emit(pageIndex);
    }, 0);
  }

  constructor() { }

  ngOnInit() {

  }

}
