import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { AreaFeeViewModel, AreaServiceViewModel, BusinessUnitViewModel, Ecommerce_AreaClient, Ecommerce_FeeDetailsViewModel, Ecommerce_ServiceCodeDetailsViewModel, Ecommerce_ServiceFrequencyDetailsViewModel, Ecommerce_ServiceTypeDetailsViewModel, LookupClient, SR_BillingCycleClient, SR_BillingCycleDetailsViewModel, UpdateAreaCommand } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import esri = __esri;
import { NgZone} from '@angular/core';
import { LatLng } from '../../map/map.model';


@Component({
  selector: "app-ecommerce-areas-details-page",
  templateUrl: "./areas-details.component.html",
  styleUrls: ["./areas-details.component.scss"],
})
export class AreasDetailsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  id: number;
  isLoading : boolean;
  loaded = false;

  isBusinessUnitsLoading: boolean;
  businessUnits: BusinessUnitViewModel[];
  businessUnitFormControl = new FormControl();

  isBillingCyclesLoading: boolean;
  billingCycles: SR_BillingCycleDetailsViewModel[];
  billingCycleFormControl = new FormControl();

  allFees : Ecommerce_FeeDetailsViewModel[];
  allServiceTypes : Ecommerce_ServiceTypeDetailsViewModel[];
  allServiceCodes : Ecommerce_ServiceCodeDetailsViewModel[];
  allServiceFrequencies : Ecommerce_ServiceFrequencyDetailsViewModel[];

  center = new LatLng();

  originalServiceArea : string = "";

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    serviceArea: new FormControl(""),
    name: new FormControl(""),
    businessUnitId: new FormControl(""),
    type: new FormControl(""),
    handledByMuni: new FormControl(false),
    monthsUpFront: new FormControl(0),
    inTower: new FormControl(false),
    marketingApproved: new FormControl(false),
    serviceAreaApproved: new FormControl(false),
    isActive: new FormControl(false),
    signupMessage: new FormControl(""),
    isPrimary: new FormControl(false),
    hasContact: new FormControl(false),
    billingCycle: new FormControl(""),
    startDate: new FormControl(null),
    areaServices: new FormArray([]),
    areaFees: new FormArray([]),
    removedAreaServices: new FormArray([]),
    shape : new FormControl(""),
    projectName: new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private ecommerce_areaClient: Ecommerce_AreaClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lookupClient: LookupClient,
    private sr_billingCycle: SR_BillingCycleClient,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params["id"];

    this.loadAllFees();
    this.loadAllServiceTypes();
    this.loadAllServiceCodes();
    this.loadAllServiceFrequencies();
    this.loadCurrentAreaFees();
    this.loadCurrentAreaServices();
    this.loadDetails();

  }

  loadCurrentAreaServices()
  {
    this.ecommerce_areaClient.getAreaServices(this.id).subscribe(
      (response) => {
        const currentAreaServices = response.result;
        this.areaServices.clear();
        for (const p of currentAreaServices)
        {
          const gControl = new FormGroup({
            areaServiceId: new FormControl(p.areaServiceId),
            serviceTypeId : new FormControl(p.serviceTypeId),
            serviceName : new FormControl(p.serviceName),
            serviceCodeId : new FormControl(p.serviceCodeId),
            serviceCode : new FormControl(p.serviceCode),
            serviceFrequencyId : new FormControl(p.serviceFrequencyId),
            serviceFrequency : new FormControl(p.serviceFrequency),
            servicePrice: new FormControl(p.servicePrice),
            isRequired: new FormControl(p.isRequired),
          });
          this.areaServices.push(gControl);
        }
      }
    )
  }

  getServiceAreaMap()
  {
    if(this.originalServiceArea != null && this.originalServiceArea != "")
    {
      this.ecommerce_areaClient.getServiceAreaMap(this.formGroup.value.serviceArea).subscribe(
        (response) => {
          if(response.result == null || !response.result.shape)
          {
            this.originalServiceArea = "";
          } 
          else{
            this.formGroup.patchValue({
              shape: response.result.shape,
              projectName: response.result.projectName
            });
          }
          
          this.loaded = true;
        },
        (err) => 
        {
          this.originalServiceArea = "";
          this.loaded = true;
        }
      )
    }
    
  }

  addAreaServices()
  {
    const pGroupControl = new FormGroup({
            id : new FormControl(0),
            areaServiceId : new FormControl(0),
            serviceTypeId : new FormControl(0),
            serviceName : new FormControl(""),
            serviceCodeId : new FormControl(0),
            serviceCode : new FormControl(""),
            serviceFrequencyId : new FormControl(0),
            serviceFrequency : new FormControl(""),
            servicePrice: new FormControl(0),
            isRequired: new FormControl(false),
    });
    this.areaServices.insert(0, pGroupControl);
  }

  get areaServices() {
    return this.formGroup.get("areaServices") as FormArray;
  }

  get removedAreaServices() {
    return this.formGroup.get("removedAreaServices") as FormArray;
  }


  removeAreaServices(x, buControl)
  {
    const gControl = new FormGroup({
      areaServiceId: new FormControl(buControl.areaServiceId.value),
      serviceTypeId : new FormControl(buControl.serviceTypeId.value),
      serviceName : new FormControl(buControl.serviceName.value),
      serviceCodeId : new FormControl(buControl.serviceCodeId.value),
      serviceCode : new FormControl(buControl.serviceCode.value),
      serviceFrequencyId : new FormControl(buControl.serviceFrequencyId.value),
      serviceFrequency : new FormControl(buControl.serviceFrequency.value),
      servicePrice: new FormControl(buControl.servicePrice.value),
      isRequired: new FormControl(buControl.isRequired.value),
    });
    this.removedAreaServices.push(gControl);
    this.areaServices.removeAt(x);
  }

  getAreaServiceId (p: any)
  {
    return p.id;
  }

  getAreaServiceName (p: any) {
    return p.serviceName;
  }

  getAreaFrequencyId (p: any)
  {
    return p.id;
  }

  getAreaServiceFrequencyName (p: any) {
    return p.serviceFrequency;
  }

  getAreaServiceCodeId (p: any)
  {
    return p.id;
  }

  getAreaServiceCodeName (p: any) {
    return p.serviceCode;
  }

  loadCurrentAreaFees()
  {
    this.ecommerce_areaClient.getAreaFees(this.id).subscribe(
      (response) => {
        const currentAreaFees = response.result;
        this.areaFees.clear();
        for (const p of currentAreaFees)
        {
          const gControl = new FormGroup({
            id : new FormControl(p.areaFeeId),
            feeId : new FormControl(p.feeId),
            feeName: new FormControl(p.feeName),
            price: new FormControl(p.price),
          });
          this.areaFees.push(gControl);
        }
      }
    )
  }

  addAreaFees()
  {
    const pGroupControl = new FormGroup({
      id: new FormControl(0),
      feeId: new FormControl(0),
      feeName: new FormControl(null),
      price: new FormControl(0),
    });
    this.areaFees.insert(0, pGroupControl);
  }

  get areaFees() {
    return this.formGroup.get("areaFees") as FormArray;
  }

  removeAreaFees(x)
  {
    this.areaFees.removeAt(x);
  }

  getAreaFeeId (p: any)
  {
    return p.id;
  }

  getAreFeeName (p: any) {
    return p.feeName;
  }



  loadAllServiceTypes()
  {
    this.lookupClient.getAllServiceTypes().subscribe(
      (response) => {
        this.allServiceTypes = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadAllFees()
  {
    this.lookupClient.getAllFees().subscribe(
      (response) => {
        this.allFees = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadAllServiceCodes()
  {
    this.lookupClient.getAllServiceCodes().subscribe(
      (response) => {
        this.allServiceCodes = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadAllServiceFrequencies()
  {
    this.lookupClient.getAllServiceFrequencies().subscribe(
      (response) => {
        this.allServiceFrequencies = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }


  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result;
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching business units error: " + err);
          }
        }
      )
    );
  }

  loadBillingCycles() {
    this.isBillingCyclesLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBillingCycles().subscribe(
        (result) => {
          this.billingCycles = result.result;
          this.isBillingCyclesLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching Billing Cycles error: " + err);
          }
        }
      )
    );
  }

  getBillingCycleId = (bu: SR_BillingCycleDetailsViewModel) => bu.id;

  getBillingCycleName = (bu: SR_BillingCycleDetailsViewModel) =>
    `${bu.billingCycle} (${bu.billMonthName})`;

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;


  loadDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.ecommerce_areaClient.getAreaDetails(
        this.id
      ).subscribe(
        (result) =>
        {
          const details = result.result;
          this.formGroup.patchValue({
            id: details.id,
            uniqueId : details.uniqueId,
            serviceArea: details.serviceArea,
            name: details.name,
            type: details.type,
            handledByMuni: details.handledByMuni,
            monthsUpFront: details.monthsUpFront,
            inTower: details.inTower,
            marketingApproved: details.marketingApproved,
            serviceAreaApproved: details.serviceAreaApproved,
            isActive: details.isActive,
            signupMessage: details.signupMessage,
            isPrimary: details.isPrimary,
            hasContact: details.hasContact,
            startDate: this.formatDate(details.startDate),
          });
          this.businessUnitFormControl.patchValue(
            details.businessUnitId
          );
          this.billingCycleFormControl.patchValue(
            details.billingCycleId
          );
          this.originalServiceArea = details.serviceArea;
          this.getBusinessUnits();
          this.loadBillingCycles();
          this.getServiceAreaMap();
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/areas");
  }

  update()
  {
    this.isLoading = true;
    const area = new UpdateAreaCommand({
    id: this.id,
    serviceArea: this.formGroup.value.serviceArea,
    name: this.formGroup.value.name,
    businessUnitId: this.businessUnitFormControl.value,
    type: this.formGroup.value.type,
    handledByMuni: this.formGroup.value.handledByMuni,
    monthsUpFront: this.formGroup.value.monthsUpFront,
    inTower: this.formGroup.value.inTower,
    marketingApproved: this.formGroup.value.marketingApproved,
    serviceAreaApproved: this.formGroup.value.serviceAreaApproved,
    isActive: this.formGroup.value.isActive,
    signupMessage: this.formGroup.value.signupMessage,
    isPrimary: this.formGroup.value.isPrimary,
    hasContact: this.formGroup.value.hasContact,
    billingCycleId: this.billingCycleFormControl.value,
    startDate: this.formGroup.value.startDate == "" ? null : this.formGroup.value.startDate,
    areaServices : this.areaServices.controls.map(
      (x) => new AreaServiceViewModel({
        areaServiceId: x.value.areaServiceId,
        areaId: this.id,
        serviceTypeId : x.value.serviceTypeId,
        serviceName: x.value.serviceName,
        serviceCode: x.value.serviceCode,
        serviceCodeId: x.value.serviceCodeId,
        serviceFrequency: x.value.serviceFrequency,
        serviceFrequencyId: x.value.serviceFrequencyId,
        servicePrice: x.value.servicePrice,
        isRequired: x.value.isRequired,
      })
    ),
    deletedAreaServices : this.removedAreaServices.controls.map(
      (x) => new AreaServiceViewModel({
        areaServiceId: x.value.areaServiceId,
        areaId: this.id,
        serviceTypeId : x.value.serviceTypeId,
        serviceName: x.value.serviceName,
        serviceCode: x.value.serviceCode,
        serviceCodeId: x.value.serviceCodeId,
        serviceFrequency: x.value.serviceFrequency,
        serviceFrequencyId: x.value.serviceFrequencyId,
        servicePrice: x.value.servicePrice,
        isRequired: x.value.isRequired,
      })
    ),
    areaFees : this.areaFees.controls.map(
      (x) => new AreaFeeViewModel({
        areaFeeId: x.value.id,
        areaId: this.id,
        feeId: x.value.feeId,
        price: x.value.price
      })
    )
    });
    this.subscription.add(
        this.ecommerce_areaClient.updateArea(
            area
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.name + " has been updated successfully !");
                window.location.reload();
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Update Area Error: " + error);
        }
        )
    );
  }

  onDeleteClicked() {
    if (confirm("This will delete this area. Please confirm.")) {
      this.isLoading = true;
    this.subscription
      .add(
        this.ecommerce_areaClient
          .deleteArea(this.formGroup.value.uniqueId)
          .subscribe(() => {
            this.snackBarService.ShowSuccess("Area sucessfully deleted");
            this.router.navigateByUrl("/ecommerce/areas");
          },
            (error) => {
              try {
                this.snackBarService.ShowError(error);
              }
              catch { }
              this.isLoading = false;
            })
      );
    }
  }

  private formatDate(input: Date) {
    if(input == null) return null;
    let dateInLocal = new Date(input);
    let d = new Date(dateInLocal.getUTCFullYear(), dateInLocal.getUTCMonth(), dateInLocal.getUTCDate());
    let datestring = d.getFullYear().toString().padStart(4, '0') + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    return datestring;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}