import { Component, OnDestroy, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { concat, forkJoin, OperatorFunction, Subscription } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import {
  MyAccountUsersClient,
  SimpleTransactionResponse,
  MassTransferAccountOwnershipCommand,
  MyAccountMassTransferSearchResultViewModelICollectionResultResponse as MassTransferSearchResponse,
} from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-my-account-mass-transfer-ownership',
  templateUrl: './my-account-mass-transfer-ownership.component.html',
  styleUrls: ['./my-account-mass-transfer-ownership.component.css'],
})
export class MyAccountMassTransferOwnershipComponent implements OnDestroy {

  @Input() massSelected: boolean;

  sourceEmailAddress = new FormControl('', Validators.required);
  destinationEmailAddress = new FormControl('', Validators.required);

  lastEnteredSourceEmailAddress = '';
  lastEnteredDestinationEmailAddress = '';
  subscription = new Subscription();
  isLoading = false;
  accounts = [];

  constructor(
    private snackbarService: SnackBarService,
    private myAccountUserClient: MyAccountUsersClient
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get isSearchDisabled() {
    return !this.sourceEmailAddress.valid || this.isLoading;
  }

  get isTransferDisabled() {
    return !this.destinationEmailAddress.valid || this.isAllDeselected || this.isLoading;
  }

  get selectedAccountIds() {
    return this.accounts.filter((x) => x.checked).map((x) => x.accountUniqueId);
  }

  get noAccountsFound() {
    return this.accounts.length === 0;
  }

  get isAllSelected() {
    return this.selectedAccountIds.length === this.accounts.length;
  }

  get isAllDeselected() {
    return this.selectedAccountIds.length === 0;
  }

  selectAllAccounts() {
    this.accounts = this.accounts.map((x) => ({ ...x, checked: true }));
  }

  deselectAllAccounts() {
    this.accounts = this.accounts.map((x) => ({ ...x, checked: false }));
  }

  formatAccountAddress(account) {
    const { addressLine1, addressLine2, addressCity, addressProvince, addressPostal } = account;

    let formattedAddress = '';
    formattedAddress += addressLine1 ? `${addressLine1}\n` : '';
    formattedAddress += addressLine2 ? `${addressLine2}\n` : '';

    if (addressCity && addressProvince) {
      formattedAddress += `${addressCity}, ${addressProvince} ${addressPostal}`;
    } else {
      formattedAddress += `${addressCity || addressProvince || ''} ${addressPostal}`.trim();
    }

    return formattedAddress;
  }

  validateProfileCount$(emailAddress: string) {
    return this.myAccountUserClient
      .massTransferFindUserProfiles(emailAddress)
      .pipe(
        tap((res) => {
          const profileCount = new Set(res.result.map((x) => x.oid)).size;

          if (profileCount === 0) {
            throw new Error('No MyAccount profiles found for the given email address.');
          } else if (profileCount > 1) {
            throw new Error('More than one MyAccount profiles found for the given email address.');
          }
        })
      );
  }

  search() {
    this.accounts = [];
    this.isLoading = true;
    this.lastEnteredSourceEmailAddress = this.sourceEmailAddress.value;

    const fetchAccounts$ = this.myAccountUserClient
      .massTransferSearchForAccounts(this.lastEnteredSourceEmailAddress)
      .pipe(
        tap((res) => {
          this.accounts = res.result;
          if (this.accounts.length === 0) {
            throw new Error('No accounts found for the given email address.');
          }
        })
      );

    this.subscription.add(
      concat(
        this.validateProfileCount$(this.lastEnteredSourceEmailAddress),
        fetchAccounts$
      ).subscribe(
        () => {},
        (err) => {
          this.isLoading = false;
          if (err instanceof Error) {
            this.snackbarService.ShowError(err.message);
          } else {
            this.snackbarService.ShowError(err);
          }
        },
        () => {
          this.isLoading = false;
        }
      )
    );
  }


  transfer() {
    this.isLoading = true;
    this.lastEnteredDestinationEmailAddress = this.destinationEmailAddress.value;

    const fetchUniqueIds: OperatorFunction<unknown, [string, string]> =
      concatMap(() =>
        forkJoin(
          this.myAccountUserClient
            .findAllAccounts(this.lastEnteredSourceEmailAddress)
            .pipe(map((x) => x.result[0].uniqueId)),
          this.myAccountUserClient
            .findAllAccounts(this.lastEnteredDestinationEmailAddress)
            .pipe(map((x) => x.result[0].uniqueId))
        )
      );



    const executeMassTransfer: OperatorFunction<[string, string], SimpleTransactionResponse> =
      concatMap(([sourceUserId, destinationUserId]) =>
        this.myAccountUserClient.massTransferAccountOwnership(
          new MassTransferAccountOwnershipCommand({
            sourceUserId,
            destinationUserId,
            accountIds: this.selectedAccountIds,
          })
        )
      );

    const fetchAccounts: OperatorFunction<unknown, MassTransferSearchResponse> =
      concatMap(() =>
        this.myAccountUserClient
          .massTransferSearchForAccounts(this.lastEnteredSourceEmailAddress)
          .pipe(tap((res) => (this.accounts = res.result)))
      );

    this.subscription.add(
      this.validateProfileCount$(this.lastEnteredDestinationEmailAddress)
        .pipe(fetchUniqueIds, executeMassTransfer, fetchAccounts)
        .subscribe(
          () => {},
          (err) => {
            this.isLoading = false;
            if (err instanceof Error) {
              this.snackbarService.ShowError(err.message);
            } else {
              this.snackbarService.ShowError(err);
            }
          },
          () => {
            this.isLoading = false;
            this.snackbarService.ShowSuccess('Accounts successfully transferred.');
            this.massSelected = false;
            window.location.reload();
          }
        )
    );
  }
}
