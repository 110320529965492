import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import {
  AssignToUserViewModel,
  BusinessUnitViewModel,
  LookupClient,
  RequestsClient,
  RequestStatus,
  RequestType,
  RequestViewModel,
  UpdateAssignedToCommand,
  UpdateBusinessUnitCommand,
  UpdateStatusCommand,
  UpdateAssigneeNoteCommand,
  UsersClient,
  RequestDetailsViewModel,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { CloseRequestNotesDialogComponent, ICloseRequestNotesDialog } from "./close-request-notes-dialog/close-request-notes-dialog.component";


interface ICloseRequestResponse {
  erpCustomerId: string;
  notes: string;
}

@Component({
  selector: "app-request-details-page",
  templateUrl: "./request-details-page.component.html",
  styleUrls: ["./request-details-page.component.scss"],
})
export class RequestDetailsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  requestId: number;

  isRequestLoading: boolean;
  request: RequestViewModel;

  isBusinessUnitsLoading: boolean;
  businessUnits: BusinessUnitViewModel[];

  isAssignableUsersLoading: boolean;
  assignToReps: AssignToUserViewModel[];

  faPhone = faPhone;
  faMail = faEnvelope;

  businessUnitFormControl = new FormControl();
  assignToFormControl = new FormControl();

  isCloseRequestLoading: boolean;
  closeRequestFormControl = new FormControl(null);
  reasons = [];

  isUserNoteLoading: boolean;
  userNoteFormControl = new FormControl();

  overflow : boolean = false;

  showTransaction = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    public location: Location,
    private requestsClient: RequestsClient,
    private userClient: UsersClient,
    private lookupClient: LookupClient,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.requestId = this.activatedRoute.snapshot.params["requestId"];
    this.loadRequest();
    this.getBusinessUnits();
  }

  displayedRequestDetailsColumns: string[] = [
    "itemName",
    "promoCode",
    "basePrice",
    "discount",
    "itemPrice",
  ];

  showTransactions()  {
    this.showTransaction = !this.showTransaction;
  }

  initializeReasons() {
    if (this.request.type == RequestType.NewService) {
      if (this.request.canNewServiceBeCancelled) {
        this.reasons = [
          {
            value: RequestStatus.ClosedCompletedAccepted,
            name: "closed_completed_accepted",
          },
          {
            value: RequestStatus.ClosedPaymentRefunded,
            name: "closed_payment_refunded",
          },
          {
            value: RequestStatus.ClosedPaymentCancelled,
            name: "closed_payment_cancelled",
          },
        ];
      } else {
        this.reasons = [
          {
            value: RequestStatus.ClosedCompletedAccepted,
            name: "closed_completed_accepted",
          },
          {
            value: RequestStatus.ClosedPaymentRefunded,
            name: "closed_payment_refunded",
          },
        ];
      }
    } else {
      this.reasons = [
        {
          value: RequestStatus.ContactedCustomer,
          name: "contacted_customer",
        },
        {
          value: RequestStatus.Cancelled,
          name: "cancelled",
        },
        {
          value: RequestStatus.ClosedCompletedAccepted,
          name: "closed_completed_accepted",
        },
        {
          value: RequestStatus.ClosedRejectedNoAction,
          name: "closed_rejected_no_action",
        },
      ];
    }
  }

  getStatusName(status): string {
    switch (status) {
      case RequestStatus.Submitted:
        return "submitted";
      case RequestStatus.Cancelled:
        return "cancelled";
      case RequestStatus.ClosedCompletedAccepted:
        return "closed_completed_accepted";
      case RequestStatus.ClosedPaymentCancelled:
        return "closed_payment_cancelled";
      case RequestStatus.ClosedPaymentRefunded:
        return "closed_payment_refunded";
      case RequestStatus.ClosedRejectedNoAction:
        return "closed_rejected_no_action";
      case RequestStatus.ContactedCustomer:
        return "contacted_customer";
      default:
        return "";
    }
  }


  canEdit(): boolean {
    if (this.request) {
      if (
        this.request.status == RequestStatus.Submitted ||
        this.request.status == RequestStatus.Cancelled ||
        this.request.status == RequestStatus.ContactedCustomer
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  getContactUsOptions() {
    let contactoptions: string[] = [];
    const options = JSON.parse(this.request.additionalRequestDetailsJson);

    if(this.request.paymentConfirmationDetailsJson) {
      const paymentConfirmationDetails = JSON.parse(this.request.paymentConfirmationDetailsJson);
      var confirmCodeKey = "ConfirmCode";
      if (paymentConfirmationDetails.hasOwnProperty(confirmCodeKey)){
        const value = confirmCodeKey + ": " + paymentConfirmationDetails[confirmCodeKey];
        contactoptions.push(value);
      }            
    }

    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        if (typeof options[key] == "boolean") {
          if (options[key] == true) {
            contactoptions.push(key);
          }
        } else if (options[key] && typeof options[key] == "object") {
          let nestedValue = "";
          for (var key2 in options[key]) {
            if (options[key].hasOwnProperty(key2)) {
              if (typeof options[key][key2] == "boolean") {
                if (options[key][key2] == true) {
                  nestedValue += `<li>${key2}</li>`;
                }
              } else {
                if (
                  options[key][key2] != null &&
                  options[key][key2] != "null"
                ) {
                  nestedValue += `<li>${key2} : ${options[key][key2]}</li>`;
                }
              }
            }
          }
          const value = key + ":" + "<ul>" + nestedValue + "</ul>";
          contactoptions.push(value);
        } else {
          if (options[key]) {
            const value = key + ": " + options[key];
            contactoptions.push(value);
          }
        }
      }
    }

    return contactoptions;
  }

  loadRequest(isRequestUpdated: boolean = false) {
    this.isRequestLoading = true;
    this.subscription.add(
      this.requestsClient.getRequest(this.requestId).subscribe(
        (r) => {
          this.request = r.result;
          if(this.request.type == RequestType.NewService)
          {
            this.getTotal();
          }
          this.isRequestLoading = false;
          if (!isRequestUpdated) {
            this.businessUnitFormControl.patchValue(
              this.request.businessUnitId
            );
            this.assignToFormControl.patchValue(this.request.assignedToUserId);

            this.getAssignableUsers();
            this.initializeReasons();
          }
          //this.userNoteFormControl.patchValue(this.request.userNote);
        },
        (err) => {
          this.snackBarService.ShowError(
            "Fetching request details Error: " + err
          );
        }
      )
    );
  }

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result;
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching business units error: " + err);
          }
        }
      )
    );
  }

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  getAssignableUsers() {
    this.isAssignableUsersLoading = true;
    this.subscription.add(
      this.userClient
        .getAssignableUsersForBusinessUnit(this.request.businessUnitId)
        .subscribe(
          (r) => {
            this.assignToReps = r.result;
            this.isAssignableUsersLoading = false;
          },
          (err) => {
            this.snackBarService.ShowError(
              "Fetching Assignee List Error: " + err
            );
          }
        )
    );
  }

 
  goBack(e)
  {
    if (!this.userNoteFormControl.value)
    {
      this.location.back();
    }
    else {
      if (!confirm("You have unsaved Notes! If you wish to proceed without applying the changes, click 'OK'.")) {
        e.preventDefault();
      }
      //this.snackBarService.ShowSuccess('Changes were not saved !');
      this.location.back();
    }
  }

  onSaveNoteClicked() {
    if (!this.userNoteFormControl.value) {
      this.snackBarService.ShowError("Please enter some text !");
    }
    else {
      this.isUserNoteLoading = true;
      const updateANCommand: UpdateAssigneeNoteCommand = new UpdateAssigneeNoteCommand(
        {
          id: this.requestId,
          note: this.userNoteFormControl.value,
        }
      );
      this.subscription.add(
        this.requestsClient.employeenote(updateANCommand).subscribe(
          (response) => {
            this.isBusinessUnitsLoading = false;
            this.loadRequest(true);
            this.snackBarService.ShowSuccess(
              "User Note updated successfully !"
            );
          },
          (err) => {
            this.snackBarService.ShowError(
              "Updating User Note Error: " + err
            );
          }
        )
      );
      this.userNoteFormControl.reset();
    }

  }

  getAssignableUserId = (u: AssignToUserViewModel) => u.id;

  getAssignableUserName = (u: AssignToUserViewModel) => u.name;

  onChangeBranchClicked() {
    if (!this.businessUnitFormControl.value) {
      this.snackBarService.ShowError("Please select a Business Unit/Branch !");
    } else if (
      +this.businessUnitFormControl.value === this.request.businessUnitId
    ) {
      this.snackBarService.ShowError(
        `${this.request.businessUnitName} is already the current Business Unit/Branch !`
      );
    } else {
      this.isBusinessUnitsLoading = true;

      const updateBUCommand: UpdateBusinessUnitCommand = new UpdateBusinessUnitCommand(
        {
          id: this.requestId,
          newBusinessUnitId: this.businessUnitFormControl.value,
        }
      );

      this.subscription.add(
        this.requestsClient.businessunit(updateBUCommand).subscribe(
          (response) => {
            this.isBusinessUnitsLoading = false;
            this.snackBarService.ShowSuccess(
              "Business Unit/Branch updated successfully !"
            );

            /* Setting it false because we need to refetch assignable users again according to this BU */
            this.loadRequest(false);
          },
          (err) => {
            this.snackBarService.ShowError(
              "Updating Business Unit Error: " + err
            );
          }
        )
      );
    }
  }

  onChangeAssigneeClicked() {
    if (!this.assignToFormControl.value) {
      this.snackBarService.ShowError("Please select an Assignee !");
    } else if (
      +this.assignToFormControl.value === this.request.assignedToUserId
    ) {
      this.snackBarService.ShowError(
        `${this.request.assignedToName} is already the current Assignee !`
      );
    } else {
      this.isAssignableUsersLoading = true;

      const updateAssignee: UpdateAssignedToCommand = new UpdateAssignedToCommand(
        {
          id: this.requestId,
          newAssignedToId: +this.assignToFormControl.value,
          note: null,
        }
      );
      this.subscription.add(
        this.requestsClient.assign(updateAssignee).subscribe(
          (response) => {
            this.snackBarService.ShowSuccess("Assignee updated successfully !");
            this.isAssignableUsersLoading = false;
            this.loadRequest(true);
          },
          (err) => {
            this.snackBarService.ShowError(
              "Updating Assignee Error: " + err
            );
          }
        )
      );
    }
  }

  checkOverflow (el)
  {
    var overFlow = el.style.overflow;
    this.overflow = true;
  }

  onCloseRequestClicked() {
    if (this.closeRequestFormControl.value) {
      const dialogConfig = new MatDialogConfig();

      const data: ICloseRequestNotesDialog = {
        status: this.closeRequestFormControl.value as RequestStatus,
        type: this.request.type
      }

      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = false;
      dialogConfig.width = "600px";
      dialogConfig.maxHeight = "800px";
      dialogConfig.data = data;

      this.subscription.add(
        this.dialog
          .open(CloseRequestNotesDialogComponent, dialogConfig)
          .afterClosed()
          .subscribe((response) => {
            if (response) {
              this.confirmCloseRequest(response);
            }
          })
      );
    } else {
      this.snackBarService.ShowError("Please select a reason!");
    }
  }

  confirmCloseRequest(response: ICloseRequestResponse) {
    this.isCloseRequestLoading = true;

    const updateStatusCommand: UpdateStatusCommand = new UpdateStatusCommand({
      erpCustId: response.erpCustomerId,
      id: this.requestId,
      newStatus: this.closeRequestFormControl.value,
      note: response.notes,
    });
    this.subscription.add(
      this.requestsClient.status(updateStatusCommand).subscribe(
        (response) => {
          this.isCloseRequestLoading = false;
          this.snackBarService.ShowSuccess("Status changed successfully !");
          this.closeRequestFormControl.patchValue(null);
          this.loadRequest(true);
        },
        (err) => {
          this.snackBarService.ShowError(
            "Updating Status Error: " + err
          );
          this.isCloseRequestLoading = false;
        }
      )
    );
  }

  copyRequestInformation() {
    var requestInfo = this.getContactUsOptions();
    var copiedRequestData: string[] = [];
    var serviceAddressData: string[] = [];
    var billingAddressData: string[] = [];
    var contactInformationData: string[] = [];
    var contactAddressData: string[] = [];

    for (var key in requestInfo){
      copiedRequestData.push(`\n${requestInfo[key]}`);
    }

    for (const details in this.request.requestDetails){
      for (const [c, v] of Object.entries(this.request.requestDetails[details]))
      copiedRequestData.push(`\n${c}: ${v}`);
    }

    for (const [c, v] of Object.entries(this.request.shipToAddress)){
      serviceAddressData.push(`\n${c}: ${v}`);
    }

    for (const [c, v] of Object.entries(this.request.billToAddress)){
      billingAddressData.push(`\n${c}: ${v}`);
    }

    for (const [c, v] of Object.entries(this.request.contactAddress)){
      contactAddressData.push(`\n${c}: ${v}`);
    }

    contactInformationData.push(`\n${this.request.customerFirstName} ${this.request.customerLastName}`,
      `\nEmail: ${this.request.customerEmail}`,
      `\nPhone: ${this.request.customerPhone}`,
      `\nPreferred Contact: ${this.request.customerPreferredContact}`);

    let data = (
      "Service Address:" + serviceAddressData +
      "\n\nBilling Address:" + billingAddressData + 
      "\n\nContact Information:" + contactInformationData + 
      "\n\nContact Address:" + contactAddressData +
      "\n\nRequest Information:" + copiedRequestData
      );
    navigator.clipboard.writeText(data).then(
      success => this.snackBarService.ShowSuccess("Successfully copied request info!")
    ).catch( 
      e => this.snackBarService.ShowError("There was an issue copying the request info!")
    );
  }

  getTotal() {
    if(this.request.total && this.request.total > 0)
    {
      var totalRequest = new RequestDetailsViewModel();
      totalRequest.itemPrice = this.request.total;
      totalRequest.itemName = "Total";
      this.request.requestDetails.push(totalRequest);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
