import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationErrorClient, IntegrationErrorViewModel } from '../apis/customer-care-api.generated';
import { IIntegrationErrorService } from '../features/error-handling/error-handling';
import { IntegrationErrorTableModel } from './models/entities';

@Injectable()
export class IntegrationErrorService implements IIntegrationErrorService {
  constructor(private integrationErrorClient: IntegrationErrorClient) {}

  search(): Observable<IntegrationErrorTableModel[]> {
    // Filter currently undefined as filters hasnt been implemented yet
    return this.integrationErrorClient.searchIntegrationErrors(undefined, undefined, undefined, undefined).pipe(
      map(t => t.result.map(x => ({
            id: x.id,
            status: x.status,
            category: x.errorCategory,
            errorType: x.errorType,
            errorDescription: x.errorDescription,
            date: x.date,
            errorMessage: x.errorMessage
          })))
    );
  }

  integrationErrorDetails(id: number): Observable<IntegrationErrorViewModel> {
    return this.integrationErrorClient.integrationErrorDetails(id);
  }

  resubmitMessage(id: number): Observable<void> {
    return this.integrationErrorClient.resubmitMessage(id);
  }
  resolveMessage(id: number): Observable<void> {
    return this.integrationErrorClient.resolve(id);
  }
}
