import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { Ecommerce_PromoCodeSortBySortBy, Ecommerce_PromoCodeFilterByFilterBy, Ecommerce_PromoCodeDetailsViewModel, Ecommerce_PromoCodeClient, Ecommerce_PromoCodeSortBy, Ecommerce_PromoCodeFilterBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-promo-codes-lookup-page",
  templateUrl: "./promo-codes-lookup.component.html",
  styleUrls: ["./promo-codes-lookup.component.scss"],
})
export class PromoCodesLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  pageIndex: number;
  pageSize: number;
  sortBy: Ecommerce_PromoCodeSortBySortBy[] = [];
  filterBy: Ecommerce_PromoCodeFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate : boolean;

  records: Ecommerce_PromoCodeDetailsViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    durationType: new FormControl(null),
    discountType: new FormControl(null),
    code: new FormControl(null),
  });



  constructor(
    private snackBarService: SnackBarService,
    private serviceTypeClient: Ecommerce_PromoCodeClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToCreateEcommerceFees;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadPromoCodes();
  }

  loadPromoCodes()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.serviceTypeClient.promoCodeListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new Ecommerce_PromoCodeSortBySortBy({
        property: Ecommerce_PromoCodeSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new Ecommerce_PromoCodeFilterByFilterBy({
          property: Ecommerce_PromoCodeFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.discountType
    ) {
      this.filterBy.push(
        new Ecommerce_PromoCodeFilterByFilterBy({
          property: Ecommerce_PromoCodeFilterBy.DiscountType,
          contains: this.filterFormGroup.value.discountType
        })
      );
    }

      if (
        this.filterFormGroup.value.durationType
      ) {
        this.filterBy.push(
          new Ecommerce_PromoCodeFilterByFilterBy({
            property: Ecommerce_PromoCodeFilterBy.DurationType,
            contains: this.filterFormGroup.value.durationType
          })
        );
    }

    if (
      this.filterFormGroup.value.code
    ) {
      this.filterBy.push(
        new Ecommerce_PromoCodeFilterByFilterBy({
          property: Ecommerce_PromoCodeFilterBy.Code,
          contains: this.filterFormGroup.value.code
        })
      );
  }

  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadPromoCodes();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      discountType: null,
      durationType: null,
      code: null,
    });
    this.loadPromoCodes();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadPromoCodes();
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}