import { Component, ElementRef, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FilterLayers } from './map.model';
import { Subscription } from 'rxjs';
import { EsriModuleLoader } from './esri-module.loader';
import { EsriTypeFactory } from './esri-type.factory';
import { EsriModuleEnum } from './map.model';
import { MapService } from './map.service';
import { MapConfig } from './config';
import { Polygon, ServiceZonex } from './serviceareas';
import esri = __esri;
import { EsriMap } from './esri-map';
import { __read } from 'tslib';
@Component({
  selector: 'map-common',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  shape: string;

  @ViewChild('map', { static: true, read: ElementRef })
  map: ElementRef;
  esriMap: EsriMap;
  mapView: esri.MapView;
  mapInit;

  graphicLayers = new FilterLayers();


  mapFilterByBusinessUnits = false;

  subscription = new Subscription();
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private esriTypeFactory: EsriTypeFactory,
    private esriModuleLoader: EsriModuleLoader,
    private mapService: MapService,
  ) { }

  async ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.mapInit = this.initEsri();
    });
  }

  ngOnDestroy() {
    if (this.esriMap) {
      this.esriMap.destroy();
    }
    if (this.mapView) {
      if (this.mapView.layerViews) {
        this.mapView.layerViews.removeAll();
      }
      this.mapView.map = null;
      this.mapView.destroy();
    }
    if (this.graphicLayers) {
      if (this.graphicLayers.serviceZone) {
        this.graphicLayers.serviceZone.destroy();
      }
    }

    this.subscription.unsubscribe();
  }


  async initEsri() {
    await this.esriModuleLoader.loadModules([EsriModuleEnum.Map, EsriModuleEnum.MapView, EsriModuleEnum.GraphicsLayer, EsriModuleEnum.Graphic]);

    this.esriMap = await this.mapService.createMap(
      this.map.nativeElement,
      {
        zoom: 5
      },
      'streets-navigation-vector'
    );
    MapConfig.init(this.esriTypeFactory, this.esriModuleLoader, this.esriMap.mapView);

    this.addGraphicLayers();
    const mapFilter = document.getElementById('map-filter');
    const mapSelector = document.getElementById('map-selector');
    if (mapFilter) {
      this.esriMap.mapView.ui.add(mapFilter, 'manual');
    }

    if (mapSelector) {
      this.esriMap.mapView.ui.add(mapSelector, 'bottom-right');
    }

    this.drawServiceZone(this.shape);
    this.addGraphicLayers();

    return this;
  }

  async ngOnChanges(changes) {
  }

  addGraphicLayers() {
    this.graphicLayers.serviceZone = this.esriTypeFactory.create<esri.GraphicsLayer>(EsriModuleEnum.GraphicsLayer);

    this.esriMap.map.add(this.graphicLayers.serviceZone);
  }

  async drawServiceZone(data) {
    if (await this.esriMap) {
      this.graphicLayers.serviceZone.removeAll();
      if (data.length > 0) {
        ServiceZonex.markZones(data, this.graphicLayers.serviceZone);
        this.esriMap.mapView.goTo(this.graphicLayers.serviceZone.graphics).catch(error => {
          console.log('Warning: Goto is inerrupted ');
        });
      }
    }
  }

  onBasemapChange(event: string) {
    if (this.esriMap) {
      this.esriMap.map.basemap = event as any;
    }
  }
}
