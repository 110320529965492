import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  BusinessUnitViewModel,
  RequestsClient,
  RequestStatsQuery,
  RequestStatsQueryModel,
  UsersClient,
} from "src/app/apis/customer-care-api.generated";
import { formatDate } from '@angular/common';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import * as Highcharts from 'highcharts';

@Component({
  selector: "app-request-stats-page",
  templateUrl: "./request-stats-page.component.html",
  styleUrls: ["./request-stats-page.component.scss"],
})
export class RequestStatsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  highcharts = Highcharts;
  chartOptions = {};
  data = [];
  categories = [];

  fromDate: any;
  toDate: any;
  start: any;
  end: any;
  startDateChanged: boolean = false;
  endDateChanged: boolean = false;
  dateRange: number = 0;

  selectedIds = [];

  nullStats: boolean = false;

  isLoading: boolean;
  isChartLoading: boolean;
  chartCounter: number = 0;

  isBusinessUnitsLoading: boolean;
  businessUnits: BusinessUnitViewModel[];

  filterFormGroup = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    groupAll: new FormControl(false),
    selectAll: new FormControl(false),
    retrieveAll: new FormControl(false),
  });

  stats: RequestStatsQueryModel[];

  hasStats: boolean = false;

  constructor(
    private requestsClient: RequestsClient,
    private userClient: UsersClient,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.initializeDates();
    this.getBusinessUnits();
  }

  initializeDates() {
    this.filterFormGroup.value.endDate = new Date();
    this.filterFormGroup.value.startDate = new Date(new Date().setDate(new Date().getDate() - 90));
    this.toDate = [formatDate(new Date(), "yyyy-MM-dd", "en")];
    this.fromDate = [formatDate(new Date(new Date().setDate(new Date().getDate() - 90)), "yyyy-MM-dd", "en")];
  }

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.userClient.getCurrentUserBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          this.snackBarService.ShowError(
            "Fetching request stats error: " + err
          );
        }
      )
    );
  }

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  retrieveStats() {
    this.updateDates();
    this.isLoading = true;
    const request: RequestStatsQuery = new RequestStatsQuery({
      businessCodes: this.selectedIds.join(),
      startDate: new Date(this.filterFormGroup.value.startDate),
      endDate: new Date(this.filterFormGroup.value.endDate),
      groupAll: this.filterFormGroup.value.groupAll,
      allBusinessUnits: this.filterFormGroup.value.retrieveAll
    });
    this.subscription.add(
      this.requestsClient.getRequestStats(request).subscribe(
        (response) => {
          if (response.result == null || response.result.length == 0 || response.result[0].bucketSize == 0) {
            this.nullStats = true;
            this.stats = [];
          }
          else {
            this.nullStats = false;
            this.data = [];
            this.categories = [];
            this.chartCounter = 0;
            this.stats = response.result;
            this.loadData(this.stats[0]);
            this.loadChart();
          }
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.snackBarService.ShowError(error);
        }
      ));
  }

  updateDates() {
    this.start = [formatDate(this.filterFormGroup.value.startDate, "MM-dd-yyyy", "en")];
    this.end = [formatDate(this.filterFormGroup.value.endDate, "MM-dd-yyyy", "en")];
    var diffInMins = (new Date(this.end).getTime() - new Date(this.start).getTime());
    this.dateRange = Math.ceil(diffInMins / (1000 * 60 * 60 * 24));
  }

  onSelectAllChecked() {
    if (!this.filterFormGroup.value.selectAll) {
      this.selectedIds = [];
    }
    else {
      this.selectedIds = [];
      for (var i = 0; i < this.businessUnits.length; i++) {
        this.selectedIds.push(this.businessUnits[i].id);
      }
    }
  }

  onClearFiltersClicked() {
    this.filterFormGroup.patchValue({
      groupAll: false,
      selectAll: false,
      retrieveAll: false,
    });
    this.initializeDates();
    this.endDateChanged = false;
    this.startDateChanged = false;
    this.selectedIds = [];
    this.stats = null;
  }

  onGetStatsClicked() {
    if (this.selectedIds != null || this.filterFormGroup.value.selectAll || this.filterFormGroup.value.retrieveAll) {
      if (this.selectedIds.length < 1 && !this.filterFormGroup.value.retrieveAll) {
        this.hasStats = false;
        this.snackBarService.ShowError("Please select a business unit !");
      }
      else if (this.endDateChanged || this.startDateChanged) {
        if (this.endDateChanged && !this.startDateChanged) {
          this.filterFormGroup.value.startDate = new Date(new Date().setDate(new Date().getDate() - 90));
        }
        if (!this.endDateChanged && this.startDateChanged) {
          this.filterFormGroup.value.endDate = new Date();
        }
        this.hasStats = true;
        this.retrieveStats();
      }
      else {
        if (this.filterFormGroup.value.startDate < this.filterFormGroup.value.endDate) {
          this.hasStats = true;
          this.retrieveStats();
        }
        else {
          this.filterFormGroup.value.startDate = new Date(new Date().setDate(new Date().getDate() - 90));
          this.filterFormGroup.value.endDate = new Date();
          this.hasStats = true;
          this.retrieveStats();
        }
      }
    }
    else {
      this.hasStats = false;
      this.snackBarService.ShowError("Please select a business unit !");
    }

  }

  displayDays(time: number) {
    var days = Math.trunc(time / 24 / 60);
    var hours = Math.trunc(time / 60 % 24);
    var minutes = Math.trunc(time % 60);
    if (days == 0) {
      if (minutes != 0 && hours != 0) {
        return hours + ' hours ' + minutes + " minutes";
      }
      else if (hours != 0) {
        return hours + ' hours ';
      }
      return minutes + " minutes";
    }
    else {
      if (minutes != 0 && hours != 0) {
        return days + " days " + hours + ' hours ' + minutes + " minutes";
      }
      else if (minutes != 0) {
        return days + " days " + minutes + " minutes";
      }
      return days + " days " + hours + ' hours ';
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.code.indexOf(term) > -1;
  }

  onStartDateChanged() {
    this.startDateChanged = true;
    this.fromDate = [formatDate(this.filterFormGroup.value.startDate, "yyyy-MM-dd", "en")];
  }

  onEndDateChanged() {
    this.endDateChanged = true;
    this.toDate = [formatDate(this.filterFormGroup.value.endDate, "yyyy-MM-dd", "en")];
  }

  loadChart() {
    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Time To Close Request Distribution'
      },
      xAxis: {
        categories: this.categories,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Frequency'
        }
      },
      tooltip: {
        headerFormat: '<span style = "font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style = "color:{series.color};padding:0">{series.name}: </td>' +
          '<td style = "padding:0"><b>{point.y:.1f} </b></td></tr>', footerFormat: '</table>', shared: true, useHTML: true
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            color: 'black',
            formatter: function () {
              return (this.y != 0) ? this.y : "";
            }
          },
          color: '#83f52c',
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        name: 'Time To Close Request Frequency',
        data: this.data
      }]
    };
    this.isChartLoading = false;
  }

  loadData(b: RequestStatsQueryModel) {
    if (this.chartCounter < this.stats.length) {
      var isInHours = true;
      var isInDays = true;
      if (b.bucketSize * 13 < 1440) {
        isInDays = false;
        if (b.bucketSize * 13 < 60) {
          isInHours = false;
        }
      }
      for (var i = 0; i < 15; i++) {
        this.data[i] = b.buckets[i];
      }
      for (let i = 0; i < 15; i++) {
        this.convertCategories(isInDays, isInHours, i, b);
      }
      this.chartCounter++;
    }
  }

  convertCategories(isInDays: boolean, isInHours: boolean, i: number, b: RequestStatsQueryModel) {
    if (isInDays) {
      var start = b.bucketSize * i;
      var end = b.bucketSize * (i + 1);
      start = (start / 24 / 60);
      end = (end / 24 / 60);
      start = Math.round(start * 10) / 10;
      end = Math.round(end * 10) / 10;
      this.categories[i] = String(start) + "-" + String(end) + " days";
    }
    else if (isInHours) {
      var start = b.bucketSize * i;
      var end = b.bucketSize * (i + 1);
      start = (start / 60);
      end = (end / 60);
      start = Math.round(start * 10) / 10;
      end = Math.round(end * 10) / 10;
      this.categories[i] = String(start) + "-" + String(end) + " hours";
    }
    else {
      var start = b.bucketSize * i;
      var end = b.bucketSize * (i + 1);
      this.categories[i] = String(start) + "-" + String(end) + " minutes";
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
