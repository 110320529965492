import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { saveAs } from 'file-saver';
import {
    BusinessUnitViewModel,
    ReportViewModel,
    UsersClient,
    ReportsClient,
    GetReportQuery
  } from "src/app/apis/customer-care-api.generated";
  import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
  import {formatDate} from '@angular/common';
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-reports-page",
  templateUrl: "./reports-page.component.html",
  styleUrls: ["./reports-page.component.scss"],
})
export class ReportsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();


  isBusinessUnitsLoading: boolean;
  businessUnits: BusinessUnitViewModel[];

  isReportsLoading: boolean;
  reports: ReportViewModel[];

  isResultLoading: boolean;

  selectedIds = [];

  selectedReport : string;

  dateFormGroup = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null)
  });


  constructor(
    private userClient: UsersClient,
    private reportClient: ReportsClient,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.getBusinessUnits();
    this.getReports();
  }

  getReports() {
    this.isReportsLoading = true;
    this.subscription.add(
        this.reportClient.allReports().subscribe(
            (result) => 
            {
                this.reports = result.result;
                this.isReportsLoading = false;
            },
            (err) =>
            {
                this.isReportsLoading = false;
                this.snackBarService.ShowError("Get Reports Error: " + err);
            }
        )
    );
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.code.indexOf(term) > -1;
  }

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.userClient.getCurrentUserBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          this.snackBarService.ShowError("Get Business Units Error: " + err );
          this.isBusinessUnitsLoading = false;
        }
      )
    );
  }

  get isValidSearch() {
    return this.isValidReport &&  (this.selectedIds ? (this.selectedIds.length > 0 ? true : false)  : false);
  }

  get isValidReport()
  {
    return this.selectedReport ? true : false;
  }

  get isValidReportSearch()
  {
    return this.isValidReport && !this.isValidSearch;
  }

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);


    onSearchInputtedBUsClicked () {
        // user wants to search by the input
        this.isResultLoading = true;
        const request: GetReportQuery = new GetReportQuery(
            {
                uniqueId: this.selectedReport,
                businessCodes: this.selectedIds.join(),
                allBusinessUnits: false,
                startDate: this.dateFormGroup.value.startDate,
                endDate: this.dateFormGroup.value.endDate
            }
        );
        this.subscription.add(
            this.reportClient.getReport(request).subscribe(
                (response) => 
                {
                  saveAs(response.data, this.getFileName());
                  this.snackBarService.ShowSuccess("Report was successfully created");
                  this.isResultLoading = false;
                },
                (error) =>
                {
                  this.snackBarService.ShowError(error);
                  this.isResultLoading = false;
                }
            )
        );
    }

    onSearchMyBUsClicked () {
      // user wants to search by all of their BUs
      this.isResultLoading = true;
      const allBUs = [];
      for (var i = 0; i < this.businessUnits.length; i++) 
      {
        allBUs.push(this.businessUnits[i].code);
      }
        const request: GetReportQuery = new GetReportQuery(
            {
                uniqueId: this.selectedReport,
                businessCodes: allBUs.join(),
                allBusinessUnits: false,
                startDate: this.dateFormGroup.value.startDate,
                endDate: this.dateFormGroup.value.endDate
            }
        );
        this.subscription.add(
            this.reportClient.getReport(request).subscribe(
                (response) => 
                {
                  saveAs(response.data, this.getFileName());
                  this.snackBarService.ShowSuccess("Report was successfully created");
                  this.isResultLoading = false;
                },
                (error) =>
                {
                  this.snackBarService.ShowError(error);
                  this.isResultLoading = false;
                }
            )
        );
    }

    onSearchAllBUsClicked () {
      // user wants to search by all BUs
      this.isResultLoading = true;
      const request: GetReportQuery = new GetReportQuery(
          {
              uniqueId: this.selectedReport,
              businessCodes: this.selectedIds.join(),
              allBusinessUnits: true,
              startDate: this.dateFormGroup.value.startDate,
              endDate: this.dateFormGroup.value.endDate
          }
        );
        this.subscription.add(
            this.reportClient.getReport(request).subscribe(
                (response) => 
                {
                  saveAs(response.data, this.getFileName());
                  this.snackBarService.ShowSuccess("Report was successfully created");
                  this.isResultLoading = false;
                },
                (error) =>
                {
                  this.snackBarService.ShowError(error);
                  this.isResultLoading = false;
                }
            )
        );
    }

    onClearFiltersClicked() {
        this.selectedIds = [];
        this.selectedReport = null;
        this.dateFormGroup.patchValue({
          startDate: null,
          endDate: null
        });
    }


    getFileName()
    {
      var index = this.reports.findIndex(x => x.uniqueId == this.selectedReport);
      return this.reports[index].name + formatDate(new Date(), 'yyyy-MM/dd', 'en');
    }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


