import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { CreateAreaCommand, CreateBillingCycleCommand, SR_BillingCycleClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-billing-cycle-create-page",
  templateUrl: "./billing-cycle-create-page.component.html",
  styleUrls: ["./billing-cycle-create-page.component.scss"],
})
export class BillingCycleCreatePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;

  formGroup = new FormGroup({
    id: new FormControl(0),
    billMonth: new FormControl(0),
    billMonthName: new FormControl(""),
    billingCycle: new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private billingCycleClient: SR_BillingCycleClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/billing-cycles");
  }

  create()
  {
    this.isLoading = true;
    const billingCycle = new CreateBillingCycleCommand({
    billMonth: this.formGroup.value.billMonth,
    billMonthName: this.formGroup.value.billMonthName,
    billingCycle: this.formGroup.value.billingCycle,
    });
    this.subscription.add(
        this.billingCycleClient.createBillingCycle(
            billingCycle
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.billingCycle + " has been created successfully !");
            this.router.navigateByUrl("/ecommerce/billing-cycles");
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Create Billing Cycle Error: " + error);
        }
        )
    );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}