import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AssignToUserViewModel, UsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';


export interface IAssigneeDialogInputData {
  businessUnitId: number;
  assigneeUserId: number;
}

@Component({
  selector: 'app-assignee-dialog',
  templateUrl: './assignee-dialog.component.html',
  styleUrls: ['./assignee-dialog.component.scss']
})



export class AssigneeDialogComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();


  assigneeFormControl = new FormControl(null, Validators.required);

  isAssigneeListLoading: boolean;
  assignToReps: AssignToUserViewModel[];


  onAssignRequestClicked() {
    if (this.assigneeFormControl.invalid) {
      this.snackBarService.ShowError('Please select an Assignee !');
      this.assigneeFormControl.markAsDirty();
      this.assigneeFormControl.markAllAsTouched();
    } else {
      this.dialog.close(this.assigneeFormControl.value);
    }
  }

  getAssigneeList() {
    this.isAssigneeListLoading = true;

    this.subscription.add(
      this.usersClient.getAssignableUsersForBusinessUnit(this.data.businessUnitId).subscribe(response => {
        this.assignToReps = response.result;
        this.isAssigneeListLoading = false;
      },
        err => {
          this.snackBarService.ShowError('Fetching the assignee list error: ' + err);
        })
    );
  }

  constructor(private snackBarService: SnackBarService, private dialog: MatDialogRef<AssigneeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAssigneeDialogInputData,
    private usersClient: UsersClient
  ) { }

  ngOnInit() {
    this.getAssigneeList();

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
