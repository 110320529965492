import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {
  CreateMyAccountUserCommand,
  MyAccountUsersClient,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
// import{translate}
@Component({
  selector: "app-my-account-page",
  templateUrl: "./my-account-page.component.html",
  styleUrls: ["./my-account-page.component.css"],
})
export class MyAccountPageComponent implements OnDestroy {
  constructor(
    private myAccountUsersClient: MyAccountUsersClient,
    private snackBarService: SnackBarService,
    private router: Router,private translateService : TranslateService
  ) {}

  toolDescription = "This tool is meant to create an account for customers who call in or otherwise contact us and want to setup a MyAccount profile. \n This tool will create a MyAccount profile using the entered information. \n We can then impersonate using the entered email and link the customer's account to that profile.";

  private subscription: Subscription = new Subscription();
  userFormGroup = new FormGroup(
    {
      email: new FormControl("", Validators.required),
      password: new FormControl("", [
        Validators.minLength(6),
        this.checkEmptyString,
      ]),
      passwordConfirm: new FormControl(""),
      givenName: new FormControl("", Validators.required),
      surname: new FormControl("", Validators.required),
    },
    { validators: this.checkPassword }
  );

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  checkPassword(formGroup: FormGroup) {
    const password = formGroup.get("password").value;
    const passwordConfirm = formGroup.get("passwordConfirm").value;
    return password === passwordConfirm ? null : { notSame: true };
  }

  create() {
    const data = new CreateMyAccountUserCommand({
      email: this.userFormGroup.value.email.trim(),
      password: this.userFormGroup.value.password.trim(),
      givenName: this.userFormGroup.value.givenName.trim(),
      surname: this.userFormGroup.value.surname.trim(),
    });
    this.myAccountUsersClient.createMyAccountUser(data).subscribe(
      (x) => {
        this.snackBarService.ShowSuccess(this.translateService.instant('snackbar.account_created_success'))
        this.router.navigateByUrl("/");
      },
      (error) => {
        this.snackBarService.ShowError(
          "Create MyAccount User Error: " + error
        );
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
