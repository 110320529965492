import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { SuccessSnackBarComponent } from './success-snack-bar/success-snack-bar.component';
import { ValidationSnackBarComponent } from './validation-snack-bar/validation-snack-bar.component';

@NgModule({
  declarations: [SuccessSnackBarComponent, ValidationSnackBarComponent],
  imports: [CommonModule, MaterialModule],
  exports: [SuccessSnackBarComponent, ValidationSnackBarComponent],
  entryComponents: [SuccessSnackBarComponent, ValidationSnackBarComponent],
})
export class SnackBarModule { }
