import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { FormControl, FormGroup } from '@angular/forms';
import {
  GroupsClient,
  GroupDetailsViewModel,
  PermissionsClient,
  PermissionGroupFilterBy,
  PermissionGroupFilterByFilterBy,
  PermissionGroupSortBy,
  PermissionGroupSortBySortBy,
  PermissionGroupDetailsViewModel
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { AuthService } from 'src/app/shared/auth/auth.service';


export interface IGroupSearchFilters {
  id: number;
  groupName: string;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: "app-permission-group-list-page",
  templateUrl: "./permission-group-list-page.component.html",
  styleUrls: ["./permission-group-list-page.component.scss"],
})
export class PermissionGroupListPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isAllowedToCreatePermissionGroup: boolean = true;

  groups: GroupDetailsViewModel[] = [];

  permissionGroups: PermissionGroupDetailsViewModel[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  num: number = 0;

  pageIndex: number;
  pageSize: number;
  totalGroups: number;
  sortBy: PermissionGroupSortBySortBy[] = [];
  filterBy: PermissionGroupFilterByFilterBy[] = [];

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    groupName: new FormControl(null)
  });

  constructor(
    private groupsClient: GroupsClient,
    private permissionsClient: PermissionsClient,
    private snackBarService: SnackBarService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreatePermissionGroup = response.isAllowedToCreatePermissionGroups;
      }
    );

    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadGroups();
  }

  updateFilters() {
    // Default Sort By
    this.sortBy = [
      new PermissionGroupSortBySortBy({
        property: PermissionGroupSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Group Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new PermissionGroupFilterByFilterBy({
          property: PermissionGroupFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }
    // Group Name
    if (
      this.filterFormGroup.value.groupName &&
      this.filterFormGroup.value.groupName != ""
    ) {
      this.filterBy.push(
        new PermissionGroupFilterByFilterBy({
          property: PermissionGroupFilterBy.GroupName,
          contains: this.filterFormGroup.value.groupName.trim(),
        })
      );
    }
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadGroups();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      groupName: null,
    });
    this.loadGroups();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadGroups();
  }

  loadGroups() {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.permissionsClient.permissionGroupListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.groups = result.records;
          this.totalGroups = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }
      )
    )
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}