import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as queryString from "query-string";
import { stringify } from "query-string";
import { Subscription } from "rxjs";
import {
  AssignToUserViewModel,
  BusinessUnitViewModel,
  LookupClient,
  RequestFilterBy,
  RequestFilterByFilterBy,
  RequestListViewModel,
  RequestsClient,
  RequestSortBy,
  RequestSortBySortBy,
  RequestStatus,
  RequestType,
  RequestViewModel,
  UpdateAssignedToCommand,
  UpdateBatchAssignedToCommand,
  UsersClient,
} from "src/app/apis/customer-care-api.generated";
import { AuthService } from "src/app/shared/auth/auth.service";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import {
  AssigneeDialogComponent,
  IAssigneeDialogInputData,
} from "./assignee-dialog/assignee-dialog.component";

export interface IRequestSearchFilters {
  status: string;
  businessUnitId: string;
  lastName: string;
  requestId: string;
  pageIndex: number;
  pageSize: number;
  completionDate: Date;
  assigneeId: number;
}

@Component({
  selector: "app-request-list-page",
  templateUrl: "./request-list-page.component.html",
  styleUrls: ["./request-list-page.component.scss"],
})
export class RequestListPageComponent implements AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public pageIndex: number;
  public pageSize: number;
  public totalRequests: number;

  private isInitialized: boolean;

  requestType = RequestType;


  statuses = [];

  isDaysOldDescending: boolean = null;

  requests: RequestListViewModel[];
  repList: AssignToUserViewModel[];
  isLoading: boolean;
  public selection = new SelectionModel<number>(true, []);
  selectedRequests = [];

  isBusinessUnitsLoading: boolean;
  businessUnits: BusinessUnitViewModel[];

  isAssigneeListLoading: boolean;
  isAssigneeListFilterVisable: boolean;

  isAssignableUsersLoading: boolean;
  assigneeList: AssignToUserViewModel[];

  filterFormGroup = new FormGroup({
    status: new FormControl(null),
    businessUnitId: new FormControl(null),
    lastName: new FormControl(null),
    requestId: new FormControl(null),
    completionDate: new FormControl(null),
    assigneeId: new FormControl(null)
  });

  filterBy: RequestFilterByFilterBy[] = [];
  sortBy: RequestSortBySortBy[] = [];

  reasons = [
    {
      value: RequestStatus.Submitted,
      name: "Submitted",
    },
    {
      value: RequestStatus.ContactedCustomer,
      name: "Contacted Customer",
    },
    {
      value: RequestStatus.Cancelled,
      name: "Cancelled",
    },
    {
      value: RequestStatus.ClosedCompletedAccepted,
      name: "Closed Completed Accepted",
    },
    {
      value: RequestStatus.ClosedPaymentRefunded,
      name: "Closed Payment Refunded",
    },
    {
      value: RequestStatus.ClosedPaymentCancelled,
      name: "Closed Payment Cancelled",
    },
    {
      value: RequestStatus.ClosedRejectedNoAction,
      name: "Closed Rejected No Action",
    },
  ];

  constructor(
    private router: Router,
    private requestsClient: RequestsClient,
    private userClient: UsersClient,
    private snackBarService: SnackBarService,
    private lookupClient: LookupClient,
    private dialog: MatDialog,
    private authServ: AuthService
  ) {
    this.setStatuses();
    this.loadReps();
    this.initializeFromQueryString();
    this.getBusinessUnits();
    this.isAssigneeListFilterVisable = authServ.isAllowedToFilterByAssignee;
    if(this.isAssigneeListFilterVisable) {
      this.getAssigneeList();
    }
  }

  setStatuses() {
    this.statuses = [{
      name: "all", value: 3
    }, {
      name: "all_open_statuses", value: 1
    }, {
      name: 'all_closed_statuses', value: 2
    }, {
      value: RequestStatus.Submitted,
      name: "submitted",
    },
    {
      value: RequestStatus.ContactedCustomer,
      name: "contacted_customer",
    },
    {
      value: RequestStatus.Cancelled,
      name: "cancelled",
    },
    {
      value: RequestStatus.ClosedCompletedAccepted,
      name: "closed_completed_accepted",
    },
    {
      value: RequestStatus.ClosedPaymentRefunded,
      name: "closed_payment_refunded",
    },
    {
      value: RequestStatus.ClosedPaymentCancelled,
      name: "closed_payment_cancelled",
    },
    {
      value: RequestStatus.ClosedRejectedNoAction,
      name: "closed_rejected_no_action",
    },
    {
      value: RequestStatus.ClosedSentToFive9,
      name: "closed_send_to_five_nine",
    },
  
    ];
  }

  ngAfterViewInit() { }

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.userClient.getCurrentUserBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching business units error: " + err);
          }
        }
      )
    );
  }

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number => 
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);
    
  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;
  getBusinessUnitName = (bu: BusinessUnitViewModel) => `${bu.name} (${bu.code})`;

    getAssigneeList() {
      this.isAssigneeListLoading = true;
      this.subscription.add(
        this.userClient.getAssignableUsers().subscribe(response => {
          this.assigneeList = response.result;
          this.isAssigneeListLoading = false;
        },
          err => {
            this.snackBarService.ShowError('Fetching Assignee List Error: ' + err);
          })
      );
    }

    getAssigneeId = (assignee: AssignToUserViewModel) => assignee.id;
    getAssigneeName = (assignee: AssignToUserViewModel) => assignee.name === null ? '' : assignee.name;
    

  loadReps() {
    this.subscription.add(
      this.userClient.getAssignableUsersForBusinessUnit(1).subscribe((r) => {
        this.repList = r.result;
      })
    );
    this.subscription.add(
      this.lookupClient.getCurrentUserInfo()
        .subscribe((r) => {
          if(r.result.isUserAllowedToSetStatusClosedNotAddressed) {
            this.statuses.push({
              value: RequestStatus.ClosedNotAddressed,
              name: "closed_not_addressed",
            });
          }
        })
    )
  }

  initializeFromQueryString() {
    const requestParsedFilters: IRequestSearchFilters = (queryString.parse(
      location.search,
      { arrayFormat: "comma" }
    ) as any) as IRequestSearchFilters;

    this.filterFormGroup.patchValue({
      status: requestParsedFilters.status
        ? requestParsedFilters.status == "1" ||
          requestParsedFilters.status == "2" ||
          requestParsedFilters.status == "3"
          ? +requestParsedFilters.status
          : requestParsedFilters.status
        : +1,
      businessUnitId: requestParsedFilters.businessUnitId
        ? +requestParsedFilters.businessUnitId
        : null,
      lastName: requestParsedFilters.lastName,
      requestId: requestParsedFilters.requestId,
      completionDate: requestParsedFilters.completionDate,
      assigneeId: requestParsedFilters.assigneeId,
    });

    this.pageIndex = requestParsedFilters.pageIndex
      ? requestParsedFilters.pageIndex
      : 1;

    this.pageSize = requestParsedFilters.pageSize
      ? requestParsedFilters.pageSize
      : 10;

    this.filterRequests();
  }

  createQueryStringFilters() {
    let requestFilterQueryString: any = {};

    /*---------------------- STATUS FILTER -------------------*/
    if (this.filterFormGroup.value.status) {
      requestFilterQueryString.status = this.filterFormGroup.value.status;
    }

    /*---------------------- Business Unit FILTER -------------------*/
    if (this.filterFormGroup.value.businessUnitId) {
      requestFilterQueryString.businessUnitId = this.filterFormGroup.value.businessUnitId;
    }

    /*---------------------- LASTNAME FILTER -------------------*/
    if (
      this.filterFormGroup.value.lastName &&
      this.filterFormGroup.value.lastName != ""
    ) {
      requestFilterQueryString.lastName = this.filterFormGroup.value.lastName.trim();
    }

    /*---------------------- Request ID -------------------*/
    if (this.filterFormGroup.value.requestId) {
      requestFilterQueryString.requestId = this.filterFormGroup.value.requestId.trim();
    }

    /*----------------------------------- PAGE NUMBER ------------------------------------------*/
    requestFilterQueryString.pageIndex = this.pageIndex;

    /*----------------------------------- PAGE SIZE --------------------------------------------*/
    requestFilterQueryString.pageSize = this.pageSize;

    /*---------------------- COMPLETION DATE FILTER -------------------*/
    if (this.filterFormGroup.value.completionDate)
    {
      requestFilterQueryString.completionDate = this.filterFormGroup.value.completionDate;
    }

    /*---------------------- Assignee FILTER -------------------*/
    if (this.filterFormGroup.value.assigneeId && this.isAssigneeListFilterVisable) {
      requestFilterQueryString.assigneeId = this.filterFormGroup.value.assigneeId;
    }  
    
    const stringifiedAgreementQueryString = stringify(
      requestFilterQueryString,
      { arrayFormat: "comma" }
    );

    console.log(stringifiedAgreementQueryString)

    this.router.navigateByUrl(`requests?${stringifiedAgreementQueryString}`);

    this.filterRequests();

  }

  loadRequests() {
    this.isLoading = true;

    this.subscription.add(
      this.requestsClient
        .requestListSearch(
          this.pageIndex,
          this.pageSize,
          this.sortBy,
          this.filterBy.length == 0 ? undefined : this.filterBy
        )
        .subscribe(
          (result) => {
            this.requests = result.records;
            this.isLoading = false;
            this.totalRequests = result.totals[0].value;

            setTimeout(() => {
              this.isInitialized = true;
            }, 2000);
          },
          (error) => {
            console.error(error);
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  onDaysOldHeaderClicked(){
    if(this.isDaysOldDescending == null) {
      this.isDaysOldDescending = false;
    } else {
      this.isDaysOldDescending = !this.isDaysOldDescending;
    }

    this.createQueryStringFilters();
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }

      this.pageSize = e.pageSize;
      this.createQueryStringFilters();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;

    this.filterFormGroup.patchValue({
      status: 3,
      businessUnitId: null,
      lastName: null,
      requestId: null,
      completionDate: null,
      assigneeId: null,
    });

    this.isDaysOldDescending = null;

    this.createQueryStringFilters();
  }

  onFilterRequestsClicked() {
    this.pageIndex = 1;

    console.log("onFilterRequestClicked used.")

    this.createQueryStringFilters();
  }

  filterRequests() {
    /*-----------DEFAULT SORT BY ------------*/
    this.sortBy = [];

    if(this.isDaysOldDescending == null){
      // if null then apply the default filters
      const sortByNewService = new RequestSortBySortBy({
        property: RequestSortBy.IsNewService,
        descendingOrder: true,
      });
  
      this.sortBy.push(sortByNewService);
  
      const sortByDaysOld = new RequestSortBySortBy({
        property: RequestSortBy.DaysOld,
        descendingOrder: true,
      });
  
      this.sortBy.push(sortByDaysOld);

    } else if (this.isDaysOldDescending === true) {
      const sortByDaysOld = new RequestSortBySortBy({
        property: RequestSortBy.DaysOld,
        descendingOrder: true,
      });
  
      this.sortBy.push(sortByDaysOld);

    } else {
      const sortByDaysOld = new RequestSortBySortBy({
        property: RequestSortBy.DaysOld,
        descendingOrder: false,
      });
  
      this.sortBy.push(sortByDaysOld);
    }   

    this.filterBy = [];

    /*---------------------- STATUS FILTER -------------------*/
    if (
      this.filterFormGroup.value.status &&
      this.filterFormGroup.value.status != 3
    ) {
      let statusList;

      /* All Open Requests */
      if (this.filterFormGroup.value.status == 1) {
        statusList = [RequestStatus.ContactedCustomer, RequestStatus.Submitted];
        /* All Closed Requests */
      } else if (this.filterFormGroup.value.status == 2) {
        statusList = [
          RequestStatus.Cancelled,
          RequestStatus.ClosedCompletedAccepted,
          RequestStatus.ClosedPaymentCancelled,
          RequestStatus.ClosedPaymentRefunded,
          RequestStatus.ClosedRejectedNoAction,
          RequestStatus.ClosedSentToFive9,
          RequestStatus.ClosedNotAddressed
        ];
      } else {
        statusList = this.filterFormGroup.value.status;
      }

      const filterByStatus = new RequestFilterByFilterBy({
        property: RequestFilterBy.Status,
        in: statusList,
      });

      this.filterBy.push(filterByStatus);
    }

    /*---------------------- Business Unit FILTER -------------------*/
    if (this.filterFormGroup.value.businessUnitId) {
      const filterByStatus = new RequestFilterByFilterBy({
        property: RequestFilterBy.BusinessUnitId,
        equal: this.filterFormGroup.value.businessUnitId,
      });

      this.filterBy.push(filterByStatus);
    }

    /*---------------------- LASTNAME FILTER -------------------*/
    if (
      this.filterFormGroup.value.lastName &&
      this.filterFormGroup.value.lastName != ""
    ) {
      const filterByLastname = new RequestFilterByFilterBy({
        property: RequestFilterBy.LastName,
        equal: this.filterFormGroup.value.lastName.trim(),
      });

      this.filterBy.push(filterByLastname);
    }

    /*---------------------- Request ID -------------------*/
    if (this.filterFormGroup.value.requestId) {
      const filterByStatus = new RequestFilterByFilterBy({
        property: RequestFilterBy.Id,
        equal: this.filterFormGroup.value.requestId.trim(),
      });

      this.filterBy.push(filterByStatus);
    }

    /*---------------------- COMPLETION DATE FILTER -------------------*/
    if (
      this.filterFormGroup.value.completionDate &&
      this.filterFormGroup.value.completionDate != ""
    ) {
      const filterByCompletionDate = new RequestFilterByFilterBy({
        property: RequestFilterBy.CompletionDate,
        equal: this.filterFormGroup.value.completionDate,
      });

      this.filterBy.push(filterByCompletionDate);
    }

        /*---------------------- Assignee FILTER -------------------*/
    if (this.filterFormGroup.value.assigneeId) {
      const filterByStatus = new RequestFilterByFilterBy({
        property: RequestFilterBy.AssigneeId,
        equal: this.filterFormGroup.value.assigneeId,
      });

      this.filterBy.push(filterByStatus);
    }

    this.loadRequests();
  }

  clearFilter() { }
  
  getStatusName(status): string {
    switch (status) {
      case RequestStatus.Submitted:
        return "submitted";
      case RequestStatus.Cancelled:
        return "cancelled";
      case RequestStatus.ClosedCompletedAccepted:
        return "closed_completed_accepted";
      case RequestStatus.ClosedPaymentCancelled:
        return "closed_payment_cancelled";
      case RequestStatus.ClosedPaymentRefunded:
        return "closed_payment_refunded";
      case RequestStatus.ClosedRejectedNoAction:
        return "closed_rejected_no_action";
      case RequestStatus.ContactedCustomer:
        return "contacted_customer";
      default:
        return "";
    }
  }

  showEditAssigneeButton(request: RequestViewModel): boolean {
    if (
      request.status == RequestStatus.Submitted ||
      request.status == RequestStatus.Cancelled ||
      request.status == RequestStatus.ContactedCustomer
    ) {
      return true;
    } else {
      return false;
    }
  }

  onEditAssigneeClicked(request: RequestViewModel) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "600px";
    dialogConfig.maxHeight = "800px";

    const data: IAssigneeDialogInputData = {
      assigneeUserId: request.assignedToUserId,
      businessUnitId: request.businessUnitId,
    };

    dialogConfig.data = data;
    this.subscription.add(
      this.dialog
        .open(AssigneeDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.confirmChangeAssignee(response, request.id);
          }
        })
    );
  }

  confirmChangeAssignee(newAssigneeId, requestId) {
    this.isLoading = true;

    const updateAssignee: UpdateAssignedToCommand = new UpdateAssignedToCommand(
      {
        id: requestId,
        newAssignedToId: +newAssigneeId,
        note: null,
      }
    );
    this.subscription.add(
      this.requestsClient.assign(updateAssignee).subscribe(
        (response) => {
          this.snackBarService.ShowSuccess("Assignee updated successfully !");
          this.loadRequests();
        },
        (err) => {
          this.isLoading = false;
          this.snackBarService.ShowError(
            "Updating Assignee Error: " + err
          );
        }
      )
    );
  }

  selectRequest(event: any, requestId: number) {
    if (event.checked) {
      this.selectedRequests.push(requestId);
    } else {
      this.selectedRequests.splice(this.selectedRequests.indexOf(requestId), 1);
    }
  }

  batchAssignRequests(rep: AssignToUserViewModel, requestIds) {
    let data = {
      requestIds,
      userId: rep.id.toString(),
    };

    let command: UpdateAssignedToCommand[] = [];
    command.push(
      new UpdateAssignedToCommand({
        id: requestIds,
        newAssignedToId: rep.id,
      })
    );

    let batchAssignCommand: UpdateBatchAssignedToCommand = new UpdateBatchAssignedToCommand(
      {
        batchUpdates: command,
      }
    );

    this.subscription.add(
      this.requestsClient
        .batchAssign(batchAssignCommand)
        .subscribe((result) => {
          this.loadRequests();
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
