import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface IConfirmFusionProfileDeleteDialog {
  accountNumber: string;
  fusionProfileId: string;
}

@Component({
  selector: 'app-confirm-fusion-profile-delete-dialog',
  templateUrl: './confirm-fusion-profile-delete-dialog.component.html',
  styleUrls: ['./confirm-fusion-profile-delete-dialog.component.css']
})
export class ConfirmFusionProfileDeleteDialogComponent implements OnInit {

  isLoading: boolean = false;

  constructor(private dialog: MatDialogRef<ConfirmFusionProfileDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public request: IConfirmFusionProfileDeleteDialog) { }

  ngOnInit() {
  }

  confirmCancel() {
    this.dialog.close(false);
  }

  confirmSubmit() {
    this.dialog.close(true);
  }

}
