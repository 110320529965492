import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"],
})
export class Breadcrumb {
  @Input() items: string[];
  constructor(private router: Router) {}

  goToHome() {
    this.router.navigateByUrl("/");
  }
}
