import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { IntegrationErrorViewModel } from "src/app/apis/customer-care-api.generated";
import { IntegrationErrorTableModel } from "src/app/shared/models/entities";

export const IntegrationErrorServiceToken = new InjectionToken<IIntegrationErrorService>('Integration Error injection token');

export interface IIntegrationErrorService {
  integrationErrorDetails(id: number): Observable<IntegrationErrorViewModel>;
  search(): Observable<IntegrationErrorTableModel[]>;
  resubmitMessage(id: number): Observable<void>;
  resolveMessage(id: number): Observable<void>;
}