import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs";
import {
  BusinessUnitViewModel,
  LookupClient,
  RoleViewModel,
  UserFilterBy,
  UserFilterByFilterBy,
  UserListViewModel,
  UsersClient,
  UserSortBy,
  UserSortBySortBy,
} from "src/app/apis/customer-care-api.generated";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";

export interface IUserSearchFilters {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: "app-user-list-page",
  templateUrl: "./user-list-page.component.html",
  styleUrls: ["./user-list-page.component.css"],
})
export class UserListPageComponent implements OnInit, OnDestroy {
  constructor(
    private lookupClient: LookupClient,
    private userClient: UsersClient,
    private snackBarService: SnackBarService,
    private dialog: MatDialog
  ) { }
  subscription: Subscription = new Subscription();

  isLoading: boolean;
  isInitialized: boolean;

  pageIndex: number;
  pageSize: number;
  totalUsers: number;
  sortBy: UserSortBySortBy[] = [];
  filterBy: UserFilterByFilterBy[] = [];

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    firstName: new FormControl(null),
    lastName: new FormControl(null),
    emailAddress: new FormControl(null),
  });

  users: UserListViewModel[];
  roles: RoleViewModel[];
  businessUnits: BusinessUnitViewModel[];

  ngOnInit() {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadRoles();
    this.loadBusinessUnits();
    this.loadUsers();
  }

  loadRoles() {
    this.lookupClient.getAllRoles().subscribe((response) => {
      this.roles = response.result;
    });
  }

  loadBusinessUnits() {
    this.lookupClient.getAllBusinessUnits().subscribe((response) => {
      this.businessUnits = response.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
    });
  }

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);

  loadUsers() {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.userClient
        .userListSearch(
          this.pageIndex,
          this.pageSize,
          this.sortBy,
          this.filterBy.length == 0 ? undefined : this.filterBy
        )
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.users = result.records;
            this.totalUsers = result.totals[0].value;

            setTimeout(() => {
              this.isInitialized = true;
            }, 2000);
          },
          (error) => {
            this.isLoading = false;
            console.error(error);
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  updateFilters() {
    // Default Sort By
    this.sortBy = [
      new UserSortBySortBy({
        property: UserSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // User Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new UserFilterByFilterBy({
          property: UserFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }
    // First Name
    if (
      this.filterFormGroup.value.firstName &&
      this.filterFormGroup.value.firstName != ""
    ) {
      this.filterBy.push(
        new UserFilterByFilterBy({
          property: UserFilterBy.FirstName,
          contains: this.filterFormGroup.value.firstName.trim(),
        })
      );
    }
    // Last Name
    if (
      this.filterFormGroup.value.lastName &&
      this.filterFormGroup.value.lastName != ""
    ) {
      this.filterBy.push(
        new UserFilterByFilterBy({
          property: UserFilterBy.LastName,
          contains: this.filterFormGroup.value.lastName.trim(),
        })
      );
    }
    // Email
    if (
      this.filterFormGroup.value.emailAddress &&
      this.filterFormGroup.value.emailAddress != ""
    ) {
      this.filterBy.push(
        new UserFilterByFilterBy({
          property: UserFilterBy.EmailAddress,
          contains: this.filterFormGroup.value.emailAddress.trim(),
        })
      );
    }
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadUsers();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
    });
    this.loadUsers();
  }

  onFilterUsersClicked() {
    this.pageIndex = 1;
    this.loadUsers();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
