import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AssignToUserViewModel, CancelFuturePaymentCommand, EventLogViewModel, ReportsClient, ResendPaymentEmailCommand, UsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';


export interface IVoidPaymentDialogComponent {
  event: EventLogViewModel
}

@Component({
  selector: 'app-void-payment-dialog',
  templateUrl: './void-payment-dialog.component.html',
  styleUrls: ['./void-payment-dialog.component.scss']
})



export class VoidPaymentDialogComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();


  isLoading = false;
  isMultiPayment = false;
  totalPayment = "";


  constructor(private snackBarService: SnackBarService, private dialog: MatDialogRef<VoidPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IVoidPaymentDialogComponent,
    private reportClient: ReportsClient
  ) { }

  ngOnInit() {
    this.isMultiPayment = this.data.event.detailsJson.includes('Multi-Account Payment');
    if (this.isMultiPayment) {
      // deserialize the detailsJson to get the total payment
      const details = JSON.parse(this.data.event.detailsJson);
      // total payment is from the key 'Total Payment Amount'
      this.totalPayment = details['Total Payment Amount'];
    }
  }

  onEmailClicked()
  {
    const request = new CancelFuturePaymentCommand(
      {
          eventLogId: this.data.event.id,
          isVoid : true
      }
    );
    this.isLoading = true;
    this.subscription.add(
      this.reportClient
        .cancelFuturePayment(
          request
        )
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.snackBarService.ShowSuccess("Payment Voided Successfully");
            this.dialog.close(true);
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
