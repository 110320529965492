// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var protectedResourceMap = [['https://graph.microsoft.com/v1.0/me', ['user.read']], ['https://graph.microsoft.com/v1.0/me/Photos/48X48/$value', ['user.read']]];

export const environment = {
  production: false,
  MsalConfig: {
    clientID: '78dfbb6e-d61a-40f5-936e-b37e6ac7c992',
    authority: "https://login.microsoftonline.com/d33b78bd-8756-4656-91ce-ffbb17c5cc22",
    validateAuthority: true,
    redirectUri: "https://localhost:4200",
    cacheLocation: "localStorage",
    postLogoutRedirectUri: "https://loaclhost:4200",
    navigateToLoginRequestUrl: true,
    popUp: true,
    consentScopes: ["user.read"],
    protectedResourceMap: protectedResourceMap,
    correlationId: '1235'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
