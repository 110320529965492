import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { AreaFeeViewModel, AreaServiceViewModel, BusinessUnitViewModel, CreateAreaCommand, Ecommerce_AreaClient, Ecommerce_FeeDetailsViewModel, Ecommerce_ServiceCodeDetailsViewModel, Ecommerce_ServiceFrequencyDetailsViewModel, Ecommerce_ServiceTypeDetailsViewModel, LookupClient, SR_BillingCycleDetailsViewModel } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-areas-create-page",
  templateUrl: "./area-create-page.component.html",
  styleUrls: ["./area-create-page.component.scss"],
})
export class AreaCreatePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;

  isBusinessUnitsLoading: boolean;
  businessUnits: BusinessUnitViewModel[];
  businessUnitFormControl = new FormControl();

  isBillingCyclesLoading: boolean;
  billingCycles: SR_BillingCycleDetailsViewModel[];
  billingCycleFormControl = new FormControl();

  allFees : Ecommerce_FeeDetailsViewModel[];
  allServiceTypes : Ecommerce_ServiceTypeDetailsViewModel[];
  allServiceCodes : Ecommerce_ServiceCodeDetailsViewModel[];
  allServiceFrequencies : Ecommerce_ServiceFrequencyDetailsViewModel[];


  formGroup = new FormGroup({
    id: new FormControl(0),
    serviceArea: new FormControl(""),
    name: new FormControl(""),
    businessUnitId: new FormControl(""),
    type: new FormControl(""),
    handledByMuni: new FormControl(false),
    monthsUpFront: new FormControl(0),
    inTower: new FormControl(false),
    marketingApproved: new FormControl(false),
    serviceAreaApproved: new FormControl(false),
    isActive: new FormControl(false),
    signupMessage: new FormControl(""),
    isPrimary: new FormControl(false),
    hasContact: new FormControl(false),
    billingCycle: new FormControl(""),
    startDate: new FormControl(new Date()),
    areaServices: new FormArray([]),
    areaFees: new FormArray([]),
  });

  constructor(
    private snackBarService: SnackBarService,
    private ecommerce_areaClient: Ecommerce_AreaClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lookupClient: LookupClient,
  ) { }

  ngOnInit() {
    this.getBusinessUnits();
    this.loadBillingCycles();
    this.loadAllFees();
    this.loadAllServiceTypes();
    this.loadAllServiceCodes();
    this.loadAllServiceFrequencies();
  }

  addAreaServices()
  {
    const pGroupControl = new FormGroup({
            serviceTypeId : new FormControl(0),
            serviceName : new FormControl(""),
            serviceCodeId : new FormControl(0),
            serviceCode : new FormControl(""),
            serviceFrequencyId : new FormControl(0),
            serviceFrequency : new FormControl(""),
            servicePrice: new FormControl(0),
            isRequired: new FormControl(false)
    });
    this.areaServices.insert(0, pGroupControl);
  }

  get areaServices() {
    return this.formGroup.get("areaServices") as FormArray;
  }

  removeAreaServices(x)
  {
    this.areaServices.removeAt(x);
  }

  getAreaServiceId (p: any)
  {
    return p.id;
  }

  getAreaServiceName (p: any) {
    return p.serviceName;
  }

  getAreaFrequencyId (p: any)
  {
    return p.id;
  }

  getAreaServiceFrequencyName (p: any) {
    return p.serviceFrequency;
  }

  getAreaServiceCodeId (p: any)
  {
    return p.id;
  }

  getAreaServiceCodeName (p: any) {
    return p.serviceCode;
  }

  addAreaFees()
  {
    const pGroupControl = new FormGroup({
      id: new FormControl(0),
      feeName: new FormControl(null),
      price: new FormControl(0),
    });
    this.areaFees.insert(0, pGroupControl);
  }

  get areaFees() {
    return this.formGroup.get("areaFees") as FormArray;
  }

  removeAreaFees(x)
  {
    this.areaFees.removeAt(x);
  }

  getAreaFeeId (p: any)
  {
    return p.id;
  }

  getAreFeeName (p: any) {
    return p.feeName;
  }



  loadAllServiceTypes()
  {
    this.lookupClient.getAllServiceTypes().subscribe(
      (response) => {
        this.allServiceTypes = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadAllFees()
  {
    this.lookupClient.getAllFees().subscribe(
      (response) => {
        this.allFees = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadAllServiceCodes()
  {
    this.lookupClient.getAllServiceCodes().subscribe(
      (response) => {
        this.allServiceCodes = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadAllServiceFrequencies()
  {
    this.lookupClient.getAllServiceFrequencies().subscribe(
      (response) => {
        this.allServiceFrequencies = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }



  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result;
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching business units error: " + err);
          }
        }
      )
    );
  }

  loadBillingCycles() {
    this.isBillingCyclesLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBillingCycles().subscribe(
        (result) => {
          this.billingCycles = result.result;
          this.isBillingCyclesLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching Billing Cycles error: " + err);
          }
        }
      )
    );
  }

  getBillingCycleId = (bu: SR_BillingCycleDetailsViewModel) => bu.id;

  getBillingCycleName = (bu: SR_BillingCycleDetailsViewModel) =>
    `${bu.billingCycle} (${bu.billMonthName})`;

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;


  goBack() {
    this.router.navigateByUrl("/ecommerce/areas");
  }

  create()
  {
    this.isLoading = true;
    const area = new CreateAreaCommand({
    serviceArea: this.formGroup.value.serviceArea,
    name: this.formGroup.value.name,
    businessUnitId: this.businessUnitFormControl.value,
    type: this.formGroup.value.type,
    handledByMuni: this.formGroup.value.handledByMuni,
    monthsUpFront: this.formGroup.value.monthsUpFront,
    inTower: this.formGroup.value.inTower,
    marketingApproved: this.formGroup.value.marketingApproved,
    serviceAreaApproved: this.formGroup.value.serviceAreaApproved,
    isActive: this.formGroup.value.isActive,
    signupMessage: this.formGroup.value.signupMessage,
    isPrimary: this.formGroup.value.isPrimary,
    hasContact: this.formGroup.value.hasContact,
    billingCycleId: this.billingCycleFormControl.value,
    startDate: this.formGroup.value.startDate,
    areaServices : this.areaServices.controls.map(
      (x) => new AreaServiceViewModel({
        areaId: 0,
        serviceTypeId : x.value.serviceTypeId,
        serviceName: x.value.serviceName,
        serviceCode: x.value.serviceCode,
        serviceCodeId: x.value.serviceCodeId,
        serviceFrequency: x.value.serviceFrequency,
        serviceFrequencyId: x.value.serviceFrequencyId,
        servicePrice: x.value.servicePrice,
        isRequired: x.value.isRequired
      })
    ),
    areaFees : this.areaFees.controls.map(
      (x) => new AreaFeeViewModel({
        areaId: 0,
        feeId: x.value.id,
        price: x.value.price
      })
    )
    });
    this.subscription.add(
        this.ecommerce_areaClient.createArea(
            area
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.name + " has been created successfully !");
            this.router.navigateByUrl("/ecommerce/areas");
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Create Area Error: " + error);
        }
        )
    );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}