import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface country {
  countryCode: string
}

export interface state {
  stateCode: string, countryCode: string
}

@Injectable({
  providedIn: 'root'
})
export class DemographicInformationService {
  private countryList: country[];
  private stateList: state[];
  constructor(private translateService: TranslateService) {
    this.countryList = [{
      countryCode: "CA"
    },
    {
      countryCode: "US"
    }];

    this.stateList = [
      {
        stateCode: "AL",
        countryCode: "US"
      },
      {
        stateCode: "AK",
        countryCode: "US"
      },
      {
        stateCode: "AZ",
        countryCode: "US"
      },
      {
        stateCode: "AR",
        countryCode: "US"
      },
      {
        stateCode: "CA",
        countryCode: "US"
      },
      {
        stateCode: "CO",
        countryCode: "US"
      },
      {
        stateCode: "CT",
        countryCode: "US"
      },
      {
        stateCode: "DE",
        countryCode: "US"
      },
      {
        stateCode: "DC",
        countryCode: "US"
      },
      {
        stateCode: "FL",
        countryCode: "US"
      },
      {
        stateCode: "GA",
        countryCode: "US"
      },
      {
        stateCode: "HI",
        countryCode: "US"
      },
      {
        stateCode: "ID",
        countryCode: "US"
      },
      {
        stateCode: "IL",
        countryCode: "US"
      },
      {
        stateCode: "IN",
        countryCode: "US"
      },
      {
        stateCode: "IA",
        countryCode: "US"
      },
      {
        stateCode: "KS",
        countryCode: "US"
      },
      {
        stateCode: "KY",
        countryCode: "US"
      },
      {
        stateCode: "LA",
        countryCode: "US"
      },
      {
        stateCode: "ME",
        countryCode: "US"
      },
      {
        stateCode: "MD",
        countryCode: "US"
      },
      {
        stateCode: "MA",
        countryCode: "US"
      },
      {
        stateCode: "MI",
        countryCode: "US"
      },
      {
        stateCode: "MN",
        countryCode: "US"
      },
      {
        stateCode: "MS",
        countryCode: "US"
      },
      {
        stateCode: "MO",
        countryCode: "US"
      },
      {
        stateCode: "MT",
        countryCode: "US"
      },
      {
        stateCode: "NE",
        countryCode: "US"
      },
      {
        stateCode: "NV",
        countryCode: "US"
      },
      {
        stateCode: "NH",
        countryCode: "US"
      },
      {
        stateCode: "NJ",
        countryCode: "US"
      },
      {
        stateCode: "NM",
        countryCode: "US"
      },
      {
        stateCode: "NY",
        countryCode: "US"
      },
      {
        stateCode: "NC",
        countryCode: "US"
      },
      {
        stateCode: "ND",
        countryCode: "US"
      },
      {
        stateCode: "OH",
        countryCode: "US"
      },
      {
        stateCode: "OK",
        countryCode: "US"
      },
      {
        stateCode: "OR",
        countryCode: "US"
      },
      {
        stateCode: "PA",
        countryCode: "US"
      },
      {
        stateCode: "PR",
        countryCode: "US"
      },
      {
        stateCode: "RI",
        countryCode: "US"
      },
      {
        stateCode: "SC",
        countryCode: "US"
      },
      {
        stateCode: "SD",
        countryCode: "US"
      },
      {
        stateCode: "TN",
        countryCode: "US"
      },
      {
        stateCode: "TX",
        countryCode: "US"
      },
      {
        stateCode: "UT",
        countryCode: "US"
      },
      {
        stateCode: "VT",
        countryCode: "US"
      },
      {
        stateCode: "VA",
        countryCode: "US"
      },
      {
        stateCode: "VI",
        countryCode: "US"
      },
      {
        stateCode: "WA",
        countryCode: "US"
      },
      {
        stateCode: "WV",
        countryCode: "US"
      },
      {
        stateCode: "WI",
        countryCode: "US"
      },
      {
        stateCode: "WY",
        countryCode: "US"
      },


      {
        stateCode: "NL",
        countryCode: "CA"
      },
      {
        stateCode: "PE",
        countryCode: "CA"
      },
      {
        stateCode: "NS",
        countryCode: "CA"
      },
      {
        stateCode: "NB",
        countryCode: "CA"
      },
      {
        stateCode: "QC",
        countryCode: "CA"
      },
      {
        stateCode: "ON",
        countryCode: "CA"
      },
      {
        stateCode: "MB",
        countryCode: "CA"
      },
      {
        stateCode: "SK",
        countryCode: "CA"
      },
      {
        stateCode: "AB",
        countryCode: "CA"
      },
      {
        stateCode: "BC",
        countryCode: "CA"
      },
      {
        stateCode: "YT",
        countryCode: "CA"
      },
      {
        stateCode: "NT",
        countryCode: "CA"
      },
      {
        stateCode: "NU",
        countryCode: "CA"
      },

    ]
  }

  get countries() {
    return this.countryList.sort((c1, c2) => c1.countryCode > c2.countryCode ? 1 : -1);
  }

  get states() {
    return this.stateList.sort((c1, c2) => c1.stateCode > c2.stateCode ? 1 : -1);
  }

   statesForCountry(countryCode: string) {
    return this.stateList.filter(s => s.countryCode == countryCode)
      .sort((c1, c2) => c1.stateCode > c2.stateCode ? 1 : -1);
  }
}
