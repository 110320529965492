import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIntegrationErrorService, IntegrationErrorServiceToken } from '../error-handling';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-error-details',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss']
})
export class ErrorDetailsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialog: MatDialogRef<ErrorDetailsComponent>,
    @Inject(IntegrationErrorServiceToken) private integrationErrorService: IIntegrationErrorService,
    private snackbarService: SnackBarService
  ) { }

  onClickClose() {
    this.matDialog.close();
  }

  onClickResubmitError() {
    this.integrationErrorService
      .resubmitMessage(this.data.id)
      .pipe(
        catchError(err => {
          this.snackbarService.ShowError(err);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.snackbarService.ShowSuccess('Successfully resubmitted error ' + this.data.id);
        this.matDialog.close();
      });
  }
}
