import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import {
  PermissionsClient,
  BusinessUnitViewModel,
  LookupClient,
  GroupsClient,
  UserDetailsViewModel,
  PermissionGroupUserViewModel,
  PermissionsViewModel,
  UpdatePermissionGroupCommand,
  GroupPermissionViewModel,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "app-edit-permission-group-page",
  templateUrl: "./edit-permission-group-page.component.html",
  styleUrls: ["./edit-permission-group-page.component.scss"],
})
export class EditPermissionGroupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  totalBusinessUnits: BusinessUnitViewModel[];
  totalUsers: UserDetailsViewModel[];

  totalPermissions: PermissionsViewModel[];

  loading: boolean;
  usersLoading: boolean;
  groupBUsloading: boolean;
  groupDetailsloading: boolean;

  changesMade: boolean = false;
  id: number;

  groupFormGroup = new FormGroup({
    groupId: new FormControl(0),
    groupName: new FormControl("", [this.checkEmptyString]),
    permissions: new FormArray([]),
    users: new FormArray([])
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lookupClient: LookupClient,
    private permissionsClient: PermissionsClient,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params["groupId"];
    this.loadPermissions();
    this.loadUsers();
    this.loadGroupDetails();
    this.loadGroupUsers();
  }

  loadUsers() {
    this.lookupClient.getAllUsers().subscribe((response) => {
      this.totalUsers = response.result;
    },
      (error) => {
        this.snackBarService.ShowError(error);
      }
    );
  }

  loadPermissions() {
    this.permissionsClient.allPermissions().subscribe((response) => {
      this.totalPermissions = response.result;
    },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadGroupDetails() {
    this.groupDetailsloading = true;
    this.subscription.add(
      this.permissionsClient.getPermissionGroupDetails(this.id).subscribe((response) => {

        const group = response.result;
        this.groupFormGroup.patchValue({
          groupId: group.id,
          groupName: group.groupName,
        });
        this.permissions.clear();
        for (const bu of group.groupPermissions) {
          const buControl = new FormGroup({
            userId: new FormControl(bu.userId),
            permissionId: new FormControl(bu.permissionId),
            permissionName: new FormControl(bu.permissionName),
          });
          this.permissions.push(buControl);
        }
      },
        (error) => {
          this.groupDetailsloading = false;
          this.snackBarService.ShowError("Some error occured !");
        }
      ));
    this.groupDetailsloading = false;
  }

  loadGroupUsers() {
    this.usersLoading = true;

    this.subscription.add(
      this.permissionsClient.getPermissionGroupUsers(this.id).subscribe((response) => {
        const group = response.result;
        this.users.clear();
        for (const bu of group.permissionGroups) {
          const buControl = new FormGroup({
            userId: new FormControl(bu.userId),
            fullName: new FormControl(bu.fullName)
          });
          this.users.push(buControl);
        }
      },
        (error) => {
          this.snackBarService.ShowError(error);
        }
      ));
    this.usersLoading = false;
  }

  get businessUnits() {
    return this.groupFormGroup.get("businessUnits") as FormArray;
  }

  get permissions() {
    return this.groupFormGroup.get("permissions") as FormArray;
  }

  get users() {
    return this.groupFormGroup.get("users") as FormArray;
  }

  addUser() {
    this.changesMade = true;
    const userControl = new FormGroup({
      userId: new FormControl(0),
      fullName: new FormControl(null),
      groupId: new FormControl(this.id),
    });
    this.users.insert(0, userControl);
  }

  removeUser(x) {
    this.changesMade = true;
    this.users.removeAt(x);
  }
  getUserId = (u: UserDetailsViewModel) => u.id;

  getFullName = (u: UserDetailsViewModel) => `${u.fullName} (${u.id})`;

  addBusinessUnit() {
    this.changesMade = true;
    const buControl = new FormGroup({
      userId: new FormControl(this.id),
      permissionId: new FormControl(null),
      permissionName: new FormControl(null),
    });
    this.permissions.insert(0, buControl);
  }
  removeBusinessUnit(x) {
    this.changesMade = true;
    this.permissions.removeAt(x);
  }
  getPermissionId = (p: PermissionsViewModel) => p.id;

  getPermissionName = (p: PermissionsViewModel) => this.translateService.instant(p.name);

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  updateGroup() {
    const group: UpdatePermissionGroupCommand = new UpdatePermissionGroupCommand({
      groupId: this.id,
      groupName: this.groupFormGroup.value.groupName,
      permissions: this.permissions.controls.map(
        (x) =>
          new GroupPermissionViewModel({
            permissionGroupId: this.id,
            permissionId: x.value.permissionId,
            permissionName: x.value.permissionName,
          })
      ),
      users: this.users.controls.map(
        (x) =>
          new PermissionGroupUserViewModel({
            userId: x.value.userId
          })
      )
    });
    this.loading = true;

    this.subscription.add(
      this.permissionsClient.updatePermissionGroup(group).subscribe(
        (response) => {
          this.loading = false;

          if (response.isSuccess) {
            this.snackBarService.ShowSuccess(this.groupFormGroup.value.groupName + " has been updated successfully !");
            window.location.reload();
          }
        },
        (error) => {
          this.loading = false;
          this.snackBarService.ShowError('Some error occurred !');
        }
      ));
  }

  goBack() {
    if (!this.changesMade) {
      this.router.navigateByUrl("/admin/permissions/groups");
    }
    else {
      if (window.confirm("Changes were not saved. Do you want to discard changes ?")) {
        this.snackBarService.ShowSuccess('Changes were not saved !');
        this.router.navigateByUrl("/admin/permissions/groups");
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}