import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface ICommonPaginatorEvent {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnDestroy {
  @Input() currentPageIndex: number;
  @Input() defaultPageSize: number;
  @Input() pageSizeOptions: number[];
  @Input() totalResults: number;
  @Output() page: EventEmitter<ICommonPaginatorEvent>;

  pageSizeControl: FormControl;
  subscription: Subscription;

  constructor() {
    this.currentPageIndex = this.currentPageIndex;
    this.page = new EventEmitter<ICommonPaginatorEvent>();
    this.subscription = new Subscription();
    this.initControl();
  }

  ngOnInit(): void {
    this.pageSizeControl.setValue(+this.defaultPageSize);
  }

  initControl(): void {
    this.pageSizeControl = new FormControl();
    const sub = this.pageSizeControl.valueChanges.subscribe((_) => {
      this.currentPageIndex = this.currentPageIndex;
      this.emitPagination();
    });
    this.subscription.add(sub);
  }

  emitPagination(): void {
    const event: ICommonPaginatorEvent = this.buildCommonPaginatorEvent();
    this.page.emit(event);
  }

  private buildCommonPaginatorEvent(): ICommonPaginatorEvent {
    const event: ICommonPaginatorEvent = {
      pageIndex: this.currentPageIndex,
      pageSize: this.pageSizeControl.value,
    };

    return event;
  }

  reset(): void {
    // this.currentPageIndex = 1; //  This will emit a 'page' event through change detection in child component left-paginator.
    this.pageSizeControl.setValue(this.pageSizeOptions[0] || 5, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
