import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EsriMap } from './esri-map';
import { EsriModuleLoader } from './esri-module.loader';
import { EsriTypeFactory } from './esri-type.factory';
import { EsriUtility } from './esri.utility';
import { LocationSelectMap } from './location-select.map';
import { PolygonEditorMap } from './polygon-editor.map';
import { PolygonViewMap } from './polygon-view.map';
import esri = __esri;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public mapViewChange = new BehaviorSubject('');
  public mapPointChange = new BehaviorSubject(null);
  constructor(private esriModuleLoader: EsriModuleLoader, private esriTypeFactory: EsriTypeFactory, private esriUtility: EsriUtility) { }

  async createMap(container: HTMLElement, properties: any = {}, basemap = 'gray-vector') {
    this.esriModuleLoader.loadCss();
    return await new EsriMap(this.esriModuleLoader, this.esriTypeFactory).init(container, properties, basemap);
  }

  async createLocationSelectMap(container: HTMLElement, properties: any = {}) {
    this.esriModuleLoader.loadCss();
    return await new LocationSelectMap(this.esriModuleLoader, this.esriTypeFactory).init(container, properties);
  }
  async createPolygonEditorMap(container: HTMLElement, properties: any = {}) {
    this.esriModuleLoader.loadCss();
    return await new PolygonEditorMap(this.esriModuleLoader, this.esriTypeFactory, this.esriUtility).init(container, properties);
  }

  async createPolygonViewMap(container: HTMLElement, properties: any = {}, basemap = 'gray-vector') {
    this.esriModuleLoader.loadCss();
    return await new PolygonViewMap(this.esriModuleLoader, this.esriTypeFactory).init(container, properties, basemap);
  }
}
