import { EsriModuleLoader } from './esri-module.loader';
import { EsriTypeFactory } from './esri-type.factory';
import { EsriModuleEnum, GraphicLayer } from './map.model';

import esri = __esri;

export class MapConfig {
  public static esriTypeFactory: EsriTypeFactory;
  public static esriModuleLoader: EsriModuleLoader;
  public static mapView: esri.MapView;
  public static searchWidget: esri.Widget;

  public static init(typeFactoryRef: EsriTypeFactory, moduleLoaderRef: EsriModuleLoader, mapView) {
    MapConfig.esriTypeFactory = typeFactoryRef;
    MapConfig.esriModuleLoader = moduleLoaderRef;
    MapConfig.loadModules();
    MapConfig.mapView = mapView;

  }

  public static async loadModules() {
    await MapConfig.esriModuleLoader.loadModules([
      EsriModuleEnum.Graphic,
      EsriModuleEnum.GraphicsLayer,
      EsriModuleEnum.MapView,
      EsriModuleEnum.SimpleMarkerSymbol,
      EsriModuleEnum.PopupTemplate,
      EsriModuleEnum.WatchUtils
    ]);
  }


  
  public static addGraphicLayers(esriMap: esri.Map, layer: GraphicLayer) {
    layer.serviceZone = this.esriTypeFactory.create<esri.GraphicsLayer>(EsriModuleEnum.GraphicsLayer, { graphics: [] });
    esriMap.add(layer.serviceZone);
  }
  // public static zoomToGraphics(mapView: esri.MapView, graphics = []) {
  //   return mapView
  //     ?.goTo(graphics)
  //     .then(() => {
  //       const homeIcon = document.getElementById('home-icon');
  //       homeIcon.style.visibility = 'visible';
  //     })
  //     .catch(error => {
  //       console.log('Warning: Goto is inerrupted ');
  //     });
  // }
}
