import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftPaginatorComponent } from './left-paginator/left-paginator.component';
import { RightPaginatorComponent } from './right-paginator/right-paginator.component';
import { PaginatorComponent } from './paginator.component';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [PaginatorComponent, RightPaginatorComponent, LeftPaginatorComponent],
  imports: [CommonModule, MaterialModule, NgbPaginationModule, TranslateModule.forRoot()],
  exports: [RightPaginatorComponent, LeftPaginatorComponent, PaginatorComponent],
})
export class PaginatorModule { }
