import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from "src/app/shared/auth/auth.service";


@Component({
  selector: 'app-my-account-payment-type-selector',
  templateUrl: './payment-selector.component.html',
  styleUrls: ['./payment-selector.component.scss'],
})
export class PaymentTypeLinkSelectorComponent implements OnInit, OnDestroy {

    guestCheckoutSelected = false;
    ivrSelected = false;

  subscription = new Subscription();
  isLoading = false;

  accountNumber: string;
  userPhoneNumbers: string = "";
  userEmails: string = "";

  isOnFive9Call = false;
  tooltipText ="";

  toolDescription = "This tool is meant to create a link (url) for the customer to then access to complete a secure payment. \n Secure Payment can currently be used to complete payment's for user's total Account, selected Invoices, or used for deposits. \n Please select if you would like to create a secure link for a specifc account, or a deposit for a new user.";


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

    ngOnInit(){
      this.isOnFive9Call = sessionStorage.getItem('callId') != null;
      this.subscription.add(
        this.route.queryParams.subscribe(params => {
          this.accountNumber = params['number']; // Access the 'number' query parameter
          this.userPhoneNumbers = params['userPhoneNumbers'];
          this.userEmails = params['userEmails'];
        })
      );
      this.subscription.add(this.translate.get('ivr_five9_permission').subscribe((res: string) => {
        this.tooltipText = res;
      }));
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  onGuestCheckoutClicked()
  {
    this.guestCheckoutSelected = true;
    this.ivrSelected = false;
  }


  onIvrClcked()
  {
    this.guestCheckoutSelected = false;
    this.ivrSelected = true;
  }

}
