import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../material.module";
import { Breadcrumb } from "./breadcrumb.component";

@NgModule({
  declarations: [Breadcrumb],
  imports: [CommonModule, MaterialModule],
  exports: [Breadcrumb],
  entryComponents: [Breadcrumb],
})
export class BreadcrumbModule {}
