import esri = __esri;
import { InjectionToken } from '@angular/core';

export const API_KEY_TOKEN = new InjectionToken('Map API key access token');

export class MapConfig {
  apiKey: string;
}

export enum EsriModuleEnum {
  Circle = 'esri/geometry/Circle',
  Draw = 'esri/views/draw/Draw',
  Graphic = 'esri/Graphic',
  GraphicsLayer = 'esri/layers/GraphicsLayer',
  Map = 'esri/Map',
  MapView = 'esri/views/MapView',
  Polyline = 'esri/geometry/Polyline',
  SimpleMarkerSymbol = 'esri/symbols/SimpleMarkerSymbol',
  SimpleLineSymbol = 'esri/symbols/SimpleLineSymbol',
  Editor = 'esri/widgets/Editor',
  PopupTemplate = 'esri/PopupTemplate',
  Search = 'esri/widgets/Search',
  Sketch = 'esri/widgets/Sketch',
  WebMercatorUtils = 'esri/geometry/support/webMercatorUtils',
  GeometryEngine = 'esri/geometry/geometryEngine',
  WatchUtils = 'esri/core/watchUtils',
  Point = 'esri/geometry/Point',
  Polygon = 'esri/geometry/Polygon',
  GeoEngine = 'esri/geometry/geometryEngine'
}

export class LatLng {
  lat?: number;
  lng?: number;
}


export class Polygon {
  attributes: { [key: string]: any };
  paths: LatLng[];
}

export class GraphicLayer {
  public vehicle: esri.GraphicsLayer;
  public order: esri.GraphicsLayer;
  public disposalSite: esri.GraphicsLayer;
  public disposalSiteLabel: esri.GraphicsLayer;
  public containerStorage: esri.GraphicsLayer;
  public containerCount: esri.GraphicsLayer;
  public serviceZone: esri.GraphicsLayer;
  public garage: esri.GraphicsLayer;
  public hover: esri.GraphicsLayer;
  public bestMatch: esri.GraphicsLayer;
  public service: esri.GraphicsLayer;
}

export class FilterLayers {
  serviceZone: esri.GraphicsLayer;
  vehicles: esri.GraphicsLayer;
  containerStorages: esri.GraphicsLayer;
  disposalSites: esri.GraphicsLayer;
  containers: esri.GraphicsLayer;
  businessUnits: esri.GraphicsLayer;
  garages: esri.GraphicsLayer;
}
