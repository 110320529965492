import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { BusinessUnitViewModel, Ecommerce_BlacklistClient, LookupClient, UpdateBlacklistCommand } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-blacklist-details-page",
  templateUrl: "./blacklist-details.component.html",
  styleUrls: ["./blacklist-details.component.scss"],
})
export class BlacklistDetailsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;
  id: number;
  isAllowedToDelete: boolean;

  isBusinessUnitsLoading: boolean;

  businessUnits: BusinessUnitViewModel[];

  businessUnitFormControl = new FormControl();

  formGroup = new FormGroup({
    id: new FormControl(null),
    uniqueId: new FormControl(""),
    address: new FormControl(""),
    city: new FormControl(""),
    state: new FormControl(""),
    postalCode: new FormControl(""),
    note: new FormControl(""),
  });


  constructor(
    private snackBarService: SnackBarService,
    private blacklistClient: Ecommerce_BlacklistClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private lookupClient: LookupClient,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToDelete = response.isAllowedToDeleteBillingCycles;
      }
    );
    this.id = this.activatedRoute.snapshot.params["id"];
    this.getBusinessUnits();
    this.loadDetails();
  }

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result;
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching business units error: " + err);
          }
        }
      )
    );
  }

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  loadDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.blacklistClient.getBlacklistDetails(
        this.id
      ).subscribe(
        (result) =>
        {
          const details = result.result;
          this.formGroup.patchValue({
            id: details.id,
            uniqueId : details.uniqueId,
            address: details.address,
            city: details.city,
            state: details.state,
            postalCode: details.postalCode,
            note: details.note,
          });
          this.businessUnitFormControl.patchValue(
            details.businessUnitId
          );
          setTimeout(() => {
          }, 2000);
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/blacklist");
  }

  update()
  {
    this.isLoading = true;
    const blacklist = new UpdateBlacklistCommand({
    id: this.id,
    businessUnitId : this.businessUnitFormControl.value,
    address: this.formGroup.value.address,
    city: this.formGroup.value.city,
    state: this.formGroup.value.state,
    postalCode: this.formGroup.value.postalCode,
    note: this.formGroup.value.note,

    });
    this.subscription.add(
        this.blacklistClient.updateBlacklist(
            blacklist
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.address + " has been updated successfully !");
                window.location.reload();
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Update Blacklist Error: " + error);
        }
        )
    );
  }

  onDeleteClicked() {
    if (confirm("This will delete this address. Please confirm.")) {
    this.isLoading = true;
    this.subscription
      .add(
        this.blacklistClient
          .deleteBlacklist(this.formGroup.value.uniqueId)
          .subscribe(() => {
            this.snackBarService.ShowSuccess("Blacklist sucessfully deleted");
            this.router.navigateByUrl("/ecommerce/blacklist");
          },
            (error) => {
              try {
                this.snackBarService.ShowError(error);
              }
              catch { }
              this.isLoading = false;
            })
      );
          }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}