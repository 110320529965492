import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IntegrationErrorTableModel } from '../../models/entities';
import { ColumnDef } from './table.model';

@Component({
  selector: 'common-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @Input()
  dataSource: any[] = [];

  @Input()
  columnDefs: ColumnDef[] = [];

  @Output()
  mouseoverRow: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickRow: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pageChange: EventEmitter<any> = new EventEmitter<PageEvent>();

  @Output()
  clickId: EventEmitter<IntegrationErrorTableModel> = new EventEmitter<IntegrationErrorTableModel>();

  @Output()
  clickResubmit: EventEmitter<IntegrationErrorTableModel> = new EventEmitter<IntegrationErrorTableModel>();

  @Output()
  clickResolve: EventEmitter<IntegrationErrorTableModel> = new EventEmitter<IntegrationErrorTableModel>();

  @Input()
  orderTable: boolean;

  @Input()
  showPaginator: boolean;

  @Input()
  pageSize = 20;

  displayedColumns: string[] = [];
  columnDefLookup: { [propertyName: string]: ColumnDef } = {};
  tableDataSource: MatTableDataSource<any>;
  pageIndex: number;
  selection = new SelectionModel<any>(true, []);
  subscription = new Subscription();

  constructor(private transSer: TranslateService) { } 

  ngOnInit() {
    if (this.columnDefs) {
      this.displayedColumns = this.columnDefs.sort((a, b) => a.position - b.position).map(t => t.propertyName);
      this.displayedColumns.forEach(t => {
        this.columnDefLookup[t] = this.columnDefs.find(x => x.propertyName === t);
      });
      this.updatePagedDataSource();
    }
    this.transSer.get("items_per_page").subscribe(res => {
      this.paginator._intl.itemsPerPageLabel = res;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onPageChange(pageEvent: PageEvent) {
    this.paginator.pageIndex = pageEvent.pageIndex - 1;
    // https://github.com/angular/components/issues/8417
    (this.paginator as any)._changePageSize(this.paginator.pageSize);
    this.pageChange.emit(pageEvent);
  }

  onPagSizeChange(pageEvent: PageEvent) {
    this.pageChange.emit(pageEvent);
  }

  updatePagedDataSource() {
    this.tableDataSource = new MatTableDataSource(this.dataSource);
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
  }

  getColumnDef(propertyName: string) {
    return this.columnDefLookup[propertyName];
  }

  getCellValue(propertyName: string, element: any) {
    return element[propertyName];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
    if (changes.dataSource) {
      if (this.dataSource) {
        // Set to unchecked if the datasouce check is false
        this.dataSource.forEach(elem => {
          if (elem.checked === false) {
            this.selection.deselect(elem);
          }
        });
      }
    }
  }

  onMouseoverRow(row = null) {
    this.mouseoverRow.next(row);
  }

  onClickRow(row) {
    this.clickRow.next(row);
  }

  onClickId(row : IntegrationErrorTableModel) {
    this.clickId.emit(row);
  }

  onResubmit(row : IntegrationErrorTableModel) {
    this.clickResubmit.emit(row);
  }

  onResolve(row : IntegrationErrorTableModel) {
    this.clickResolve.emit(row);
  }
}
