import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbModule } from 'src/app/shared/widgets/breadcrumb/breadcrumb.module';
import { TableModule } from 'src/app/shared/widgets/table/table.module';
import { ErrorDetailsComponent } from './error-details/error-details.component';
import { ErrorHandlingComponent } from './error-handling/error-handling.component';
import { MatIconModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { IntegrationErrorServiceToken } from './error-handling';
import { IntegrationErrorService } from 'src/app/shared/integration-error.service';
import { IntegrationErrorClient } from 'src/app/apis/customer-care-api.generated';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    ErrorHandlingComponent, 
    ErrorDetailsComponent
  ],
  imports: [
    CommonModule, 
    BreadcrumbModule, 
    TableModule, 
    MatIconModule, 
    MatToolbarModule,
    MatTooltipModule,
    TranslateModule
  ],
  providers: [
    {
      provide: IntegrationErrorServiceToken,
      useClass: IntegrationErrorService,
    },
    IntegrationErrorClient
  ],
  entryComponents: [
    ErrorDetailsComponent
  ]
})
export class ErrorHandlingModule { }
