import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import {
  RoleViewModel,
  BusinessUnitViewModel,
  LookupClient,
  GroupsClient,
  UpdateGroupCommand,
  UserDetailsViewModel,
  GroupBusinessUnitViewModel,
  GroupUserViewModel,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-edit-group-page",
  templateUrl: "./edit-group-page.component.html",
  styleUrls: ["./edit-group-page.component.scss"],
})
export class EditGroupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  roles: RoleViewModel[];

  totalBusinessUnits: BusinessUnitViewModel[];
  totalUsers: UserDetailsViewModel[];

  loading: boolean;
  usersLoading: boolean;
  groupBUsloading: boolean;
  groupDetailsloading: boolean;

  changesMade: boolean = false;
  id: number;

  groupFormGroup = new FormGroup({
    groupId: new FormControl(0),
    groupName: new FormControl("", [this.checkEmptyString]),
    businessUnits: new FormArray([]),
    users: new FormArray([])
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private groupsClient: GroupsClient,
    private lookupClient: LookupClient,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params["groupId"];
    this.loadRoles();
    this.loadBusinessUnits();
    this.loadUsers();
    this.loadGroupDetails();
    this.loadGroupUsers();
    this.loadGroupBusinessUnits();
  }

  loadRoles() {
    this.lookupClient.getAllRoles().subscribe((response) => {
      this.roles = response.result;
    });
  }

  loadUsers() {
    this.lookupClient.getAllUsers().subscribe((response) => {
      this.totalUsers = response.result;
    });
  }

  loadGroupDetails() {
    this.groupDetailsloading = true;
    this.subscription.add(
      this.groupsClient.getGroupDetails(this.id).subscribe((response) => {
        const group = response.result;
        this.groupFormGroup.patchValue({
          groupId: group.id,
          groupName: group.groupName,
        });
        this.businessUnits.clear();
        for (const bu of group.groupBusinessUnits) {
          const buControl = new FormGroup({
            userId: new FormControl(bu.groupId),
            businessUnitId: new FormControl(bu.businessUnitId),
            businessUnitName: new FormControl(bu.businessUnitName),
            businessUnitCode: new FormControl(bu.businessUnitCode)
          });
          this.businessUnits.push(buControl);
        }
      },
        (error) => {
          this.groupDetailsloading = false;
          this.snackBarService.ShowError("Group Details Error: " + error);
        }
      ));
    this.groupDetailsloading = false;
  }

  loadGroupBusinessUnits() {
    this.groupBUsloading = true;
    this.subscription.add(
      this.groupsClient.getGroupBusinessUnits(this.id).subscribe((response) => {
        const group = response.result;
        this.businessUnits.clear();
        for (const bu of group.userBusinessUnits) {
          const buControl = new FormGroup({
            groupId: new FormControl(bu.groupId),
            userId: new FormControl(bu.groupId),
            businessUnitId: new FormControl(bu.businessUnitId),
            businessUnitName: new FormControl(bu.businessUnitName),
            businessUnitCode: new FormControl(bu.businessUnitCode)
          });
          this.businessUnits.push(buControl);
        }
      },
        (error) => {
          this.groupDetailsloading = false;
          this.snackBarService.ShowError("Group Business Units Error: " + error);
        }
      ));
    this.groupBUsloading = false;
  }

  loadGroupUsers() {
    this.usersLoading = true;

    this.subscription.add(
      this.groupsClient.getGroupUsers(this.id).subscribe((response) => {
        const group = response.result;
        this.users.clear();
        for (const bu of group.groups) {
          const buControl = new FormGroup({
            userId: new FormControl(bu.userId),
            fullName: new FormControl(bu.fullName),
            roleId: new FormControl(bu.roleId)
          });
          this.users.push(buControl);
        }
      }));
    this.usersLoading = false;
  }

  get businessUnits() {
    return this.groupFormGroup.get("businessUnits") as FormArray;
  }

  get users() {
    return this.groupFormGroup.get("users") as FormArray;
  }

  addUser() {
    this.changesMade = true;
    const userControl = new FormGroup({
      userId: new FormControl(0),
      fullName: new FormControl(null),
      groupId: new FormControl(this.id),
      roleId: new FormControl(null),
      roleName: new FormControl(null),
    });
    this.users.insert(0, userControl);
  }

  removeUser(x) {
    this.changesMade = true;
    this.users.removeAt(x);
  }
  getUserId = (u: UserDetailsViewModel) => u.id;

  getFullName = (u: UserDetailsViewModel) => `${u.fullName} (${u.id})`;

  addBusinessUnit() {
    this.changesMade = true;
    const buControl = new FormGroup({
      userId: new FormControl(this.id),
      businessUnitId: new FormControl(null),
      businessUnitName: new FormControl(null),
      businessUnitCode: new FormControl(null),
    });
    this.businessUnits.insert(0, buControl);
  }
  removeBusinessUnit(x) {
    this.changesMade = true;
    this.businessUnits.removeAt(x);
  }
  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);

  loadBusinessUnits() {
    this.lookupClient.getAllBusinessUnits().subscribe((response) => {
      this.totalBusinessUnits = response.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
    });
  }

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  updateGroup() {
    const group: UpdateGroupCommand = new UpdateGroupCommand({
      groupId: this.id,
      groupName: this.groupFormGroup.value.groupName,
      businessUnits: this.businessUnits.controls.map(
        (x) =>
          new GroupBusinessUnitViewModel({
            groupId: x.value.userId,
            businessUnitId: x.value.businessUnitId,
            businessUnitCode: x.value.businessUnitCode,
            businessUnitName: x.value.businessUnitName,
          })
      ),
      users: this.users.controls.map(
        (x) =>
          new GroupUserViewModel({
            userId : x.value.userId,
            roleId: x.value.roleId
          })
      )
    });
    this.loading = true;

    this.subscription.add(
      this.groupsClient.updateGroup(group).subscribe(
        (response) => {
          this.loading = false;

          if (response.isSuccess) {
            this.snackBarService.ShowSuccess(this.groupFormGroup.value.groupName + " has been updated successfully !");
            //this.router.navigateByUrl("/admin/groups");
            window.location.reload();
          }
        },
        (error) => {
          this.loading = false;
          this.snackBarService.ShowError(error);
        }
      ));
  }

  goBack() {
    if (!this.changesMade) {
      this.router.navigateByUrl("/admin/groups");
    }
    else {
      if (window.confirm("Changes were not saved. Do you want to discard changes ?")) {
        this.snackBarService.ShowSuccess('Changes were not saved !');
        this.router.navigateByUrl("/admin/groups");
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}