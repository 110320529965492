import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import {
  RoleViewModel,
  UserBusinessUnitViewModel,
  BusinessUnitViewModel,
  LookupClient,
  UsersClient,
  PermissionsClient,
  CreateUserCommand,
  GroupDetailsViewModel,
  GroupBusinessUnitViewModel,
  PermissionGroupDetailsViewModel,
  UserPermissionsViewModel,
  PermissionsViewModel,
  GroupPermissionViewModel,
  UserPermissionViewModel,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-create-user-page",
  templateUrl: "./create-user-page.component.html",
  styleUrls: ["./create-user-page.component.scss"],
})
export class CreateUserPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  roles: RoleViewModel[];

  totalBusinessUnits: BusinessUnitViewModel[];
  totalGroups: GroupDetailsViewModel[];

  allPermissionGroupsLoading: boolean;
  allPermissionGroups: PermissionGroupDetailsViewModel[];

  allPermissionsLoading: boolean;
  filledValues: boolean = false;
  permissionValues: boolean[] = [];
  allPermissions: PermissionsViewModel[];
  checkedPermissions: UserPermissionsViewModel[] = [];

  loading: boolean;

  userPermissionFormGroup = new FormGroup({
    userId: new FormControl(null),
    fullName: new FormControl(null),
    isSystemAdmin: new FormControl(false),
    isAllowedToDeleteFusionAutoPay: new FormControl(false),
    isAllowedToImpersonateInMyAccount: new FormControl(false),
    isAllowedToCreateNotification: new FormControl(false),
    isAllowedToViewRequestStats: new FormControl(false),
    isAllowedToEditGroups: new FormControl(false),
    isUserAllowedToSetStatusClosedNotAddressed: new FormControl(false),
    isAllowedToCreateManagedAutoPayMyAccountUser: new FormControl(false),
    isAllowedAccessToAllBusinessUnits: new FormControl(false),
    isAllowedToUseRecordSync: new FormControl(false),
    isAllowedToCreateBusinessUnit: new FormControl(false),
    isAllowedToCreateFacility: new FormControl(false),
    isAllowedToFilterByAssignee: new FormControl(false),
    isAllowedToViewRequests: new FormControl(false),
    isAllowedToViewErrorHandling: new FormControl(false),
    isAllowedToViewAllUsers: new FormControl(false),
    isAllowedToEditUsers: new FormControl(false),
    isAllowedToCreateUser: new FormControl(false),
    isAllowedToViewGroups: new FormControl(false),
    isAllowedToCreateGroups: new FormControl(false),
    isAllowedToViewBusinessUnits: new FormControl(false),
    isAllowedToViewFacilities: new FormControl(false),
    isAllowedToEditFacilities: new FormControl(false),
    isAllowedToDeleteFacilities: new FormControl(false),
    isAllowedToCreateMyAccountUser: new FormControl(false),
    isAllowedToUsePayMyBillValidation: new FormControl(false),
    isAllowedToViewNotifications: new FormControl(false),
    isAllowedToUseEmailLookup: new FormControl(false),
    isAllowedToTransferOwnership: new FormControl(false),
    isAllowedToMassTransferOwnership: new FormControl(false),
    isAllowedToUseAccountLookup: new FormControl(false),
    isAllowedToDeleteAzureProfile: new FormControl(false),
    isAllowedToDeleteEbilling: new FormControl(false),
    isAllowedToUseCustomerFinder: new FormControl(false),
    isAllowedToEditBusinessUnits: new FormControl(false),
    isAllowedToDeleteMyAccountProfile: new FormControl(false),
    isAllowedToViewReports: new FormControl(false),
    isAllowedToCreatePermissionGroups: new FormControl(false),
    isAllowedToViewAllPermissionGroups: new FormControl(false),
    isAllowedToEditPermissionGroups: new FormControl(false),
    isAllowedToUnlinkAccount: new FormControl(false),
    isAllowedToViewCSRBusinessUnitAccessReport: new FormControl(false),
    isAllowedToViewCustomerEmailsLinkedToAccountsReport: new FormControl(false),
    isAllowedToViewAccountsAutopayStatusReport: new FormControl(false),
    isAllowedToViewContactCorpSiteRequestTypeCountReport: new FormControl(false),
    isAllowedToViewOpenRequestsReport: new FormControl(false),
    isAllowedToUpdateBusinessUnitInvoicePresentment: new FormControl(false),
    isAllowedToViewEcommerceAreas: new FormControl(false),
    isAllowedToViewEcommerceBlacklist: new FormControl(false),
    isAllowedToViewEcommerceFees: new FormControl(false),
    isAllowedToViewPromoCodes: new FormControl(false),
    isAllowedToViewServiceCodes: new FormControl(false),
    isAllowedToViewServiceFrequencies: new FormControl(false),
    isAllowedToViewServiceTypes: new FormControl(false),
    isAllowedToViewBillingCycles: new FormControl(false),
    isAllowedToEditEcommerceAreas: new FormControl(false),
    isAllowedToEditEcommerceBlacklist: new FormControl(false),
    isAllowedToEditEcommerceFees: new FormControl(false),
    isAllowedToEditPromoCodes: new FormControl(false),
    isAllowedToEditServiceCodes: new FormControl(false),
    isAllowedToEditServiceFrequencies: new FormControl(false),
    isAllowedToEditServiceTypes: new FormControl(false),
    isAllowedToEditBillingCycles: new FormControl(false),
    isAllowedToCreateEcommerceAreas: new FormControl(false),
    isAllowedToCreateEcommerceBlacklist: new FormControl(false),
    isAllowedToCreateEcommerceFees: new FormControl(false),
    isAllowedToCreatePromoCodes: new FormControl(false),
    isAllowedToCreateServiceCodes: new FormControl(false),
    isAllowedToCreateServiceFrequencies: new FormControl(false),
    isAllowedToCreateServiceTypes: new FormControl(false),
    isAllowedToCreateBillingCycles: new FormControl(false),
    isAllowedToDeleteEcommerceAreas: new FormControl(false),
    isAllowedToDeleteEcommerceBlacklist: new FormControl(false),
    isAllowedToDeleteEcommerceFees: new FormControl(false),
    isAllowedToDeletePromoCodes: new FormControl(false),
    isAllowedToDeleteServiceCodes: new FormControl(false),
    isAllowedToDeleteServiceFrequencies: new FormControl(false),
    isAllowedToDeleteServiceTypes: new FormControl(false),
    isAllowedToDeleteBillingCycles: new FormControl(false),
    isAllowedToUseSecureCheckoutLinks: new FormControl(false),
    isAllowedToViewAccountEventLogs: new FormControl(false),
    isAllowedToCancelFuturePayment : new FormControl(false),
    isAllowedToVoidPayment : new FormControl(false),
  });

  userFormGroup = new FormGroup({
    firstName: new FormControl("", [this.checkEmptyString]),
    lastName: new FormControl("", [this.checkEmptyString]),
    emailAdress: new FormControl("", Validators.required),
    businessUnits: new FormArray([]),
    groupBusinessUnits: new FormArray([]),
    groups: new FormArray([]),
    permissions: new FormArray([]),
  });

  constructor(
    private router: Router,
    private usersClient: UsersClient,
    private lookupClient: LookupClient,
    private permissionsClient: PermissionsClient,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.loadAllPermissionGroups();
    this.loadAllPermissions();
    this.loadRoles();
    this.loadBusinessUnits();
    this.loadGroups();
  }

  getGroupId = (g: GroupDetailsViewModel) => g.id;

  getGroupName = (g: GroupDetailsViewModel) =>
    `${g.groupName} (${g.id})`;


  removePermissionGroup(x) {
    this.permissions.removeAt(x);
  }

  addPermissionGroup() {
    const pGroupControl = new FormGroup({
      id: new FormControl(0),
      groupName: new FormControl(null),
    });
    this.permissions.insert(0, pGroupControl);
  }

  loadAllPermissions() {
    this.allPermissionsLoading = true;

    this.permissionsClient.allPermissions().subscribe(
      (response) => {
        this.allPermissions = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });

    this.allPermissionsLoading = false;
  }

  loadAllPermissionGroups() {
    this.allPermissionGroupsLoading = true;

    this.permissionsClient.getAllPermissionGroups().subscribe(
      (response) => {
        this.allPermissionGroups = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
    this.allPermissionGroupsLoading = false;
  }

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  loadRoles() {
    this.lookupClient.getAllRoles().subscribe((response) => {
      this.roles = response.result;
    },
      (error) => {
        this.loading = false;
        console.error(error);
        this.snackBarService.ShowError(error);
      });
  }

  loadGroups() {
    this.lookupClient.getAllGroups().subscribe((response) => {
      this.totalGroups = response.result;
    },
      (error) => {
        this.loading = false;
        console.error(error);
        this.snackBarService.ShowError(error);
      });
  }

  loadBusinessUnits() {
    this.lookupClient.getAllBusinessUnits().subscribe((response) => {
      this.totalBusinessUnits = response.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
    },
      (error) => {
        this.loading = false;
        console.error(error);
        this.snackBarService.ShowError(error);
      });
  }

  get businessUnits() {
    return this.userFormGroup.get("businessUnits") as FormArray;
  }

  get permissions() {
    return this.userFormGroup.get("permissions") as FormArray;
  }

  get groups() {
    return this.userFormGroup.get("groups") as FormArray;
  }

  getPermissionGroupId = (p: any) => p.id;

  getPermissionGroupName = (p: any) => p.groupName;

  addBusinessUnitGroup() {
    const buGroupControl = new FormGroup({
      groupId: new FormControl(0),
      groupName: new FormControl(null),
      roleId: new FormControl(null),
      roleName: new FormControl(null),
    });
    this.groups.insert(0, buGroupControl);
  }
  removeBusinessUnitGroup(x) {
    this.groups.removeAt(x);
  }


  addBusinessUnit() {
    const buControl = new FormGroup({
      userId: new FormControl(null),
      businessUnitId: new FormControl(null),
      businessUnitName: new FormControl(null),
      businessUnitCode: new FormControl(null),
      roleId: new FormControl(null),
      roleName: new FormControl(null),
    });
    this.businessUnits.insert(0, buControl);
  }
  removeBusinessUnit(x) {
    this.businessUnits.removeAt(x);
  }
  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);

  create() {
    const user = new CreateUserCommand({
      firstName: this.userFormGroup.value.firstName.trim(),
      lastName: this.userFormGroup.value.lastName.trim(),
      fullName: this.userFormGroup.value.firstName.trim() + " " + this.userFormGroup.value.lastName.trim(),
      userName: this.userFormGroup.value.emailAdress.substring(0, this.userFormGroup.value.emailAdress.indexOf("@")),
      emailAddress: this.userFormGroup.value.emailAdress.trim(),
      businessUnits: this.businessUnits.controls.map(
        (x) =>
          new UserBusinessUnitViewModel({
            userId: 0,
            businessUnitId: x.value.businessUnitId,
            roleId: x.value.roleId,
            businessUnitCode: x.value.businessUnitCode,
            businessUnitName: x.value.businessUnitName,
            roleName: x.value.roleName,
          })
      ),
      groups: this.groups.controls.map(
        (x) =>
          new GroupBusinessUnitViewModel({
            userId: 0,
            groupId: x.value.groupId,
            roleId: x.value.roleId,
            businessUnitCode: x.value.businessUnitCode,
            businessUnitName: x.value.businessUnitName,
            roleName: x.value.roleName,
          })
      ),
      permissionGroups: this.permissions.controls.map(
        (x) =>
          new GroupPermissionViewModel({
            permissionGroupId: x.value.id,
            userId: 0
          })
      ),

      permissions: this.selectedPermissions()
    });
    this.subscription.add(
      this.usersClient.createUser(user).subscribe(
        (response) => {
          this.loading = false;
          if (response.isSuccess) {
            this.snackBarService.ShowSuccess(this.userFormGroup.value.firstName.trim() + " " +
              this.userFormGroup.value.lastName.trim() + " has been created successfully !");
            this.router.navigateByUrl("/admin/users");
          }
        },
        (error) => {
          this.loading = false;
          this.snackBarService.ShowError("Create User Error: " + error);
        }
      ));
  }

  selectedPermissions() {
    var result = [];

    Object.keys(this.userPermissionFormGroup.controls).forEach((key: string) => {
      const control = this.userPermissionFormGroup.get(key);
      if (control.value && control.value == true) {
        result.push(new UserPermissionViewModel({
          userId: 0,
          permissionName: key,
          permissionId: this.allPermissions.find(r => r.name.toLowerCase() == key.toLowerCase()).id
        }));
      }
    })

    return result;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}