import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LookupClient,
  MyAccountAccountsClient,
  PaymentLinkActionHistoryViewModel,
  PaymentLinkActionType,
  PaymentLinkClient,
  PaymentLinkViewModel,
  PaymentTypeEnum,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-my-account-userlink",
  templateUrl: "./link-details.component.html",
  styleUrls: ["./link-details.component.scss"],
})
export class LinkDetailsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private lookupClient: LookupClient,
    private snackBarService: SnackBarService,
    private paymentLinkClient: PaymentLinkClient,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  isLoading = false;
  isLoadingAccount = false;

  id: number;

  paymentLink: PaymentLinkViewModel;

  selectedInvoices = "";

  isIVR = true;

  linkClickedOn: boolean = false;
  linkClickedOnDate: Date;
  paymentEntered: boolean = false;
  paymentCompleted: boolean = false;
  paymentCompletedAmount: string = "";
  linkResent: boolean = false;
  linkResentOn: Date;
  errors: PaymentLinkActionHistoryViewModel[] = [];


  ngOnInit() {
    this.isLoading = true;
    this.id = this.activatedRoute.snapshot.params["id"];
    this.loadDetails();
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.paymentLinkClient.getPaymentLinkDetails(
        this.id
      ).subscribe(
        (result) => {
          this.paymentLink = result.result;
          if (this.paymentLink.paymentType == PaymentTypeEnum.CheckoutLink) {
            this.isIVR = false;
          }
          if (this.paymentLink.invoiceDetails && this.paymentLink.invoiceDetails.length > 0) {
            this.selectedInvoices = this.paymentLink.invoiceDetails.map(invoice => invoice.invoiceNumber).join(", ");
          }

          // populate payment link records here
          if (result.result.actionHistory != null) {
            if (result.result.actionHistory != null) {
              const clickedOnLinkRecord = result.result.actionHistory.find(record => record.action === PaymentLinkActionType.CustomerClickedOnLink);
              if (clickedOnLinkRecord) {
                this.linkClickedOn = true;
                this.linkClickedOnDate = clickedOnLinkRecord.createdDate;
              }

              const paymentProcessingRecord = result.result.actionHistory.find(record => record.action === PaymentLinkActionType.PaymentProcessing);
              if (paymentProcessingRecord) {
                this.paymentEntered = true;
              }

              const paymentCompleteRecord = result.result.actionHistory.find(record => record.action === PaymentLinkActionType.PaymentComplete);
              if (paymentCompleteRecord) {
                this.paymentCompleted = true;
              }
              const resentLinkRecords = result.result.actionHistory.filter(record => record.action === PaymentLinkActionType.ResendLink);
              if (resentLinkRecords.length > 0) {
                const lastResentRecord = resentLinkRecords.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime())[0];
                this.linkResent = true;
                this.linkResentOn = lastResentRecord.createdDate;
              }

              const errorRecords = result.result.actionHistory.filter(record => record.action === PaymentLinkActionType.Error);
              this.errors = errorRecords.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack()
  {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.router.navigate(['/payment-link'], { queryParams });
  }

  copyLink() {
    let data = ("");
    if (this.isIVR)
    {
      data = (
        this.paymentLink.uniqueId
      );
    }
    else{
      data = (
        this.paymentLink.paymentLinkUrl
      );
    }
    
    navigator.clipboard.writeText(data).then(
      success => this.snackBarService.ShowSuccess("Successfully copied")
    ).catch(
      e => this.snackBarService.ShowError("There was an issue while copying")
    );

  }

  resend() {
    this.subscription.add(
      this.paymentLinkClient.resendLink(
        this.id
      ).subscribe(
        (result) => {
          window.location.reload();
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

}
