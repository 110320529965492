import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { Ecommerce_ServiceTypeSortBySortBy, Ecommerce_ServiceTypeFilterByFilterBy, Ecommerce_ServiceTypeDetailsViewModel, Ecommerce_ServiceTypeClient, Ecommerce_ServiceTypeSortBy, Ecommerce_ServiceTypeFilterBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-service-types-lookup-page",
  templateUrl: "./service-types-lookup.component.html",
  styleUrls: ["./service-types-lookup.component.scss"],
})
export class ServiceTypesLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  pageIndex: number;
  pageSize: number;
  sortBy: Ecommerce_ServiceTypeSortBySortBy[] = [];
  filterBy: Ecommerce_ServiceTypeFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate : boolean;

  records: Ecommerce_ServiceTypeDetailsViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    serviceName: new FormControl(null),
  });

  constructor(
    private snackBarService: SnackBarService,
    private serviceTypeClient: Ecommerce_ServiceTypeClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToCreateEcommerceFees;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadServiceTypes();
  }

  loadServiceTypes()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.serviceTypeClient.serivceTypeListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new Ecommerce_ServiceTypeSortBySortBy({
        property: Ecommerce_ServiceTypeSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new Ecommerce_ServiceTypeFilterByFilterBy({
          property: Ecommerce_ServiceTypeFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.serviceName
    ) {
      this.filterBy.push(
        new Ecommerce_ServiceTypeFilterByFilterBy({
          property: Ecommerce_ServiceTypeFilterBy.ServiceName,
          contains: this.filterFormGroup.value.serviceName
        })
      );
    }

  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadServiceTypes();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      serviceName: null,
    });
    this.loadServiceTypes();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadServiceTypes();
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}