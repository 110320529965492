import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BusinessUnitClient, MyAccountUsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-my-account-delete-azure-profile',
  templateUrl: './my-account-delete-azure-profile.component.html',
  styleUrls: ['./my-account-delete-azure-profile.component.css']
})
export class MyAccountDeleteAzureProfileComponent  implements OnInit, OnDestroy {

  toolDescription = "This tool is meant to delete the Azure profile for a selected email address. \n This tool will delete the profile if the user exists in the Azure Active Directory but does not exist in MyAccount.";
  emailAddress = new FormControl(null, Validators.required);
  isLoading: boolean = false;

  private subscription: Subscription = new Subscription();

  get isValidEmailAddress(): boolean {
    return this.emailAddress.valid;
  }

  constructor(private userClient: MyAccountUsersClient,
    private snackbar: SnackBarService,
    private translate: TranslateService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  search() {
    this.isLoading = true;
    this.subscription
      .add(
        this.userClient
          .deleteB2CProfile(this.emailAddress.value)
          .subscribe(
            (r) => {
              this.snackbar.ShowSuccess(this.translate.instant("my_account_account_delete_azure.success"));
              this.isLoading = false;
            },
            (error) => {
              console.error(error);
              this.snackbar.ShowError(error);
              this.isLoading = false;
            }
          )
      );
  }
}
