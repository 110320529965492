import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  UserAccountStatus,
  MyAccountUsersClient,
  CandidatesForTransferResponse,
  CandidateForTransferViewModel,
} from 'src/app/apis/customer-care-api.generated';

import {
  IConfirmTransferOwnershipDialog,
  ConfirmTransferOwnershipDialogComponent,
} from './confirm-transfer-ownership-dialog/confirm-transfer-ownership-dialog.component';

import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import { ConfirmUnlinkAccountDialogComponent } from './confirm-unlink-account-dialog/confirm-unlink-account-dialog.component';
import { AuthService } from "src/app/shared/auth/auth.service";

@Component({
  selector: 'app-my-account-transfer-ownership',
  templateUrl: './my-account-transfer-ownership.component.html',
  styleUrls: ['./my-account-transfer-ownership.component.css'],
})
export class MyAccountTransferOwnershipComponent implements OnDestroy {

  @Input() normalSelected : boolean;

  accountNumber = new FormControl('', Validators.minLength(9));
  displayedUserColumns = ['oid', 'firstName', 'lastName', 'emailAddress', 'status', 'transferOwnership' , 'unLink'];
  findTransferCandidatesResponse: CandidatesForTransferResponse = null;
  subscription = new Subscription();
  isLoading = false;
  isAllowedToUnlink = false;

  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private myAccountUserClient: MyAccountUsersClient,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(u => {
            if(u) {
              this.isAllowedToUnlink = u.isAllowedToUnlinkAccount;
            }
          })
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isValidAccountNumber() {
    return this.accountNumber.value.trim().length > 0 && this.accountNumber.valid;
  }

  get myAccountTransferCandidatesFound() {
    return (
      this.findTransferCandidatesResponse &&
      this.findTransferCandidatesResponse.candidatesForTransfer &&
      this.findTransferCandidatesResponse.candidatesForTransfer.length
    );
  }

  formatAccountStatus(status: UserAccountStatus) {
    switch (status) {
      case UserAccountStatus.Approved:
        return 'Approved';
      case UserAccountStatus.PendingApproval:
        return 'Pending';
    }
  }

  search() {
    this.isLoading = true;
    this.findTransferCandidatesResponse = null;

    this.subscription.add(
      this.myAccountUserClient
        .findMyAccountCandidatesForTransfer(this.accountNumber.value.trim())
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.findTransferCandidatesResponse = res.result;
          },
          (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  onTransferOwnershipClicked(user: CandidateForTransferViewModel) {
    const dialogConfig = new MatDialogConfig();

    const data: IConfirmTransferOwnershipDialog = {
      accountNumber: this.accountNumber.value.trim(),
      newOwner: user.firstName + ' ' + user.lastName,
    };

    dialogConfig.width = '600px';
    dialogConfig.maxHeight = '800px';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;

    this.subscription.add(
      this.dialog
        .open(ConfirmTransferOwnershipDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.isLoading = true;
            this.myAccountUserClient
              .transferUserAccountOwnership(
                this.findTransferCandidatesResponse.accountOwner.ownerUniqueId,
                this.findTransferCandidatesResponse.accountUniqueId,
                user.uniqueId
              )
              .subscribe(
                () => {
                  this.isLoading = false;
                  this.accountNumber.setValue('');
                  this.findTransferCandidatesResponse = null;
                  this.snackBarService.ShowSuccess('Ownership transfer successful.');
                  this.normalSelected = false;
                  window.location.reload();
                },
                (error) => {
                  this.isLoading = false;
                  this.snackBarService.ShowError(error);
                }
              );
          }
        })
    );
  }

  onUnlinkUserClicked(user: CandidateForTransferViewModel) {
    const dialogConfig = new MatDialogConfig();

    const data: IConfirmTransferOwnershipDialog = {
      accountNumber: this.accountNumber.value.trim(),
      newOwner: user.firstName + ' ' + user.lastName + ' (' + user.emailAddress + ')',
    };

    dialogConfig.width = '600px';
    dialogConfig.maxHeight = '800px';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;

    this.subscription.add(
      this.dialog
        .open(ConfirmUnlinkAccountDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.isLoading = true;
            this.myAccountUserClient
              .unlinkUserAccount(
                this.findTransferCandidatesResponse.accountUniqueId,
                user.uniqueId
              )
              .subscribe(
                () => {
                  this.isLoading = false;
                  this.accountNumber.setValue('');
                  this.findTransferCandidatesResponse = null;
                  this.snackBarService.ShowSuccess('Account Unlinked successfully.');
                  this.normalSelected = false;
                  window.location.reload();
                },
                (error) => {
                  this.isLoading = false;
                  this.snackBarService.ShowError(error);
                }
              );
          }
        })
    );
  }


}
