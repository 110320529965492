import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "select-search",
  templateUrl: "./select-search.component.html",
})
export class SelectSearch implements OnInit, OnChanges {
  @Input() options: any[];
  @Input() getValueField: Function;
  @Input() getTextField: Function;
  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() allowCustomValue: boolean;
  @Input() label: string
  searchControl = new FormControl();
  filteredTextOptions: string[];

  refreshOptions() {
    if (this.options) {
      this.filteredTextOptions = this.options.map((option) =>
        this.getTextField(option).trim()
      );
    }
  }

  ngOnInit() {
    this.searchControl.valueChanges.subscribe((value) => {
      if (this.options) {
        const filteredOptions = this._filter(value);
        this.filteredTextOptions = filteredOptions.map((option) => this.getTextField(option).trim());
  
        const selectedOption = filteredOptions.find((option) => this.getTextField(option) === value);
  
        // If allowCustomValue is true and no option matches the input, use the input value directly
        if (this.allowCustomValue && !selectedOption) {
          this.control.setValue(value); // Directly use the typed value
        } else {
          this.control.patchValue(selectedOption ? this.getValueField(selectedOption) : null);
        }
      } else {
        this.filteredTextOptions = [];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options) {
      this.filteredTextOptions = this.options.map((option) =>
        this.getTextField(option).trim()
      );
      const currentSelectedOption = this.options.find(
        (option) => option.id === this.control.value
      );
      if (currentSelectedOption) {
        this.searchControl.setValue(this.getTextField(currentSelectedOption).trim());
      }
    } else {
      this.filteredTextOptions = [];
      this.searchControl.setValue("");
    }
  }

  private _filter(value) {
    const v = value ? value : "";
    const filterValue = v.toLowerCase().trim();
    return this.options.filter(
      (option) =>
        this.getTextField(option).toLowerCase().indexOf(filterValue) !== -1
    );
  }
}
