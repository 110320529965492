import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EsriModuleLoader } from '../../esri-module.loader';
import { EsriTypeFactory } from '../../esri-type.factory';
import { EsriModuleEnum } from '../../map.model';
import { MapService } from '../../map.service';
import { LatLng } from '../../map.model';
import { MapConfig } from '../../config';
import { FiltersConfig } from '../geofence.model';
import { MapFilterMenu } from './map-editor-filter/map-editor-filter.component';
import esri = __esri;

@Component({
  selector: 'map-common-geofence-editor2',
  templateUrl: './editor2.component.html',
  styleUrls: ['./editor2.component.scss']
})
export class Editor2Component implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('map', { static: true, read: ElementRef })
  map: ElementRef;

  @Input()
  showSelector: boolean;

  @Input() center: LatLng;

  @Input() filtersConfig: FiltersConfig;

  graphicsLayer: esri.GraphicsLayer;
  sketch: esri.Sketch;
  mapFilterConditions: MapFilterMenu;
  mapInit;
  containerStoragesForMap;
  disposalSitesForMap;
  businessUnitsForMap;
  polygonRings;
  businessUnitIDs: number[] = [];

  esriMap;

  graphicLayers = {
    serviceZone: null,
    vehicles: null,
    containerStorages: null,
    disposalSites: null,
    containers: null,
    businessUnits: null
  };

  mapFilterByBusinessUnits = false;

  subscription = new Subscription();

  loggedInBusinessUnits: any;
  esriLocationMap;

  constructor(
    private esriTypeFactory: EsriTypeFactory,
    private esriModuleLoader: EsriModuleLoader,
    private mapService: MapService,
  ) {

  }
  ngAfterViewInit() {
  }

  async ngOnInit() {
    this.mapService.mapPointChange.subscribe(res => {
      if (res) {
        this.center = res;
      }
    })

    this.mapInit = this.initEsri();
  }

  async initEsri() {
    await this.esriModuleLoader.loadModules([
      EsriModuleEnum.Map,
      EsriModuleEnum.MapView,
      EsriModuleEnum.GraphicsLayer,
      EsriModuleEnum.Graphic,
      EsriModuleEnum.Sketch,
      EsriModuleEnum.WebMercatorUtils
    ]);
    if (!this.center) {
      this.center = { lng: -79.3831843, lat: 43.653226 };
    }
    this.esriMap = await this.mapService.createMap(this.map.nativeElement, { point: this.center, zoom: 12 }, 'streets-navigation-vector');
    this.esriLocationMap = await this.mapService.createLocationSelectMap(this.map.nativeElement, { map: this.esriMap.map, mapView: this.esriMap.mapView, point: this.center });
    MapConfig.init(this.esriTypeFactory, this.esriModuleLoader, this.esriMap.mapView);
    const mapFilter = document.getElementById('map-filterd');
    const mapSelector = document.getElementById('map-selector');

    if (mapFilter) {
      this.esriMap.mapView.ui.add(mapFilter, 'manual');
    }

    if (mapSelector) {
      this.esriMap.mapView.ui.add(mapSelector, 'bottom-right');
    }
    return this;
  }

  async ngOnChanges(changes) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clearPolygon() {
    this.graphicsLayer.removeAll();
    this.esriLocationMap.clearGraphics();
    if (this.center) {
      this.esriLocationMap.setPoint(this.center);
      this.esriLocationMap.center();
    }
  }

  mapFilterChange($event: MapFilterMenu) {
  }

  onBasemapChange(event: string) {
    if (this.esriMap) {
      this.esriMap.map.basemap = event as any;
    }
  }
}
