import { MapConfig } from './config';
import esri = __esri;
import { EsriModuleEnum, LatLng } from './map.model';

export interface Polygon {
  type: string;
  coordinates: [];
}


export class ServiceZonex {
  public static mapPolygon(coordinates) {
    if (!coordinates) {
      return;
    }
    if(coordinates.length > 1)
    {
      return coordinates;
    }
    var result = [];
    for (var i =0; i < coordinates.length; i++)
    {
      var temp = coordinates[i];
      for(var x =0; x < temp.length;x++)
      {
        result.push(temp[x]);
      }
    }
    return result;
  }
  public static draw(coordinates) {
    const fillSymbol = {
      type: 'simple-fill', // autocasts as new SimpleFillSymbol()
      color: [151, 215, 0, 0.6],
      outline: {
        color: [0, 0, 0],
        width: 1
      }
    };
    const polygon = {
      type: 'polygon',
      rings: this.mapPolygon(coordinates)
    };
    const graphic = MapConfig.esriTypeFactory.create<esri.Graphic>(EsriModuleEnum.Graphic, {
      geometry: polygon,
      symbol: fillSymbol,
    });


    return graphic;
  }

  
  public static markZones(shape : string, graphicLayers = null) {
    if (!graphicLayers) {
      graphicLayers = MapConfig.esriTypeFactory.create<esri.GraphicsLayer>(EsriModuleEnum.GraphicsLayer, { graphics: [] });
    }
    let jsonObj =  JSON.parse(shape);
    let zone = jsonObj as Polygon;

    for(var i =0; i < zone.coordinates.length; i++)
    {
      const graphic = this.draw(zone.coordinates[i]);
      if(graphic)
      {
        graphicLayers.add(graphic);
      }
    }
    return graphicLayers;
  }
}
