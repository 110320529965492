import esri = __esri;
import { Injectable } from '@angular/core';
import { EsriModuleLoader } from './esri-module.loader';
import { EsriTypeFactory } from './esri-type.factory';
import { EsriModuleEnum, Polygon } from './map.model';

@Injectable()
export class PolygonViewMap {
  map: esri.Map;
  mapView: esri.MapView;
  pin: esri.Graphic;
  graphicsLayer: esri.GraphicsLayer;
  polygons: Polygon[] = [];
  selected: Polygon;
  zoneLabel: HTMLElement;
  hiddenMode = false;

  get defaultSymbol() {
    return {
      type: 'simple-fill', // autocasts as new SimpleFillSymbol()
      color: [0, 0, 0, 0.38],
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: [0, 0, 0],
        width: 1,
        style: 'short-dot'
      }
    };
  }
  get highlightSymbol() {
    return {
      type: 'simple-fill', // autocasts as new SimpleFillSymbol()
      color: [255, 158, 108, 0.42],
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: [255, 158, 108],
        width: 1,
        style: 'short-dot'
      }
    };
  }

  constructor(private esriModuleLoader: EsriModuleLoader, private esriTypeFactory: EsriTypeFactory) {}

  async init(container: HTMLElement, properties: any, basemap: string) {
    await this.esriModuleLoader.loadModules([EsriModuleEnum.Map, EsriModuleEnum.MapView, EsriModuleEnum.GraphicsLayer, EsriModuleEnum.Graphic]);

    // eslint-disable-next-line object-shorthand
    this.map = this.esriTypeFactory.create<esri.Map>(EsriModuleEnum.Map, { basemap: basemap });

    this.mapView = this.esriTypeFactory.create<esri.MapView>(EsriModuleEnum.MapView, Object.assign(properties, { map: this.map, container }));

    this.graphicsLayer = this.esriTypeFactory.create<esri.GraphicsLayer>(EsriModuleEnum.GraphicsLayer);

    this.map.layers.add(this.graphicsLayer);

    if (properties.polygons) {
      this.polygons = properties.polygons;
    }
    if (!this.zoneLabel) {
      this.zoneLabel = document.createElement('div');
      this.zoneLabel.id = 'zone-name';
      container.appendChild(this.zoneLabel);
    }

    this.mapView.on('pointer-move', evt => {
      evt.stopPropagation();
      const screenPoint = {
        x: evt.x,
        y: evt.y
      };
    });

    return this;
  }
  clearHighlight() {
    if (this.zoneLabel) {
      this.zoneLabel.classList.remove('show');
      this.zoneLabel.classList.add('hide');
    }
    // restore default symbol for all polygons
    this.graphicsLayer.graphics.forEach(t => {
      t.symbol = this.defaultSymbol as any;
      if (this.hiddenMode) {
        t.visible = false;
      }
    });
  }
  highlightGraphic(graphic: esri.Graphic) {
    this.clearHighlight();
    if (graphic.geometry != null) {
      const scrPoint = this.mapView.toScreen((graphic.geometry as esri.Polygon).centroid as esri.Point);
      if (scrPoint && graphic.attributes.title) {
        this.zoneLabel = document.getElementById('zone-name');
        this.zoneLabel.style.left = scrPoint.x + 'px';
        this.zoneLabel.style.top = scrPoint.y + 'px';
        this.zoneLabel.classList.add('show');
        this.zoneLabel.classList.remove('hide');
        this.zoneLabel.innerHTML = `<span class="label label-primary">${graphic.attributes.title}</span>`;
      }
      graphic.symbol = this.highlightSymbol as any;
      graphic.visible = true;
    } else {
      this.zoneLabel.classList.add('hide');
      this.zoneLabel.classList.remove('show');
    }
  }

  render() {
    if (this.polygons && this.polygons.length) {
      this.polygons.forEach(t => {
        if (!t || !t.paths) {
          return;
        }
        // Create a polygon geometry
        const polygon = {
          type: 'polygon', // autocasts as new Polygon()
          rings: [t.paths.map(x => [x.lng, x.lat])]
        };

        // Add the geometry and symbol to a new graphic
        const graphic = this.esriTypeFactory.create<esri.Graphic>(EsriModuleEnum.Graphic, {
          geometry: polygon,
          symbol: this.defaultSymbol,
          attributes: Object.assign({ polygon: t }, t.attributes)
        });

        this.graphicsLayer.graphics.add(graphic);
      });
    }
  }
  highlight(polygon: Polygon) {
    // const graphic = this.graphicsLayer.graphics.toArray().find(t => t.attributes.polygon === polygon);
    // if (graphic) {
    //   this.highlightGraphic(graphic);
    // }
  }

  zoom(polygons: Polygon[] = []) {
    // const graphics = this.graphicsLayer.graphics.toArray().filter(t => polygons.indexOf(t.attributes.polygon) >= 0);
    // return this.mapView?.goTo({ target: graphics }).catch(error => {
    //   console.log('Warning: Goto is inerrupted ');
    // });
  }

  showAll() {
    this.graphicsLayer.graphics.forEach(t => {
      t.visible = true;
    });
  }
  hideAll() {
    this.graphicsLayer.graphics.forEach(t => {
      t.visible = false;
    });
  }

  destroy() {
    this.map.destroy();
    this.mapView.destroy();
    if (this.graphicsLayer) {
      this.map.layers.remove(this.graphicsLayer);
      this.graphicsLayer.destroy();
    }
  }
}
