import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { CreateAreaCommand, CreateBillingCycleCommand, CreateFeeCommand, Ecommerce_FeeClient, SR_BillingCycleClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-fee-create-page",
  templateUrl: "./fee-create-page.component.html",
  styleUrls: ["./fee-create-page.component.scss"],
})
export class FeeCreatePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    feeName: new FormControl(""),
    towerServiceCode: new FormControl(""),
    description : new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_FeeClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/fees");
  }

  create()
  {
    this.isLoading = true;
    const fee = new CreateFeeCommand({
    feeName: this.formGroup.value.feeName,
    towerServiceCode: this.formGroup.value.towerServiceCode,
    description: this.formGroup.value.description,
    });
    this.subscription.add(
        this.feeClient.createFee(
            fee
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.feeName + " has been created successfully !");
                this.router.navigateByUrl("/ecommerce/fees");
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Create Fee Error: " + error);
        }
        )
    );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}