import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { Ecommerce_ServiceFrequencyClient, UpdateServiceFrequencyCommand } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-service-frequency-details-page",
  templateUrl: "./service-frequency-details.component.html",
  styleUrls: ["./service-frequency-details.component.scss"],
})
export class ServiceFrequencyDetailsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;
  id: number;
  isAllowedToDelete: boolean;

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    serviceFrequency: new FormControl(""),
    description: new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_ServiceFrequencyClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToDelete = response.isAllowedToDeleteServiceFrequencies;
      }
    );
    this.id = this.activatedRoute.snapshot.params["id"];
    this.loadDetails();
  }

  loadDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.feeClient.getServiceFrequencyDetails(
        this.id
      ).subscribe(
        (result) =>
        {
          const details = result.result;
          this.formGroup.patchValue({
            id: details.id,
            uniqueId : details.uniqueId,
            serviceFrequency: details.serviceFrequency,
            description: details.description,
          });
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/service-frequencies");
  }

  
  update()
  {
    this.isLoading = true;
    const fee = new UpdateServiceFrequencyCommand({
    id: this.id,
    serviceFrequency: this.formGroup.value.serviceFrequency,
    description: this.formGroup.value.description,

    });
    this.subscription.add(
        this.feeClient.updateServiceFrequency(
            fee
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.serviceFrequency + " has been updated successfully !");
                window.location.reload();
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Update Service Frequency Error: " + error);
        }
        )
    );
  }

  onDeleteClicked() {
    if (confirm("This will delete this service frequency. Please confirm.")) {
    this.isLoading = true;
    this.subscription
      .add(
        this.feeClient
          .deleteServiceFrequency(this.formGroup.value.uniqueId)
          .subscribe(() => {
            this.snackBarService.ShowSuccess("Service Frequency sucessfully deleted");
            this.router.navigateByUrl("/ecommerce/service-frequencies");
          },
            (error) => {
              try {
                this.snackBarService.ShowError(error);
              }
              catch { }
              this.isLoading = false;
            })
      );
          }
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}