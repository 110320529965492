import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from "rxjs";
import { Ecommerce_BlacklistSortBySortBy, Ecommerce_BlacklistFilterByFilterBy, Ecommerce_BlacklistDetailsViewModel, Ecommerce_BlacklistClient, Ecommerce_BlacklistSortBy, Ecommerce_BlacklistFilterBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-blacklist-lookup-page",
  templateUrl: "./blacklist-lookup.component.html",
  styleUrls: ["./blacklist-lookup.component.scss"],
})
export class BlacklistLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  pageIndex: number;
  pageSize: number;
  sortBy: Ecommerce_BlacklistSortBySortBy[] = [];
  filterBy: Ecommerce_BlacklistFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate : boolean;

  records: Ecommerce_BlacklistDetailsViewModel[] = [];
  totalRecords: number;

  
  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    // businessUnitId : new FormControl(0),
    address: new FormControl(""),
    city: new FormControl(""),
    // state: new FormControl(""),
    postalCode: new FormControl(""),
    // note: new FormControl(""),
  });


  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_BlacklistClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToCreateEcommerceBlacklist;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadBlacklists();
  }

  loadBlacklists()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.feeClient.blacklistListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }


  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new Ecommerce_BlacklistSortBySortBy({
        property: Ecommerce_BlacklistSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new Ecommerce_BlacklistFilterByFilterBy({
          property: Ecommerce_BlacklistFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.address
    ) {
      this.filterBy.push(
        new Ecommerce_BlacklistFilterByFilterBy({
          property: Ecommerce_BlacklistFilterBy.Address,
          contains: this.filterFormGroup.value.address
        })
      );
    }

    if (
      this.filterFormGroup.value.city
    ) {
      this.filterBy.push(
        new Ecommerce_BlacklistFilterByFilterBy({
          property: Ecommerce_BlacklistFilterBy.City,
          contains: this.filterFormGroup.value.city
        })
      );
    }

    if (
      this.filterFormGroup.value.postalCode
    ) {
      this.filterBy.push(
        new Ecommerce_BlacklistFilterByFilterBy({
          property: Ecommerce_BlacklistFilterBy.PostalCode,
          contains: this.filterFormGroup.value.postalCode
        })
      );
    }

  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadBlacklists();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      address: null,
      city: null,
      postalCode: null,
    });
    this.loadBlacklists();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadBlacklists();
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}