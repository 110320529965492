import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MyAccountLookupViewModel,
  MyAccountProfileLookupViewModel,
  MyAccountUsersClient,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-my-account-find-accounts",
  templateUrl: "./my-account-find-accounts.component.html",
  styleUrls: ["./my-account-find-accounts.component.scss"],
})

export class MyAccountFindAccountsComponent implements OnInit {
  constructor(
    private myAccountUsersClient: MyAccountUsersClient,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {}

  isLoading = false;
  emailSearched = false;

  accounts = [];
  user: MyAccountLookupViewModel;

  email = new FormControl("", Validators.email);

  displayedColumns: string[] = [
    "firstName",
    "lastName",
    "createdDate",
    "lastLogin",
    "idp",
    "linkedAccounts",
    "linkedSites",
    "oid",
  ];

  toolDescription = "This tool is meant to find all user profiles related to the selected email address. \n This tool will list all of the MyAccount profiles that have this email address as their primary email. \n By clicking on the OId, more details and linked Accounts/Sites for that profile will be shown, along with the ability to delete that profile. \n The delete tool is meant to be used to resolve confusion over multiple accounts associated with the same email.\n If the profile is the owner of accounts that are linked to other users, the deletion will not occur and the ownership must be transferred prior to deletion.";

  get isValidEmail() {
    return (this.email.value || "").trim().length > 0 && this.email.valid;
  }

  search() {
    this.accounts = [];
    this.isLoading = true;

    this.myAccountUsersClient
    .findAllAccounts(this.email.value.trim())
    .subscribe(
      (res) => {
        this.accounts = res.result;
        this.emailSearched = true
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackBarService.ShowError(
          "Search Error: " + error
          );
      }
    );
  }

  moreDetails(myaccountProfile : MyAccountProfileLookupViewModel)
  {
    this.isLoading = true;
    this.user = null;
    this.myAccountUsersClient
      .searchForMyAccountUser(this.email.value.trim(), myaccountProfile.uniqueId)
      .subscribe(
        (x) => {
          this.user = x.result;
          this.isLoading = false;
          window.scrollTo({ top: 80 });
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(
            "Search Error: " + error
          );
        }
      );

  }

  get showResult() {
    return !this.user && !this.isLoading;
  }

  ngOnInit() {
  }
}
