import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { SR_BillingCycleClient, UpdateBillingCycleCommand } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-billing-cycles-details-page",
  templateUrl: "./billing-cycles-details.component.html",
  styleUrls: ["./billing-cycles-details.component.scss"],
})
export class BillingCyclesDetailsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;
  id: number;
  isAllowedToDelete: boolean;

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    billMonth: new FormControl(0),
    billMonthName: new FormControl(""),
    billingCycle: new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private billingCycleClient: SR_BillingCycleClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToDelete = response.isAllowedToDeleteBillingCycles;
      }
    );
    this.id = this.activatedRoute.snapshot.params["id"];
    this.loadDetails();
  }

  loadDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.billingCycleClient.getBillingCycleDetails(
        this.id
      ).subscribe(
        (result) =>
        {
          const details = result.result;
          this.formGroup.patchValue({
            id: details.id,
            uniqueId : details.uniqueId,
            billMonth: details.billMonth,
            billMonthName: details.billMonthName,
            billingCycle: details.billingCycle,
          });
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/billing-cycles");
  }

  update()
  {
    this.isLoading = true;
    const area = new UpdateBillingCycleCommand({
    id: this.id,
    billingCycle: this.formGroup.value.billingCycle,
    billMonthName: this.formGroup.value.billMonthName,
    billMonth: this.formGroup.value.billMonth,

    });
    this.subscription.add(
        this.billingCycleClient.updateBillingCycle(
            area
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.billingCycle + " has been updated successfully !");
                window.location.reload();
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Update Billing Cycle Error: " + error);
        }
        )
    );
  }

  onDeleteClicked() {
    if (confirm("This will delete this billing cycle. Please confirm.")) {
    this.isLoading = true;
    this.subscription
      .add(
        this.billingCycleClient
          .deleteBillingCycle(this.formGroup.value.uniqueId)
          .subscribe(() => {
            this.snackBarService.ShowSuccess("Billing Cycle sucessfully deleted");
            this.router.navigateByUrl("/ecommerce/billing-cycles");
          },
            (error) => {
              try {
                this.snackBarService.ShowError(error);
              }
              catch { }
              this.isLoading = false;
            })
      );
          }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}