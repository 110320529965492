import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmTransferOwnershipDialog {
  accountNumber: string;
  newOwner: string;
}

@Component({
  selector: 'app-confirm-transfer-ownership-dialog',
  templateUrl: './confirm-transfer-ownership-dialog.component.html',
  styleUrls: ['./confirm-transfer-ownership-dialog.component.css']
})
export class ConfirmTransferOwnershipDialogComponent implements OnInit {

  isLoading: boolean = false;
  
  constructor(private dialog: MatDialogRef<ConfirmTransferOwnershipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public request: IConfirmTransferOwnershipDialog) { }

  ngOnInit() {
  }

  confirmCancel() {
    this.dialog.close(false);
  }

  confirmSubmit() {
    this.dialog.close(true);
  }

}
