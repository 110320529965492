import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { RequestsClient, RequestStatus, RequestType, RequestViewModel, IMyAccountErpNumberValidationAccountViewModel } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

export interface ICloseRequestNotesDialog {
  status: RequestStatus;
  type: RequestType;
}

@Component({
  selector: 'app-close-request-notes-dialog',
  templateUrl: './close-request-notes-dialog.component.html',
  styleUrls: ['./close-request-notes-dialog.component.scss']
})
export class CloseRequestNotesDialogComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  closeRequestFormGroup: FormGroup;

  isErpCustomerIdRequired = false;
  isLoading = false;

  erpCustomerDetails: IMyAccountErpNumberValidationAccountViewModel;
  showErpCustomerDetails=false;

  onCloseRequestClicked() {
    if (this.closeRequestFormGroup.invalid) {

      this.snackBarService.ShowError(this.isErpCustomerIdRequired ? 'ERP Customer Number and Notes are mandatory !' : 'Notes are mandatory !');
      this.closeRequestFormGroup.markAllAsTouched();
    } else {

      if (this.isErpCustomerIdRequired) {

        this.isLoading = true;

        this.subscription.add(
          this.requestsClient.validateErpAccountNumber(this.closeRequestFormGroup.value.erpCustomerId).subscribe(response => {
            this.isLoading = false;
            this.erpCustomerDetails = response.result;
            this.showErpCustomerDetails=true;
          },
            err => {
              this.snackBarService.ShowError('Invalid ERP Customer Number !');
              this.isLoading = false;
            })
        );

      } else {
        this.dialog.close(this.closeRequestFormGroup.value);
      }
    }
  }

  cancelErpCustomerDetails(){
    this.showErpCustomerDetails=false;
  }

  confirmSubmit(){
    this.dialog.close(this.closeRequestFormGroup.value);
  }

  constructor(private snackBarService: SnackBarService, private dialog: MatDialogRef<CloseRequestNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public request: ICloseRequestNotesDialog,
    private requestsClient: RequestsClient) { }

  ngOnInit() {

    console.log(this.request);

    if (this.request.type == RequestType.NewService && (this.request.status == RequestStatus.ClosedCompletedAccepted || this.request.status == RequestStatus.ClosedPaymentRefunded)) {
      this.closeRequestFormGroup = new FormGroup({
        erpCustomerId: new FormControl(null, Validators.required),
        notes: new FormControl(null, Validators.required)
      })
      this.isErpCustomerIdRequired = true;
    } else {

      this.isErpCustomerIdRequired = false;
      this.closeRequestFormGroup = new FormGroup({
        erpCustomerId: new FormControl(null),
        notes: new FormControl(null, Validators.required)
      })
    }


  }



}
