import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MyAccountUsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-my-account-delete-ebilling',
  templateUrl: './my-account-delete-ebilling.component.html',
  styleUrls: ['./my-account-delete-ebilling.component.css']
})
export class MyAccountDeleteEbillingComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  constructor(
    private userClient: MyAccountUsersClient,
    private snackbar: SnackBarService,
    private translate: TranslateService) { }

  emailAddress = new FormControl(null, Validators.required);
  isLoading: boolean = false;
  error: string;

  toolDescription = "This tool is meant to remove ebilling for a specified email address. \n This tool will find all accounts linked to the email address' profile, and will delete the autopay function from the accounts."

  ngOnInit() {
  }

  get isValidEmailAddress(): boolean {
    return this.emailAddress.valid;
  }

  deleteEbilling() {
    this.isLoading = true;
    this.error = '';
    this.subscription
      .add(
        this.userClient
          .deleteEbilling(this.emailAddress.value)
          .subscribe(
            (r) => {
              this.snackbar.ShowSuccess(this.translate.instant("my_account_account_delete_ebilling.success"));
              this.isLoading = false;
            },
            (error) => {
              this.error = error;
              this.isLoading = false;
            }
          )
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
