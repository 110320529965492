import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import {
  PermissionsClient,
  GroupsClient,
  PermissionsViewModel,
  GroupPermissionViewModel,
  CreatePermissionGroupCommand,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { TranslateService } from '@ngx-translate/core';
import { Location } from "@angular/common";


@Component({
  selector: "app-create-permission-group-page",
  templateUrl: "./create-permission-group-page.component.html",
  styleUrls: ["./create-permission-group-page.component.scss"],
})
export class CreatePermissionGroupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  totalPermissions: PermissionsViewModel[];


  loading: boolean;

  userFormGroup = new FormGroup({
    groupName: new FormControl("", [this.checkEmptyString]),
    permissions: new FormArray([]),
  });

  constructor(
    public location: Location,
    private router: Router,
    private groupsClient: GroupsClient,
    private permissionsClient: PermissionsClient,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadPermissions();
    this.loading = false;
  }

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  loadPermissions() {
    this.permissionsClient.allPermissions().subscribe(
      (response) => {
        this.totalPermissions = response.result;
      },
      (error) => {
        this.snackBarService.ShowError("Some error occured.");
      }
    );
  }

  get permissions() {
    return this.userFormGroup.get("permissions") as FormArray;
  }

  addBusinessUnit() {
    const buControl = new FormGroup({
      userId: new FormControl(null),
      permissionId: new FormControl(null),
      permissionName: new FormControl(null),
    });
    this.permissions.insert(0, buControl);
  }
  removeBusinessUnit(x) {
    this.permissions.removeAt(x);
  }
  getPermissionId = (p: PermissionsViewModel) => p.id;

  getPermissionName = (p: PermissionsViewModel) => this.translateService.instant(p.name);


  create() {
    const user = new CreatePermissionGroupCommand({
      groupName: this.userFormGroup.value.groupName.trim(),
      permissions: this.permissions.controls.map(
        (x) =>
          new GroupPermissionViewModel({
            permissionGroupId: 0,
            permissionId: x.value.permissionId,
            permissionName: x.value.permissionName,
          })
      )
    });
    this.subscription.add(
      this.permissionsClient.createPermissionGroup(user).subscribe(
        (response) => {
          this.loading = false;
          if (response.isSuccess) {
            this.snackBarService.ShowSuccess(this.userFormGroup.value.groupName + " has been created successfully !");
            this.router.navigateByUrl("/admin/permissions/groups");
          }
        },
        (error) => {
          this.loading = false;
          this.snackBarService.ShowError("Some error occured.");
        }
      ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}