import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import {
  BusinessUnitViewModel,
  LookupClient,
  GroupsClient,
  UserListViewModel,
  CreateGroupCommand,
  GroupBusinessUnitViewModel,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { Location } from "@angular/common";


@Component({
  selector: "app-create-group-page",
  templateUrl: "./create-group-page.component.html",
  styleUrls: ["./create-group-page.component.scss"],
})
export class CreateGroupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();


  users: UserListViewModel[];

  totalBusinessUnits: BusinessUnitViewModel[];

  loading: boolean;

  userFormGroup = new FormGroup({
    groupName: new FormControl("", [this.checkEmptyString]),
    businessUnits: new FormArray([]),
  });

  constructor(
    public location: Location,
    private router: Router,
    private groupsClient: GroupsClient,
    private lookupClient: LookupClient,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.loadBusinessUnits();
  }

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  loadBusinessUnits() {
    this.lookupClient.getAllBusinessUnits().subscribe((response) => {
      this.totalBusinessUnits = response.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
    });
  }

  get businessUnits() {
    return this.userFormGroup.get("businessUnits") as FormArray;
  }

  addBusinessUnit() {
    const buControl = new FormGroup({
      userId: new FormControl(null),
      businessUnitId: new FormControl(null),
      businessUnitName: new FormControl(null),
      businessUnitCode: new FormControl(null),
    });
    this.businessUnits.insert(0, buControl);
  }
  removeBusinessUnit(x) {
    this.businessUnits.removeAt(x);
  }
  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);

  create() {
    const user = new CreateGroupCommand({
      groupName: this.userFormGroup.value.groupName.trim(),
      businessUnits: this.businessUnits.controls.map(
        (x) =>
          new GroupBusinessUnitViewModel({
            groupId: 0,
            businessUnitId: x.value.businessUnitId,
            businessUnitCode: x.value.businessUnitCode,
            businessUnitName: x.value.businessUnitName,
          })
      )
    });
    this.subscription.add(
      this.groupsClient.createGroup(user).subscribe(
        (response) => {
          this.loading = false;
          if (response.isSuccess) {
            this.snackBarService.ShowSuccess(this.userFormGroup.value.groupName + " has been created successfully !");
            this.router.navigateByUrl("/admin/groups");
          }
        },
        (error) => {
          this.loading = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }
      ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}