import { Component, Input, OnInit } from "@angular/core";
import {
  MyAccountLookupViewModel,
  MyAccountUsersClient,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/shared/auth/auth.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-my-account-user-details",
  templateUrl: "./my-account-user-lookup.component.html",
  styleUrls: ["./my-account-user-lookup.component.css"],
})
export class MyAccountUserLookupComponent implements OnInit {
  constructor(
    private myAccountUsersClient: MyAccountUsersClient,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  @Input() user: MyAccountLookupViewModel;

  private subscription: Subscription = new Subscription();

  isLoading = false;

  isAllowedToDelete = false;

  get myAccountUserFound() {
    return (
      this.user &&
      this.user.myAccountUsers &&
      this.user.myAccountUsers.length
    );
  }

  displayedAccountColumns: string[] = [
    "accountName",
    "accountNumber",
    "billTo",
    "invoiceSource",
    "line1",
    "city",
    "province",
    "postalCode",
    "isOwner",
    "isApprovedUser",
  ];

  displayedSiteColumns: string[] = [
    "siteName",
    "siteNumber",
    "line1",
    "city",
    "province",
    "postalCode",
    "isOwner",
    "isApprovedUser",
  ];

  deleteUserProfile(myaccountProfile : MyAccountLookupViewModel)
  {
    this.isLoading = true;
      this.myAccountUsersClient
      .deleteMyAccountProfile(myaccountProfile.myAccountUsers[0].uniqueId)
      .subscribe(
        (res) => {
          this.snackBarService.ShowSuccess(this.translateService.instant("my_account_delete_account_success"));
          this.isLoading = false;
          this.user = null;
          window.location.reload();
        },
        (error) => {
          this.snackBarService.ShowError(error);
          this.isLoading = false;
        }
    );
  }

  ngOnInit() {
    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(u => {
            if(u) {
              this.isAllowedToDelete = u.isAllowedToDeleteMyAccountProfile;
            }
          })
      );
  }
}
