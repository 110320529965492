import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../material.module";
import { SelectSearch } from "./select-search.component";

@NgModule({
  declarations: [SelectSearch],
  imports: [CommonModule, MaterialModule],
  exports: [SelectSearch],
  entryComponents: [SelectSearch],
})
export class SelectSearchModule {}
