import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { Ecommerce_PromoCodeClient, Ecommerce_ServiceTypeDetailsViewModel, LookupClient, PromoCodeServiceViewModel, UpdatePromoCodeCommand } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-promo-codes-details-page",
  templateUrl: "./promo-codes-details.component.html",
  styleUrls: ["./promo-codes-details.component.scss"],
})
export class PromoCodesDetailsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;
  id: number;
  isAllowedToDelete: boolean;

  allServiceTypes : Ecommerce_ServiceTypeDetailsViewModel[];

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    code: new FormControl(""),
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    durationType: new FormControl(""),
    durationValue: new FormControl(0),
    durationUnit: new FormControl(""),
    discountType: new FormControl(""),
    discountValue: new FormControl(0),
    description: new FormControl(""),
    automatic: new FormControl(false),
    serviceTypes: new FormArray([]),
  });

  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_PromoCodeClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private lookupClient: LookupClient
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToDelete = response.isAllowedToDeletePromoCodes;
      }
    );
    this.id = this.activatedRoute.snapshot.params["id"];
    this.loadAllServiceTypes();
    this.loadCurrentServiceTypes();
    this.loadDetails();
  }

  loadAllServiceTypes()
  {
    this.lookupClient.getAllServiceTypes().subscribe(
      (response) => {
        this.allServiceTypes = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  loadCurrentServiceTypes()
  {
    this.feeClient.getPromoCodeServices(this.id).subscribe(
      (response) => {
        const currentPromoCodeServices = response.result;
        this.serviceTypes.clear();
        for (const p of currentPromoCodeServices)
        {
          const gControl = new FormGroup({
            id : new FormControl(p.serviceTypeId),
            serviceName: new FormControl(p.serviceName)
          });
          this.serviceTypes.push(gControl);
        }
      }
    )
  }

  addServiceType()
  {
    const pGroupControl = new FormGroup({
      id: new FormControl(0),
      serviceName: new FormControl(null),
    });
    this.serviceTypes.insert(0, pGroupControl);
  }

  get serviceTypes() {
    return this.formGroup.get("serviceTypes") as FormArray;
  }

  removeServiceType(x)
  {
    this.serviceTypes.removeAt(x);
  }

  getServiceTypeId (p: any)
  {
    return p.id;
  }

  getServiceTypeName (p: any) {
    return p.serviceName;
  }
  
  loadDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.feeClient.getPromoCodeDetails(
        this.id
      ).subscribe(
        (result) =>
        {
          const details = result.result;
          this.formGroup.patchValue({
            id: details.id,
            uniqueId: details.uniqueId,
            code: details.code,
            startDate: this.formatDate(details.startDate),
            endDate: this.formatDate(details.endDate),
            durationType: details.durationType,
            durationValue: details.durationValue,
            durationUnit: details.durationUnit,
            discountType: details.discountType,
            discountValue: details.discountValue,
            description: details.description,
            automatic: details.automatic,
          });
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/promo-codes");
  }

  update()
  {
    this.isLoading = true;
    const fee = new UpdatePromoCodeCommand({
    id: this.id,
    code: this.formGroup.value.code,
    startDate: this.formGroup.value.startDate,
    endDate: this.formGroup.value.endDate,
    durationType: this.formGroup.value.durationType,
    durationValue: this.formGroup.value.durationValue,
    durationUnit: this.formGroup.value.durationUnit,
    discountType: this.formGroup.value.discountType,
    discountValue: this.formGroup.value.discountValue,
    description: this.formGroup.value.description,
    automatic: this.formGroup.value.automatic,
    serviceTypes : this.serviceTypes.controls.map(
      (x) => new PromoCodeServiceViewModel({
        promoCodeId : this.id,
        serviceTypeId: x.value.id
      })
    )
    });
    this.subscription.add(
        this.feeClient.updatePromoCode(
            fee
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.code + " has been updated successfully !");
                window.location.reload();
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Update Promo Code Error: " + error);
        }
        )
    );
  }

  private formatDate(input: Date) {
    if(input == null) return null;
    let dateInLocal = new Date(input);
    let d = new Date(dateInLocal.getUTCFullYear(), dateInLocal.getUTCMonth(), dateInLocal.getUTCDate());
    let datestring = d.getFullYear().toString().padStart(4, '0') + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    return datestring;
  }

  onDeleteClicked() {
    if (confirm("This will delete this promo code. Please confirm.")) {
    this.isLoading = true;
    this.subscription
      .add(
        this.feeClient
          .deletePromoCode(this.formGroup.value.uniqueId)
          .subscribe(() => {
            this.snackBarService.ShowSuccess("Promo Codes sucessfully deleted");
            this.router.navigateByUrl("/ecommerce/promo-codes");
          },
            (error) => {
              try {
                this.snackBarService.ShowError(error);
              }
              catch { }
              this.isLoading = false;
            })
      );
          }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}