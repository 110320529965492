import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../material.module';
import { TableComponent } from './table.component';
@NgModule({
  imports: [
    CommonModule, 
    MatTableModule, 
    MatSortModule, 
    NgbModule, 
    MatPaginatorModule, 
    MatCheckboxModule, 
    DragDropModule, 
    FormsModule, 
    MaterialModule],
  declarations: [TableComponent],
  exports: [TableComponent]
})
export class TableModule {}
