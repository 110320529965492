import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BusinessUnitDetailViewModel, FacilityViewModel, BusinessUnitClient, FacilityClient, EditBusinessUnitCommand, FacilitySearchQuery, CreateBusinessUnitCommand } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-create-business-unit',
  templateUrl: './create-business-unit.component.html',
  styleUrls: ['./create-business-unit.component.scss']
})
export class CreateBusinessUnitComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  businessUnitDetail: BusinessUnitDetailViewModel = null;
  businessUnitGroup: FormGroup;
  facilities: FacilityViewModel[];

  private buId: string;
  private subscription: Subscription = new Subscription();
  private buCreated: BehaviorSubject<BusinessUnitDetailViewModel> =
  new BehaviorSubject<BusinessUnitDetailViewModel>(null);

  constructor(
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private businessUnitClient: BusinessUnitClient,
    private facilityClient: FacilityClient,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.buId = this.activatedRoute.snapshot.params["buId"];

    this.businessUnitGroup = this.fb.group({
      code: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      isActive: new FormControl(false, Validators.required),
      primaryFacilityId: new FormControl(''),
      five9EmailStartDate: new FormControl(null),
      five9EmailEndDate: new FormControl(null),
      five9DistributionEmails: new FormControl(''),
      newRequestDistributionEmails: new FormControl(''),
      isAllowedNewServiceRequest : new FormControl(false),
      isAllowedAddServiceRequest : new FormControl(false),
      isAllowedGetQuoteRequest : new FormControl(false),
      isAllowedPickupRequest : new FormControl(false),
      isAllowedContainerRequest : new FormControl(false),
      isAllowedBillingRequest : new FormControl(false),
      isAllowedContactMyAccountRequest : new FormControl(false),
      isAllowedNewServiceNoPaymentRequest : new FormControl(false),
    });

    this.loadRequest();
  }

  createandAddFacility() {
    this.onCreateConfirmed(true);
    this.buCreated.asObservable().subscribe((createdBu) => {
      if (createdBu) {
        this.router.navigate(["/admin/facility/create", { buId: createdBu.id, buUniqueId: createdBu.uniqueId }]);
      }
    });
  }

  onCreateConfirmed(isCreateFacility: boolean) {
    this.businessUnitGroup.markAllAsTouched();
    if (!this.businessUnitGroup.valid) {
      return false;
    }

    this.isLoading = true;

    var createCommand = this.businessUnitGroup.value as CreateBusinessUnitCommand;
    this.subscription
      .add(
        this.businessUnitClient
          .createBusinessUnit(createCommand)
          .subscribe((businessUnit) => {
            this.snackBarService.ShowSuccess("Business unit sucessfully created");
            this.isLoading = false;
            this.buCreated.next(businessUnit);
            if(!isCreateFacility)
            {
              this.router.navigate(["/admin/business_units/detail", businessUnit.uniqueId]);
            }
          },
            (error) => {
              try {
                console.error(error);
                this.snackBarService.ShowError(error);
              }
              catch { }
              this.isLoading = false;
            })
      );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.buCreated.unsubscribe();
  }

  private loadRequest() {
    this.isLoading = true;
    this.subscription
      .add(
        this.facilityClient.searchFacility(new FacilitySearchQuery())
          .subscribe(result => {
            this.facilities = result.records;
            this.isLoading = false;
          },
            (error) => {
              console.error(error);
              this.isLoading = false;
            })
      );
  }


  private fillBusinessUnitGroup() {
    this.businessUnitGroup.patchValue({
      code: this.businessUnitDetail.code,
      name: this.businessUnitDetail.name,
      isActive: this.businessUnitDetail.isActive != null ? this.businessUnitDetail.isActive : true,
      primaryFacilityId: (this.businessUnitDetail.primaryFacility)
        ? this.businessUnitDetail.primaryFacility.id
        : null,
        five9EmailStartDate: this.formatDate(this.businessUnitDetail.five9EmailStartDate),
        five9EmailEndDate: this.formatDate(this.businessUnitDetail.five9EmailEndDate),
        five9DistributionEmails: this.businessUnitDetail.five9DistributionEmails,
        newRequestDistributionEmails: this.businessUnitDetail.newRequestDistributionEmails,
        isAllowedNewServiceRequest : this.businessUnitDetail.isAllowedNewServiceRequest,
        isAllowedAddServiceRequest : this.businessUnitDetail.isAllowedAddServiceRequest,
        isAllowedGetQuoteRequest : this.businessUnitDetail.isAllowedGetQuoteRequest,
        isAllowedPickupRequest : this.businessUnitDetail.isAllowedPickupRequest,
        isAllowedContainerRequest : this.businessUnitDetail.isAllowedContainerRequest,
        isAllowedBillingRequest : this.businessUnitDetail.isAllowedBillingRequest,
        isAllowedContactMyAccountRequest : this.businessUnitDetail.isAllowedContactMyAccountRequest,
        isAllowedNewServiceNoPaymentRequest : this.businessUnitDetail.isAllowedNewServiceNoPaymentRequest,
    });
    this.businessUnitGroup.markAsUntouched();
  }

  private formatDate(input: Date) {
    if(input == null) return null;
    let dateInLocal = new Date(input);
    let d = new Date(dateInLocal.getUTCFullYear(), dateInLocal.getUTCMonth(), dateInLocal.getUTCDate());
    let datestring = d.getFullYear().toString().padStart(4, '0') + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    return datestring;
  }

}
