import { Component, Input } from "@angular/core";
@Component({
  selector: "app-my-account-tool-description",
  templateUrl: "./my-account-tool-description-page.component.html",
  styleUrls: ["./my-account-tool-description-page.component.scss"],
})
export class MyAccountToolDescriptionPageComponent  {
  constructor() { }

  @Input() toolDescription: any;
  showDescription: boolean = false;

  onShowDescriptionClicked()
  {
    this.showDescription = !this.showDescription;
  }
}
