import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { Ecommerce_FeeClient, Ecommerce_FeeDetailsViewModel, Ecommerce_FeeFilterBy, Ecommerce_FeeFilterByFilterBy, Ecommerce_FeeSortBy, Ecommerce_FeeSortBySortBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-fees-lookup-page",
  templateUrl: "./fees-lookup.component.html",
  styleUrls: ["./fees-lookup.component.scss"],
})
export class FeesLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();


  pageIndex: number;
  pageSize: number;
  sortBy: Ecommerce_FeeSortBySortBy[] = [];
  filterBy: Ecommerce_FeeFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate : boolean;

  records: Ecommerce_FeeDetailsViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    feeName: new FormControl(null),
    towerServiceCode: new FormControl(null),
  });

  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_FeeClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToCreateEcommerceFees;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadFees();
  }

  loadFees()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.feeClient.feeListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new Ecommerce_FeeSortBySortBy({
        property: Ecommerce_FeeSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new Ecommerce_FeeFilterByFilterBy({
          property: Ecommerce_FeeFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.feeName
    ) {
      this.filterBy.push(
        new Ecommerce_FeeFilterByFilterBy({
          property: Ecommerce_FeeFilterBy.FeeName,
          contains: this.filterFormGroup.value.feeName
        })
      );
    }

    if (
      this.filterFormGroup.value.towerServiceCode
    ) {
      this.filterBy.push(
        new Ecommerce_FeeFilterByFilterBy({
          property: Ecommerce_FeeFilterBy.TowerServiceCode,
          contains: this.filterFormGroup.value.towerServiceCode
        })
      );
    }

  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadFees();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      billingCycle: null,
    });
    this.loadFees();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadFees();
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}