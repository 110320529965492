import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IIntegrationErrorService,
  IntegrationErrorServiceToken
} from '../error-handling';

import { throwError } from 'rxjs';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

import { catchError } from 'rxjs/operators';
import { IntegrationErrorTableModel } from 'src/app/shared/models/entities';
import { ErrorDetailsComponent } from '../error-details/error-details.component';

@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.scss']
})
export class ErrorHandlingComponent implements OnInit {
  columnDefs = [
    { position: 1, displayLabel: 'ID', propertyName: 'id' },
    { position: 2, displayLabel: 'Status', propertyName: 'status' },
    { position: 3, displayLabel: 'Category', propertyName: 'category' },
    { position: 4, displayLabel: 'Type', propertyName: 'errorType' },
    { position: 5, displayLabel: 'Description', propertyName: 'errorMessage' },
    { position: 6, displayLabel: 'Date', propertyName: 'date' },
    { position: 7, displayLabel: 'Action', propertyName: 'action' }
  ];
  errors = [];

  constructor(
    @Inject(IntegrationErrorServiceToken) private integrationErrorService: IIntegrationErrorService,
    private matDialog: MatDialog,
    private snackbarService: SnackBarService,
    private changeDectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loadTableContent();
  }

  loadTableContent() {
    this.integrationErrorService.search().subscribe(result => {
      result.forEach(error => {
        if (error.errorDescription) {
          error.errorMessage = error.errorMessage + ' : ' + error.errorDescription;
          error.errorDescription = error.errorDescription.substr(0, error.errorDescription.indexOf(':'));
        }
      });
      this.errors = result;
    });
  }

  onOpenDetails(row: IntegrationErrorTableModel) {
    this.integrationErrorService.integrationErrorDetails(row.id).subscribe(error => {
      this.matDialog.open(ErrorDetailsComponent, {
        data: error,
        width: '500px'
      });
    });
  }

  onClickResubmitError(row: IntegrationErrorTableModel) {
    this.integrationErrorService
      .resubmitMessage(row.id)
      .pipe(
        catchError(err => {
          this.snackbarService.ShowError(err);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.snackbarService.ShowSuccess('Successfully resubmitted error ' + row.id);
        this.loadTableContent();
      });
  }
  onClickResolveError(row: IntegrationErrorTableModel) {
    this.integrationErrorService
      .resolveMessage(row.id)
      .pipe(
        catchError(err => {
          this.snackbarService.ShowError(err);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.snackbarService.ShowSuccess('Successfully resolved error ' + row.id);
        this.loadTableContent();
      });
  }
}
