import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MapService } from '../map.service';
import { Basemap } from './basemap.model';

@Component({
  selector: 'map-common-basemap',
  templateUrl: './basemap.component.html',
  styleUrls: ['./basemap.component.scss']
})
export class BasemapComponent implements OnInit {
  @ViewChild('changeMaps', { static: true, read: ElementRef })
  changeMaps: ElementRef;

  @Input()
  esriMap: any;

  @Input()
  initialBasemap: string;

  selectedOption: string;
  showMapOptions: boolean;

  basemaps: Basemap[] = [
    // { key: 'topo', label: 'Topology', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/topo.jpg' },
    // { key: 'streets', label: 'Streets', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/streets.jpg' },
    // { key: 'hybrid', label: 'Hybrid', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/hybrid.jpg' },
    // { key: 'gray', label: 'Gray', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/gray.jpg'},
    // { key: 'dark-gray-vector', label: 'Dark Gray', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/dark-gray.jpg'},
    { key: 'gray-vector', label: '', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/dark-gray.jpg' },
    { key: 'streets-navigation-vector', label: '', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/streets.jpg' },
    { key: 'satellite', label: '', imageUrl: 'https://js.arcgis.com/4.13/esri/images/basemap/satellite.jpg' }
  ];

  constructor(private mapService: MapService) { }

  ngOnInit() {
    this.showMapOptions = false;
    this.setBasemap(this.initialBasemap);
    this.selectedOption = this.initialBasemap;
  }

  setBasemap(key: string) {
    if (this.esriMap) {
      this.esriMap.basemap = key as any;
    }
  }

  onSelectBasemap(map: string) {
    this.mapService.mapViewChange.next(map)
    this.setBasemap(map);
    this.showMapOptions = false;
    this.selectedOption = map;
  }

  closeBaseMap() {
    this.showMapOptions = false;
  }

  displayMapOptions() {
    this.showMapOptions = !this.showMapOptions;
  }
}
