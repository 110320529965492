import esri = __esri;
import { Injectable } from '@angular/core';
import { LatLng } from './geofence/geofence.model';
import { EsriModuleLoader } from './esri-module.loader';
import { EsriTypeFactory } from './esri-type.factory';
import { EsriModuleEnum } from './map.model';

@Injectable()
export class EsriMap {
  map: esri.Map;
  mapView: esri.MapView;
  pin: esri.Graphic;
  graphicsLayer: esri.GraphicsLayer;
  constructor(private esriModuleLoader: EsriModuleLoader, private esriTypeFactory: EsriTypeFactory) { }

  async init(container: HTMLElement, properties: any, basemap: string) {
    await this.esriModuleLoader.loadModules([EsriModuleEnum.Map, EsriModuleEnum.MapView, EsriModuleEnum.GraphicsLayer, EsriModuleEnum.Graphic, EsriModuleEnum.Sketch]);

    // eslint-disable-next-line object-shorthand
    this.map = this.esriTypeFactory.create<esri.Map>(EsriModuleEnum.Map, { basemap: basemap });

    this.mapView = this.esriTypeFactory.create<esri.MapView>(EsriModuleEnum.MapView, Object.assign(properties, { map: this.map, container }));
    this.graphicsLayer = this.esriTypeFactory.create<esri.GraphicsLayer>(EsriModuleEnum.GraphicsLayer);
    this.map.layers.add(this.graphicsLayer);
    if (properties.point) {
      this.setPoint(properties.point as LatLng);
      this.center();
    }

    return this;
  }
  setPoint(point: LatLng) {
    const iconPath = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z';
    const simpleMarkerSymbol = {
      type: 'simple-marker',
      path: iconPath,
      color: 'red',
      size: '30px', // pixels
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: 'red',
        width: 0 // points
      }
    };

    // do something with the result graphic
    const mapPoint = { type: 'point', latitude: point.lat, longitude: point.lng };
    this.pin = this.esriTypeFactory.create<esri.Graphic>(EsriModuleEnum.Graphic, { geometry: mapPoint, symbol: simpleMarkerSymbol });

    this.graphicsLayer.graphics.forEach(t => t.destroy());
    this.graphicsLayer.graphics.removeAll();
    this.graphicsLayer.graphics.add(this.pin);

    return this;
  }
  center() {
  }

  destroy() {
    this.map.destroy();
    this.mapView.destroy();
  }
}
