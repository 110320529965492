import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SuccessSnackBarComponent } from './success-snack-bar/success-snack-bar.component';
import { ValidationSnackBarComponent } from './validation-snack-bar/validation-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  /* This method is used for showing error */
  ShowError(error: string): void {
    const config = new MatSnackBarConfig();

    config.duration = 20000;

    config.verticalPosition = 'top';

    config.horizontalPosition = 'center';

    config.panelClass = ['error-snackbar'];
    config.data = { type: 1, message: error };
    this.snackBar.openFromComponent(ValidationSnackBarComponent, config);
  }

  /* This method is used for showing warning */
  ShowWarning(warning: string): void {
    const config = new MatSnackBarConfig();

    config.duration = 4000;

    config.verticalPosition = 'top';

    config.horizontalPosition = 'center';

    config.panelClass = ['warning-snackbar'];
    config.data = { type: 2, message: warning };
    this.snackBar.openFromComponent(ValidationSnackBarComponent, config);
  }

  /* This method is used for showing success */
  ShowSuccess(message: string): void {
    const config = new MatSnackBarConfig();

    config.duration = 20000;

    config.verticalPosition = 'top';

    config.horizontalPosition = 'center';

    config.panelClass = ['success-snackbar'];
    config.data = message;
    this.snackBar.openFromComponent(SuccessSnackBarComponent, config);
    
  }

  Close():void{this.snackBar.dismiss();}

  

}
