import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { CreateAreaCommand, CreateBillingCycleCommand, CreateServiceTypeCommand, Ecommerce_ServiceTypeClient, SR_BillingCycleClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-service-type-create-page",
  templateUrl: "./service-type-create-page.component.html",
  styleUrls: ["./service-type-create-page.component.scss"],
})
export class ServiceTypeCreatePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    serviceName: new FormControl(""),
    lOB: new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_ServiceTypeClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/service-types");
  }

  create()
  {
    this.isLoading = true;
    const fee = new CreateServiceTypeCommand({
    serviceName: this.formGroup.value.serviceName,
    lob: this.formGroup.value.lOB,
    });
    this.subscription.add(
        this.feeClient.createServiceType(
            fee
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.serviceName + " has been created successfully !");
                this.router.navigateByUrl("/ecommerce/service-types");
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Create Service Type Error: " + error);
        }
        )
    );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}