import { Location } from "@angular/common";
import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {
  BusinessUnitClient,
  BusinessUnitSearchQuery,
  BusinessUnitViewModel,
  EditFacilityCommand,
  FacilityClient,
  FacilityHolidayViewModel,
  FacilityLocationTypeViewModel,
  FacilityViewModel,
  HolidayClient,
  HolidayViewModel,
  LocationTypeViewModel,
} from "src/app/apis/customer-care-api.generated";
import { DemographicInformationService } from "src/app/shared/demographic-information.service";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { state } from "src/app/shared/demographic-information.service";

@Component({
  selector: "app-facility-detail",
  templateUrl: "./facility-detail.component.html",
  styleUrls: ["./facility-detail.component.scss"],
})
export class FacilityDetailComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  isLoading: boolean = false;
  facilityDetail: FacilityViewModel = null;
  facilityGroup: FormGroup;
  businessUnits: BusinessUnitViewModel[];
  totalLocationTypes: FacilityLocationTypeViewModel[];
  checkedLocationTypes: FacilityLocationTypeViewModel[];
  locationTypeValues: boolean[] = [];
  filledValues: boolean = false;
  isPageEditable: boolean = false;
  yesNoList: any = [
    { backValue: true, frontValue: "Yes" },
    { backValue: false, frontValue: "No" },
  ];
  daysOfWeek: any[] = [
    { dayName: "monday", day: 1 },
    { dayName: "tuesday", day: 2 },
    { dayName: "wednesday", day: 3 },
    { dayName: "thursday", day: 4 },
    { dayName: "friday", day: 5 },
    { dayName: "saturday", day: 6 },
    { dayName: "sunday", day: 7 },
  ];
  holidayList: HolidayViewModel[];

  private facilityId: string;
  private id: number;
  private subscription: Subscription = new Subscription();

  constructor(
    public location: Location,
    public demographic: DemographicInformationService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private businessUnitClient: BusinessUnitClient,
    private facilityClient: FacilityClient,
    private holidayClient: HolidayClient,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params["id"];
    this.facilityId = this.activatedRoute.snapshot.params["facilityId"];

    this.facilityGroup = this.fb.group({
      id: new FormControl(this.id),
      uniqueId: new FormControl(this.facilityId, Validators.required),
      name: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      state: new FormControl("", Validators.required),
      zip: new FormControl("", Validators.required),
      country: new FormControl("", Validators.required),
      generalManager: new FormControl("", Validators.required),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern(
          new RegExp(
            /(\d{3}\.\d{3}\.\d{4},\sExt\s\d{3,4})|(\d{3}\.\d{3}\.\d{4}$)/i
          )
        ),
      ]),
      fax: new FormControl(
        null,
        Validators.pattern(
          new RegExp(
            /(\d{3}\.\d{3}\.\d{4},\sExt\s\d{3,4})|(\d{3}\.\d{3}\.\d{4}$)/i
          )
        )
      ),
      lng: new FormControl("", Validators.required),
      lat: new FormControl("", Validators.required),
      pageUrlDomain: new FormControl(),
      pageUrlPath: new FormControl(
        null,
        Validators.pattern(new RegExp(/^([^\s]*)\/$/))
      ),
      facilityLocationTypes: new FormControl(""),
      isBranchLocation: new FormControl("", Validators.required),
      isDropOffLocation: new FormControl("", Validators.required),
      businessUnitId: new FormControl("", Validators.required),
      hoursOfOperations: new FormArray([]),
      facilityHolidays: new FormArray([]),
      isActive: new FormControl('', Validators.required)
    });

    this.createWeeklyHoursOfOperation();
    this.loadRequest();
  }

  ngAfterContentChecked() {
    this.daysOfWeek = [
      { dayName: this.translate.instant("monday"), day: 1 },
      { dayName: this.translate.instant("tuesday"), day: 2 },
      { dayName: this.translate.instant("wednesday"), day: 3 },
      { dayName: this.translate.instant("thursday"), day: 4 },
      { dayName: this.translate.instant("friday"), day: 5 },
      { dayName: this.translate.instant("saturday"), day: 6 },
      { dayName: this.translate.instant("sunday"), day: 7 },
    ];
  }

  get states() {
    if (this.facilityGroup.value.country) {
      return this.demographic.statesForCountry(
        this.facilityGroup.value.country
      );
    } else {
      return new Array<state>();
    }
  }

  onEditClicked() {
    if (this.isPageEditable) {
      this.fillFacilityGroup();
    }
    this.isPageEditable = !this.isPageEditable;
  }

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;
  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  checkValidation(dayOfWeek: number, isChecked: boolean) {
    var form = (this.facilityGroup.get("hoursOfOperations") as FormArray).at(
      dayOfWeek
    ) as FormGroup;
    if (isChecked) {
      var dbVal = this.facilityDetail
        ? this.facilityDetail.hoursOfOperations[dayOfWeek]
        : null;
      form.controls["openTime"].clearValidators();
      form.controls["openTime"].patchValue(dbVal ? dbVal.openTime : null);
      form.controls["openTime"].updateValueAndValidity();
      form.controls["closeTime"].clearValidators();
      form.controls["closeTime"].patchValue(dbVal ? dbVal.closeTime : null);
      form.controls["closeTime"].updateValueAndValidity();
    } else {
      form.controls["openTime"].clearValidators();
      form.controls["openTime"].patchValue(null);
      form.controls["openTime"].updateValueAndValidity();
      form.controls["closeTime"].clearValidators();
      form.controls["closeTime"].patchValue(null);
      form.controls["closeTime"].updateValueAndValidity();
    }
  }

  get isMondayActiveAndTimesFilled() {
    var mondayHours = (
      this.facilityGroup.get("hoursOfOperations") as FormArray
    ).at(0);
    if (mondayHours.get("isOpen").value) {
      if (
        mondayHours.get("openTime").valid &&
        mondayHours.get("openTime").value &&
        mondayHours.get("closeTime").valid &&
        mondayHours.get("closeTime").value
      )
        return true;
    }
    return false;
  }

  copyMondaytoActiveDays() {
    var weekDays = this.facilityGroup.get("hoursOfOperations") as FormArray;
    var mondayHours = weekDays.at(0);
    for (let day = 1; day < weekDays.length; day++) {
      const weekDay = weekDays.at(day);
      if (weekDay.get("isOpen").value) {
        weekDay.patchValue({
          openTime: mondayHours.value.openTime,
          closeTime: mondayHours.value.closeTime,
        });
      }
    }
  }

  saveLocationTypes() {
    var result: FacilityLocationTypeViewModel[] = [];
    var loopTill: number = this.checkedLocationTypes
      ? this.checkedLocationTypes.length
      : 0;
    for (let i = 0; i < loopTill; i++) {
      result.push(this.checkedLocationTypes[i]);
    }
    this.facilityGroup.value.facilityLocationTypes = result;
  }

  private getLocationTypeValue() {
    if (!this.filledValues && this.totalLocationTypes) {
      this.locationTypeValues = [];
      for (let x = 0; x < this.totalLocationTypes.length; x++) {
        var isChecked = false;
        var loopTill: number = this.checkedLocationTypes
          ? this.checkedLocationTypes.length
          : 0;
        for (let i = 0; i < loopTill; i++) {
          if (
            this.totalLocationTypes[x].id == this.checkedLocationTypes[i].id
          ) {
            this.locationTypeValues.push(true);
            isChecked = true;
          }
        }
        if (!isChecked) {
          this.locationTypeValues.push(false);
        }
      }
      this.locationTypeValues.reverse;
      this.filledValues = true;
    }
  }

  onLocationTypeClicked(i: LocationTypeViewModel, index: number) {
    var hasMatch = false;
    var loopTill: number = this.checkedLocationTypes
      ? this.checkedLocationTypes.length
      : 0;
    for (let x = 0; x < loopTill; x++) {
      if (!hasMatch) {
        if (this.checkedLocationTypes[x].id == i.id) {
          this.checkedLocationTypes.splice(x, 1);
          this.locationTypeValues[index] = false;
          hasMatch = true;
        }
      }
    }
    if (!hasMatch) {
      this.locationTypeValues[index] = true;
      this.checkedLocationTypes.push(i);
    }
  }

  onEditConfirmed() {
    this.saveLocationTypes();
    if (!this.facilityGroup.valid) {
      this.facilityGroup.markAsDirty();
      this.facilityGroup.markAllAsTouched();
      return false;
    }

    this.isLoading = true;

    var editCommand = new EditFacilityCommand();

    this.subscription.add(
      this.facilityClient
        .editFacility(
          this.facilityGroup.value.uniqueId,
          this.facilityGroup.value as EditFacilityCommand
        )
        .subscribe(
          () => {
            this.isPageEditable = false;
            this.snackBarService.ShowSuccess("Facility sucessfully edited");
            this.isLoading = false;
          },
          (error) => {
            try {
              console.error(error);
              this.snackBarService.ShowError(error);
            } catch {}
            this.isLoading = false;
          }
        )
    );
  }

  onDeleteClicked() {
    this.isLoading = true;
    this.subscription.add(
      this.facilityClient
        .deleteFacility(this.facilityGroup.value.uniqueId)
        .subscribe(
          () => {
            this.isPageEditable = false;
            this.snackBarService.ShowSuccess(
              "Business unit sucessfully deleted"
            );
            this.location.back();
          },
          (error) => {
            try {
              console.error(error);
              this.snackBarService.ShowError(error);
            } catch {}
            this.isLoading = false;
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addHoliday() {
    if (!this.isPageEditable) {
      return false;
    }
    (this.facilityGroup.get("facilityHolidays") as FormArray).push(
      this.createHoliday(null)
    );
  }

  removeHoliday(holiday: number) {
    if (!this.isPageEditable) {
      return false;
    }
    (this.facilityGroup.get("facilityHolidays") as FormArray).removeAt(holiday);
  }

  private createDay(day: any): FormGroup {
    return this.fb.group({
      day: new FormControl(day.day),
      dayName: new FormControl(day.dayName),
      isOpen: new FormControl(false, Validators.required),
      openTime: new FormControl(null),
      closeTime: new FormControl(null),
    });
  }

  private loadRequest() {
    this.isLoading = true;
    this.subscription.add(
      this.businessUnitClient
        .searchBusinessUnit(new BusinessUnitSearchQuery())
        .subscribe((result) => {
          this.businessUnits = result.records.sort((b1, b2) =>
            b1.name > b2.name ? 1 : -1
          );
          this.isLoading = false;
        })
    );
    this.isLoading = true;
    this.subscription.add(
      this.facilityClient.facilityDetails(this.facilityId).subscribe(
        (result) => {
          this.facilityDetail = result.result;

          this.fillFacilityGroup();
          this.checkedLocationTypes = result.result.facilityLocationTypes;
          this.facilityClient.locationTypes().subscribe((response) => {
            this.totalLocationTypes = response.result;
            this.getLocationTypeValue();
          });
          this.isLoading = false;
        },
        (error) => {
          try {
            console.error(error);
            this.snackBarService.ShowError(error);
          } catch {}
          this.isLoading = false;
        }
      )
    );
    this.isLoading = true;
    this.subscription.add(
      this.holidayClient.allHolidayList().subscribe(
        (result) => {
          this.holidayList = result.records;
          this.isLoading = false;
        },
        (error) => {
          try {
            console.error(error);
            this.snackBarService.ShowError(error);
          } catch {}
          this.isLoading = false;
        }
      )
    );
  }

  private createWeeklyHoursOfOperation(): void {
    this.daysOfWeek.forEach((day) => {
      (this.facilityGroup.get("hoursOfOperations") as FormArray).push(
        this.createDay(day)
      );
    });
  }

  private fillFacilityGroup() {
    this.facilityGroup.patchValue({
      name: this.facilityDetail.name,
      address: this.facilityDetail.address,
      city: this.facilityDetail.city,
      state: this.facilityDetail.state,
      zip: this.facilityDetail.zip,
      country: this.facilityDetail.country,
      generalManager: this.facilityDetail.generalManager,
      phone: this.facilityDetail.phone,
      fax: this.facilityDetail.fax,
      lng: this.facilityDetail.lng,
      lat: this.facilityDetail.lat,
      pageUrlDomain: this.facilityDetail.pageUrlDomain,
      pageUrlPath: this.facilityDetail.pageUrlPath,
      isBranchLocation: this.facilityDetail.isBranchLocation,
      isDropOffLocation: this.facilityDetail.isDropOffLocation,
      businessUnitId: this.facilityDetail.businessUnitId,
      isActive: this.facilityDetail.isActive,
    });

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[0]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(0) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[0].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[0].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[0].closeTime,
      });
    }

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[1]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(1) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[1].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[1].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[1].closeTime,
      });
    }

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[2]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(2) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[2].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[2].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[2].closeTime,
      });
    }

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[3]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(3) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[3].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[3].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[3].closeTime,
      });
    }

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[4]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(4) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[4].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[4].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[4].closeTime,
      });
    }

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[5]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(5) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[5].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[5].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[5].closeTime,
      });
    }

    if (
      this.facilityDetail.hoursOfOperations &&
      this.facilityDetail.hoursOfOperations[6]
    ) {
      var formGroup = (
        this.facilityGroup.get("hoursOfOperations") as FormArray
      ).at(6) as FormGroup;
      formGroup.patchValue({
        isOpen: this.facilityDetail.hoursOfOperations[6].isOpen,
        openTime: this.facilityDetail.hoursOfOperations[6].openTime,
        closeTime: this.facilityDetail.hoursOfOperations[6].closeTime,
      });
    }

    (this.facilityGroup.get("facilityHolidays") as FormArray).clear();
    this.facilityDetail.facilityHolidays.forEach((fcHoliday) => {
      (this.facilityGroup.get("facilityHolidays") as FormArray).push(
        this.createHoliday(fcHoliday)
      );
    });

    this.facilityGroup.markAsUntouched();
  }

  private createHoliday(value?: FacilityHolidayViewModel | null): FormGroup {
    if (value) {
      return this.fb.group({
        holidayId: new FormControl(value.holidayId, Validators.required),
        isOfficeOpen: new FormControl(value.isOfficeOpen, Validators.required),
        officeNote: new FormControl(value.officeNote),
        isCollectionOpen: new FormControl(
          value.isCollectionOpen,
          Validators.required
        ),
        collectionNote: new FormControl(value.collectionNote),
      });
    } else {
      return this.fb.group({
        holidayId: new FormControl(null, Validators.required),
        isOfficeOpen: new FormControl(false, Validators.required),
        officeNote: new FormControl(),
        isCollectionOpen: new FormControl(false, Validators.required),
        collectionNote: new FormControl(),
      });
    }
  }
}
