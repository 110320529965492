import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-current-user-error-page",
  templateUrl: "./current-user-error-page.component.html",
  styleUrls: ["./current-user-error-page.component.scss"],
})

export class CurrentUserErrorPageComponent implements OnInit, OnDestroy {
  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
