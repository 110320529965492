import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmUnlinkAccountDialogComponent {
  accountNumber: string;
  newOwner: string;
}

@Component({
  selector: 'app-confirm-unlink-account-dialog',
  templateUrl: './confirm-unlink-account-dialog.component.html',
  styleUrls: ['./confirm-unlink-account-dialog.component.css']
})
export class ConfirmUnlinkAccountDialogComponent implements OnInit {

  isLoading: boolean = false;
  
  constructor(private dialog: MatDialogRef<ConfirmUnlinkAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public request: IConfirmUnlinkAccountDialogComponent) { }

  ngOnInit() {
  }

  confirmCancel() {
    this.dialog.close(false);
  }

  confirmSubmit() {
    this.dialog.close(true);
  }

}
