import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { CreateAreaCommand, CreateBillingCycleCommand, CreatePromoCodeCommand, Ecommerce_PromoCodeClient, Ecommerce_ServiceTypeDetailsViewModel, LookupClient, PromoCodeServiceViewModel, SR_BillingCycleClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-promo-code-create-page",
  templateUrl: "./promo-code-create-page.component.html",
  styleUrls: ["./promo-code-create-page.component.scss"],
})
export class PromoCodeCreatePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;
  
  allServiceTypes : Ecommerce_ServiceTypeDetailsViewModel[];

  formGroup = new FormGroup({
    id: new FormControl(0),
    uniqueId: new FormControl(""),
    code: new FormControl(""),
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    durationType: new FormControl(""),
    durationValue: new FormControl(0),
    durationUnit: new FormControl(""),
    discountType: new FormControl(""),
    discountValue: new FormControl(0),
    description: new FormControl(""),
    automatic: new FormControl(false),
    serviceTypes: new FormArray([]),
  });

  constructor(
    private snackBarService: SnackBarService,
    private feeClient: Ecommerce_PromoCodeClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lookupClient: LookupClient
  ) { }

  ngOnInit() {
    this.loadAllServiceTypes();
  }

  loadAllServiceTypes()
  {
    this.lookupClient.getAllServiceTypes().subscribe(
      (response) => {
        this.allServiceTypes = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
  }

  addServiceType()
  {
    const pGroupControl = new FormGroup({
      id: new FormControl(0),
      serviceName: new FormControl(null),
    });
    this.serviceTypes.insert(0, pGroupControl);
  }

  get serviceTypes() {
    return this.formGroup.get("serviceTypes") as FormArray;
  }

  removeServiceType(x)
  {
    this.serviceTypes.removeAt(x);
  }

  getServiceTypeId (p: any)
  {
    return p.id;
  }

  getServiceTypeName (p: any) {
    return p.serviceName;
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/promo-codes");
  }

  create()
  {
    this.isLoading = true;
    const fee = new CreatePromoCodeCommand({
    code: this.formGroup.value.code,
    startDate: this.formGroup.value.startDate,
    endDate: this.formGroup.value.endDate,
    durationType: this.formGroup.value.durationType,
    durationValue: this.formGroup.value.durationValue,
    durationUnit: this.formGroup.value.durationUnit,
    discountType: this.formGroup.value.discountType,
    discountValue: this.formGroup.value.discountValue,
    description: this.formGroup.value.description,
    automatic: this.formGroup.value.automatic,
    serviceTypes : this.serviceTypes.controls.map(
      (x) => new PromoCodeServiceViewModel({
        promoCodeId : 0,
        serviceTypeId: x.value.id
      })
    )
    });
    this.subscription.add(
        this.feeClient.createPromoCode(
            fee
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.code + " has been created successfully !");
                this.router.navigateByUrl("/ecommerce/promo-codes");
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Create Promo Code Error: " + error);
        }
        )
    );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}