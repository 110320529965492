import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { Ecommerce_ServiceCodeSortBySortBy, Ecommerce_ServiceCodeFilterByFilterBy, Ecommerce_ServiceCodeDetailsViewModel, Ecommerce_ServiceCodeClient, Ecommerce_ServiceCodeFilterBy, Ecommerce_ServiceCodeSortBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-service-codes-lookup-page",
  templateUrl: "./service-codes-lookup.component.html",
  styleUrls: ["./service-codes-lookup.component.scss"],
})
export class ServiceCodesLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();


  pageIndex: number;
  pageSize: number;
  sortBy: Ecommerce_ServiceCodeSortBySortBy[] = [];
  filterBy: Ecommerce_ServiceCodeFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate : boolean;

  records: Ecommerce_ServiceCodeDetailsViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    serviceCode: new FormControl(null),
  });

  constructor(
    private snackBarService: SnackBarService,
    private serviceCodeClient: Ecommerce_ServiceCodeClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToDeleteServiceCodes;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadServiceCodes();
  }

  loadServiceCodes()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.serviceCodeClient.serviceCodeListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new Ecommerce_ServiceCodeSortBySortBy({
        property: Ecommerce_ServiceCodeSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new Ecommerce_ServiceCodeFilterByFilterBy({
          property: Ecommerce_ServiceCodeFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.serviceCode
    ) {
      this.filterBy.push(
        new Ecommerce_ServiceCodeFilterByFilterBy({
          property: Ecommerce_ServiceCodeFilterBy.ServiceCode,
          contains: this.filterFormGroup.value.serviceCode
        })
      );
    }

  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadServiceCodes();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      serviceCode: null,
    });
    this.loadServiceCodes();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadServiceCodes();
  }




  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}