import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { BusinessUnitViewModel, CreateAreaCommand, CreateBillingCycleCommand, CreateBlacklistCommand, Ecommerce_BlacklistClient, LookupClient, SR_BillingCycleClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-blacklist-create-page",
  templateUrl: "./blacklist-create-page.component.html",
  styleUrls: ["./blacklist-create-page.component.scss"],
})
export class BlacklistCreatePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isLoading : boolean;
  isBusinessUnitsLoading: boolean;

  businessUnits: BusinessUnitViewModel[];

  businessUnitFormControl = new FormControl();

  formGroup = new FormGroup({
    id: new FormControl(null),
    businessUnitId : new FormControl(1),
    address: new FormControl(""),
    city: new FormControl(""),
    state: new FormControl(""),
    postalCode: new FormControl(""),
    note: new FormControl(""),
  });

  constructor(
    private snackBarService: SnackBarService,
    private blacklistClient: Ecommerce_BlacklistClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lookupClient: LookupClient,
  ) { }

  ngOnInit() {
    this.getBusinessUnits();
  }

  goBack() {
    this.router.navigateByUrl("/ecommerce/blacklist");
  }

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.lookupClient.getAllBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result;
          this.isBusinessUnitsLoading = false;
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            this.snackBarService.ShowError(
            "Fetching business units error: " + err);
          }
        }
      )
    );
  }

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  create()
  {
    this.isLoading = true;
    const blacklist = new CreateBlacklistCommand({
    businessUnitId : this.businessUnitFormControl.value,
    address: this.formGroup.value.address,
    city: this.formGroup.value.city,
    state: this.formGroup.value.state,
    postalCode: this.formGroup.value.postalCode,
    note: this.formGroup.value.note,
    });
    this.subscription.add(
        this.blacklistClient.createBlacklist(
            blacklist
        ).subscribe((result) => {
            this.isLoading = false;
            if(result.isSuccess)
            {
                this.snackBarService.ShowSuccess(this.formGroup.value.address + " has been created successfully !");
                this.router.navigateByUrl("/ecommerce/blacklist");
            }
        },
        (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError("Create Blacklist Error: " + error);
        }
        )
    );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}