import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { stringify } from 'query-string';
import { Subscription } from 'rxjs';
import { BusinessUnitClient, BusinessUnitSearchQuery, BusinessUnitSearchViewModel, FacilityClient, FacilityFilterBy, FacilityFilterByFilterBy, FacilitySearchQuery, FacilitySortBy, FacilitySortBySortBy, FacilityViewModel } from 'src/app/apis/customer-care-api.generated';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import * as queryString from "query-string";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/auth/auth.service';

export interface IFacilitySearchFilters {
  businessUnitIds: string;
  name: string;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-facility-lookup',
  templateUrl: './facility-lookup.component.html',
  styleUrls: ['./facility-lookup.component.scss']
})
export class FacilityLookupComponent implements OnInit, OnDestroy {

  private pageIndex: number = 1;
  private pageSize: number = 10;
  private filterBy: FacilityFilterByFilterBy[] = [];
  private sortBy: FacilitySortBySortBy[] = [];
  private isInitialized: boolean;
  private subscription: Subscription = new Subscription();

  facilitySearchGroup: FormGroup;
  isLoading: boolean = false;
  facilities: FacilityViewModel[] | null | undefined;
  isAllowedToCreateFacility: boolean = false;
  businessUnits: BusinessUnitSearchViewModel[] = [];
  totalRequests: number;

  constructor(private router: Router,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private businessUnitClient: BusinessUnitClient,
    private translateService : TranslateService,
    private authService: AuthService,
    private facilityClient: FacilityClient) {
    this.facilitySearchGroup = this.fb.group({
      businessUnitIds: new FormControl(),
      name: new FormControl(),
    });

    this.setDefaultSort();

    this.initializeFromQueryString();
  }

  ngOnInit() {
    this.onSearchClick();
    this.isLoading = true;
    this.subscription
    .add(
      this.businessUnitClient.searchBusinessUnit(new BusinessUnitSearchQuery())
        .subscribe(result => {
          this.businessUnits = result.records;
          this.isLoading = false;
        })
    );

    this.subscription
        .add(
          this.authService.currentUser
            .subscribe(val => {
              if(val) {
                this.isAllowedToCreateFacility = val.isAllowedToCreateFacility
                if(!val.isAllowedToViewFacilities)
              {
                this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
              }
              }
            })
        )
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }

      this.pageSize = e.pageSize;
      this.createQueryStringFilters();
    }
  }

  onSearchClick() {
    this.pageIndex = 1;

    this.createQueryStringFilters();
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;

    this.facilitySearchGroup.reset();

    this.createQueryStringFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getYesNo(isTrue: boolean): string {
    return isTrue 
      ? this.translateService.instant('yes')
      : this.translateService.instant('no');
  }

  private loadRequests() {
    this.isLoading = true;
    var searchQuery = new FacilitySearchQuery();
    searchQuery.filterBy = this.filterBy.length == 0 ? undefined : this.filterBy;
    searchQuery.page = this.pageIndex;
    searchQuery.pageSize = this.pageSize;
    searchQuery.sortBy = this.sortBy;

    this.subscription
      .add(
        this.facilityClient.searchFacility(searchQuery)
          .subscribe(result => {
            this.facilities = result.records;
            this.totalRequests = result.totals[0].value;

            setTimeout(() => {
              this.isInitialized = true;
            }, 2000);
            this.isLoading = false;
          },
            (error) => {
              try {
                console.error(error);
                this.snackBarService.ShowError(error);
               }
               catch { }
               this.isLoading = false;
            })
      );
  }

  private filterRequests() {
    this.filterBy = [];

    /*---------------------- FILTER -------------------*/
    if (this.facilitySearchGroup.value.name) {

      const filterByCode = new FacilityFilterByFilterBy({
        property: FacilityFilterBy.Name,
        contains: this.facilitySearchGroup.value.name,
      });

      this.filterBy.push(filterByCode);
    }

    if (this.facilitySearchGroup.value.businessUnitIds) {
      const filterByName = new FacilityFilterByFilterBy({
        property: FacilityFilterBy.BusinessUnitId,
        contains: this.facilitySearchGroup.value.businessUnitIds,
      });

      this.filterBy.push(filterByName);
    }

    this.loadRequests();
  }

  private createQueryStringFilters() {
    let requestFilterQueryString: any = {};

    /*---------------------- NAME FILTER -------------------*/
    if (this.facilitySearchGroup.value.name) {
      requestFilterQueryString.code = this.facilitySearchGroup.value.name;
    }

    /*---------------------- BUSINESS UNITS FILTER -------------------*/
    if (this.facilitySearchGroup.value.businessUnitIds) {
      requestFilterQueryString.name = this.facilitySearchGroup.value.businessUnitIds;
    }

    /*----------------------------------- PAGE NUMBER ------------------------------------------*/
    requestFilterQueryString.pageIndex = this.pageIndex;

    /*----------------------------------- PAGE SIZE --------------------------------------------*/
    requestFilterQueryString.pageSize = this.pageSize;

    const stringifiedAgreementQueryString = stringify(
      requestFilterQueryString,
      { arrayFormat: "comma" }
    );

    this.router.navigateByUrl(`admin/facility?${stringifiedAgreementQueryString}`);

    this.filterRequests();
  }

  private initializeFromQueryString() {
    const requestParsedFilters: IFacilitySearchFilters = (queryString.parse(
      location.search,
      { arrayFormat: "comma" }
    ) as any) as IFacilitySearchFilters;

    this.facilitySearchGroup.patchValue({
      businessUnitIds: requestParsedFilters.businessUnitIds,
      name: requestParsedFilters.name
    });

    this.pageIndex = requestParsedFilters.pageIndex
      ? requestParsedFilters.pageIndex
      : 1;

    this.pageSize = requestParsedFilters.pageSize
      ? requestParsedFilters.pageSize
      : 10;

    this.filterRequests();
  }

  private setDefaultSort() {
    this.sortBy = [];
    /*-----------DEFAULT SORT BY ------------*/
    const sortByName = new FacilitySortBySortBy({
      property: FacilitySortBy.Id,
      descendingOrder: false,
    });

    this.sortBy.push(sortByName);
    /*-----------DEFAULT SORT BY ------------*/
  }

}
