import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { Ecommerce_AreaSortBySortBy, Ecommerce_AreaFilterByFilterBy, Ecommerce_AreaDetailsViewModel, Ecommerce_AreaClient, Ecommerce_AreaSortBy, Ecommerce_AreaFilterBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-areas-lookup-page",
  templateUrl: "./areas-lookup.component.html",
  styleUrls: ["./areas-lookup.component.scss"],
})
export class AreasLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();


  pageIndex: number;
  pageSize: number;
  sortBy: Ecommerce_AreaSortBySortBy[] = [];
  filterBy: Ecommerce_AreaFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate: boolean;

  records: Ecommerce_AreaDetailsViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    serviceArea: new FormControl(null),
    name: new FormControl(null),
    businessUnitId: new FormControl(null)
  });


  constructor(
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private ecommerce_areClient: Ecommerce_AreaClient
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToCreateEcommerceAreas;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadAreas();
  }

  loadAreas()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.ecommerce_areClient.areaListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new Ecommerce_AreaSortBySortBy({
        property: Ecommerce_AreaSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new Ecommerce_AreaFilterByFilterBy({
          property: Ecommerce_AreaFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.serviceArea
    ) {
      this.filterBy.push(
        new Ecommerce_AreaFilterByFilterBy({
          property: Ecommerce_AreaFilterBy.ServiceArea,
          contains: this.filterFormGroup.value.serviceArea
        })
      );
    }

    if (
      this.filterFormGroup.value.name
    ) {
      this.filterBy.push(
        new Ecommerce_AreaFilterByFilterBy({
          property: Ecommerce_AreaFilterBy.Name,
          contains: this.filterFormGroup.value.name
        })
      );
    }
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadAreas();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      name: null,
      serviceArea: null,
      businessUnitId: null,
    });
    this.loadAreas();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadAreas();
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}