import { Component, Input, OnInit } from "@angular/core";
import { saveAs } from 'file-saver';
import { Subscription } from "rxjs";
import {
  MyAccountAccountLookupViewModel,
  MyAccountAccountsClient,
  MyAccountInvoicesViewModel,
} from "src/app/apis/customer-care-api.generated";
import { AuthService } from "src/app/shared/auth/auth.service";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";

@Component({
  selector: "app-my-account-account-details",
  templateUrl: "./my-account-account-details.component.html",
  styleUrls: ["./my-account-account-details.component.scss"],
})
export class MyAccountAccountDetailsComponent implements OnInit {

  @Input() account: MyAccountAccountLookupViewModel;
  @Input() fromCustomerFinder = false;

  userEmails: string = "";
  userPhoneNumbers: string = "";

  private subscription: Subscription = new Subscription();
  isAllowedToCreateCheckoutLink = false;

  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    
  ) { }

  get billTo() {
    return this.account.billToEnum ? (this.account.billToEnum + " (" + this.account.billTo + ")") : this.account.billTo;
  }

  invoiceLoading = false;

  displayedUsersColumns: string[] = [
    "name",
    "emailAddress",
    "phoneNumber",
    "createdDate",
    "isOwner",
    "idp",
    "isApprovedUser",
  ];

  displayedUsersFromSiteColumns: string[] = [
    "name",
    "emailAddress",
    "phoneNumber",
    "createdDate",
    "idp",
  ];

  displayedInvoicesColumns: string[] = [
    "download",
    "invoiceNumber",
    "siteId",
    "invoiceDate",
    "originalAmount",
    "dueDate",
    "balance",
  ];

  displayedServicesColumns: string[] = [
    "serviceId",
    "containerSize",
    "frequency",
    "quantity",
    "serviceTypeDescription",
    "siteId",
    "serviceStatus",
  ];

  displayedSiteColumns: string[] = [
    "siteName",
    "siteNumber",
    "line1",
    "city",
    "province",
    "postalCode",
  ];

  onDownloadPdfClicked(invoice: MyAccountInvoicesViewModel) {
    this.invoiceLoading = true;
    this.myAccountAccountsClient.getInvoicePdf(invoice.invoiceNumber, invoice.siteId ? invoice.siteId : undefined)
      .subscribe(
        (x) => {
          saveAs(x.data, invoice.invoiceNumber)
          this.invoiceLoading = false;
        },
        (error) => {
          this.invoiceLoading = false;
          this.snackBarService.ShowError(
            "Returning PDF error: " + error
          );
        }
      );
  }

  copyEmailToClipboard(email: string) {
    navigator.clipboard.writeText(email)
      .then(success =>
        this.snackBarService.ShowSuccess(`"${email}" is copied to clipboard`)
      );
  }

  ngOnInit() {

    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(user => {
            if(user) {
              this.isAllowedToCreateCheckoutLink = user.isAllowedToUseSecureCheckoutLinks;
            }
          })
      );


    if (this.account) {
      this.userEmails = this.account.usersFromAccount.map((x) => x.emailAddress).join(",") + this.account.usersFromSite.map((x) => x.emailAddress).join(",");
      this.userPhoneNumbers = this.account.usersFromAccount.map((x) => x.phoneNumber).join(",") + this.account.usersFromSite.map((x) => x.phoneNumber).join(",");
    }
   }
}
