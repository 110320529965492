import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'widget-success-snack-bar',
  templateUrl: './success-snack-bar.component.html',
  styleUrls: ['./success-snack-bar.component.scss'],
})
export class SuccessSnackBarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar,
    private snackRef: MatSnackBarRef<SuccessSnackBarComponent>
  ) {}

  ngOnInit() {}

  onCloseSnackBar() {
    this.snackRef.dismiss();
  }
}
