import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { SR_BillingCycleClient, SR_BillingCycleDetailsViewModel, SR_BillingCycleFilterBy, SR_BillingCycleFilterByFilterBy, SR_BillingCycleSortBy, SR_BillingCycleSortBySortBy } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";


@Component({
  selector: "app-ecommerce-billing-cycles-lookup-page",
  templateUrl: "./billing-cycles-lookup.component.html",
  styleUrls: ["./billing-cycles-lookup.component.scss"],
})
export class BillingCyclesLookupPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  pageIndex: number;
  pageSize: number;
  sortBy: SR_BillingCycleSortBySortBy[] = [];
  filterBy: SR_BillingCycleFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  isAllowedToCreate : boolean;

  records: SR_BillingCycleDetailsViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    billingCycle: new FormControl(null)
  });


  constructor(
    private snackBarService: SnackBarService,
    private billingCycleClient: SR_BillingCycleClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.isAllowedToCreate = response.isAllowedToCreateBillingCycles;
      }
    );
    this.pageIndex = 1;
    this.pageSize = 10;
    this.loadBillingCycles();
  }

  loadBillingCycles()
  {
    this.isLoading = true;
    this.updateFilters();
    this.subscription.add(
      this.billingCycleClient.billingCycleListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records  = result.records;
          this.totalRecords = result.totals[0].value;

          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) =>
        {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters()
  {
        // Default Sort By
    this.sortBy = [
      new SR_BillingCycleSortBySortBy({
        property: SR_BillingCycleSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.id &&
      this.filterFormGroup.value.id != 0
    ) {
      this.filterBy.push(
        new SR_BillingCycleFilterByFilterBy({
          property: SR_BillingCycleFilterBy.Id,
          equal: this.filterFormGroup.value.id
        })
      );
    }

    if (
      this.filterFormGroup.value.billingCycle
    ) {
      this.filterBy.push(
        new SR_BillingCycleFilterByFilterBy({
          property: SR_BillingCycleFilterBy.BillingCycle,
          contains: this.filterFormGroup.value.billingCycle
        })
      );
    }

  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadBillingCycles();
    }
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      billingCycle: null,
    });
    this.loadBillingCycles();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadBillingCycles();
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}