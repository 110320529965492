import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {
  CreateMyAccountUserForManagedAutopayCommand,
  MyAccountUsersClient,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
@Component({
  selector: "app-my-account-create-managed-autopay-account",
  templateUrl: "./my-account-create-managed-autopay-account.component.html",
  styleUrls: ["./my-account-create-managed-autopay-account.component.scss"],
})
export class MyAccountCreateManagedAutopayAccountComponent implements OnDestroy {
  constructor(
    private myAccountUsersClient: MyAccountUsersClient,
    private snackBarService: SnackBarService,
    private router: Router, 
    private translateService: TranslateService
  ) { }

  toolDescription = "This tool is meant to be used for customers who call in or otherwise contact us and want to setup autopay but do not want to login to MyAccount themselves. \n This tool will create a MyAccount profile using the entered account number as the email address. \n The format of the email address will be 'accountnumber@gflenv.com' \n EX: A10000123@gflenv.com \n We can then impersonate using the created email, link the customer's account to that profile, and configure autopay for the customer."

  private subscription: Subscription = new Subscription();
  userFormGroup = new FormGroup(
    {
      accountNumber: new FormControl("", [
        Validators.maxLength(20),
        this.checkEmptyString,
      ]),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
    },
  );

  checkEmptyString(formControl: FormControl) {
    const isWhitespace = (formControl.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }


  create() {
    const data = new CreateMyAccountUserForManagedAutopayCommand({
      accountNumber: this.userFormGroup.value.accountNumber.trim(),
      firstName: this.userFormGroup.value.firstName.trim(),
      lastName: this.userFormGroup.value.lastName.trim(),
    });
    this.myAccountUsersClient.createMyAccountUserForManagedAutopay(data).subscribe(
      (x) => {
        this.snackBarService.ShowSuccess(this.translateService.instant('snackbar.account_created_success'))
        this.router.navigateByUrl("/");
      },
      (error) => {
        this.snackBarService.ShowError(
          "Create MyAccount User Error: " + error
        );
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
