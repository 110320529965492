import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IValidation } from './validation.model';

@Component({
  selector: 'widget-validation-snack-bar',
  templateUrl: './validation-snack-bar.component.html',
  styleUrls: ['./validation-snack-bar.component.scss'],
})
export class ValidationSnackBarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IValidation,
    private snackRef: MatSnackBarRef<ValidationSnackBarComponent>,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  onCloseSnackBar() {
    this.snackRef.dismiss();
  }
}
