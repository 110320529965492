import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MyAccountAccountsClient, MyAccountPayMyBillDataValidationViewModel } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-my-account-paymybill-datavalidation',
  templateUrl: './my-account-paymybill-datavalidation.component.html',
  styleUrls: ['./my-account-paymybill-datavalidation.component.css']
})
export class MyAccountPayMyBillDataValidationComponent implements OnDestroy {

  private subscription = new Subscription();

  constructor(private snackBarService: SnackBarService,
    private myAccountAccountsClient: MyAccountAccountsClient,
    private dialog: MatDialog,
    private authService: AuthService) { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isLoading = false;

  toolDescription = "This tool is meant to be used when a customer is unable to use the Pay My Bill feature in MyAccount. \n This tool attempts to determine the problem by checking the customer's data. \n If at least one of the states are deemed to be invalid, we can try to follow the solution outlined.\n If all states are valid and the customer is unable to use the feature, please escalate the issue to dev.";
  validationResults: MyAccountPayMyBillDataValidationViewModel[];

  accountNumber = new FormControl("", Validators.minLength(9));

  get isValidAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0 && this.accountNumber.valid;
  }

  displayedAccountColumns: string[] = [
    "currentState",
    "expectedState",
    "isValid",
    "solution",
  ];

  getAccount() {
    this.validationResults = null;
    this.isLoading = true;
    this.myAccountAccountsClient
      .payMyBillDataValidation(this.accountNumber.value)
      .subscribe(
        (x) => {
          this.isLoading = false;
          this.validationResults = x.result;
          window.scrollTo({ top: 80 });
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(
            error
          );
        }
      );
  }
}
