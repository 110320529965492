import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusinessUnitClient, BusinessUnitDetailViewModel, EditBusinessUnitCommand, FacilityClient, FacilitySearchQuery, FacilityViewModel } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from "src/app/shared/auth/auth.service";
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-business-unit-detail',
  templateUrl: './business-unit-detail.component.html',
  styleUrls: ['./business-unit-detail.component.scss']
})
export class BusinessUnitDetailComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  businessUnitDetail: BusinessUnitDetailViewModel = null;
  businessUnitGroup: FormGroup;
  invoicePresentmentGroup: FormGroup;
  facilities: FacilityViewModel[];
  isPageEditable: boolean = false;
  isAllowedToEditInvoice: boolean =false;

  private buId: string;
  private subscription: Subscription = new Subscription();
  
  constructor(
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private businessUnitClient: BusinessUnitClient,
    private facilityClient: FacilityClient,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.buId = this.activatedRoute.snapshot.params["buId"];

    this.businessUnitGroup = this.fb.group({
      uniqueId: new FormControl(this.buId, Validators.required),
      code: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      isActive: new FormControl(null, Validators.required),
      primaryFacilityId: new FormControl(''),
      // email fields
      five9EmailStartDate: new FormControl(null),
      five9EmailEndDate: new FormControl(null),
      five9DistributionEmails: new FormControl(''),
      newRequestDistributionEmails: new FormControl(''),
      // request fields
      isAllowedNewServiceRequest : new FormControl(false),
      isAllowedAddServiceRequest : new FormControl(false),
      isAllowedGetQuoteRequest : new FormControl(false),
      isAllowedPickupRequest : new FormControl(false),
      isAllowedContainerRequest : new FormControl(false),
      isAllowedBillingRequest : new FormControl(false),
      isAllowedContactMyAccountRequest : new FormControl(false),
      isAllowedNewServiceNoPaymentRequest : new FormControl(false),
    });

    this.invoicePresentmentGroup = this.fb.group({
      printAndReturnToGFLMailingAddressName: new FormControl(''),
    printAndReturnToGFLMailingAddress1: new FormControl(''),
    printAndReturnToGFLMailingAddress2: new FormControl(''),
    printAndReturnToGFLMailingAddress3: new FormControl(''),
    printAndReturnToGFLMailingAddress4: new FormControl(''),
    printAndReturnToGFLMailingAddressCity: new FormControl(''),
    printAndReturnToGFLMailingAddressPostal: new FormControl(''),
    printAndReturnToGFLMailingAddressNotes: new FormControl(''),
    undeliverableMailReturnAddressName: new FormControl(''),
    undeliverableMailReturnAddressAddress1: new FormControl(''),
    undeliverableMailReturnAddressAddress2: new FormControl(''),
    undeliverableMailReturnAddressAddress3: new FormControl(''),
    undeliverableMailReturnAddressAddress4: new FormControl(''),
    undeliverableMailReturnAddressAddressCity: new FormControl(''),
    undeliverableMailReturnAddressAddressPostal: new FormControl(''),
    undeliverableMailReturnAddressPhone: new FormControl(''),
    undeliverableMailReturnAddressEmail: new FormControl(''),
    undeliverableMailReturnAddressNotes: new FormControl(''),

    });

    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(u => {
            if(u) {
              this.isAllowedToEditInvoice = u.isAllowedToUpdateBusinessUnitInvoicePresentment;
            }
          })
      );

    this.loadRequest();
  }

  onEditClicked() {
    if(this.isPageEditable) {
      this.fillBusinessUnitGroup();
    }
    this.isPageEditable = !this.isPageEditable;
  }
  
  addFacility() {
    this.router.navigate(["/admin/facility/create", 
    { buId: this.businessUnitDetail.id, buUniqueId: this.businessUnitDetail.uniqueId }]);
  }

  onEditConfirmed() {
    this.businessUnitGroup.markAllAsTouched();
    if(!this.businessUnitGroup.valid) {
      return false;
    }

    this.isLoading = true;

    var command = this.businessUnitGroup.value as EditBusinessUnitCommand;
    command.invoicePresentmentInfo = this.invoicePresentmentGroup.value;

    
    this.subscription
      .add(
        this.businessUnitClient
          .editBusinessUnit(this.businessUnitGroup.value.uniqueId, command)
          .subscribe(() => {
            this.isPageEditable = false;
            this.snackBarService.ShowSuccess("Business unit sucessfully edited");
            this.isLoading = false;
            window.location.reload();
           },
           (error) => {
            try {
              console.error(error);
              this.snackBarService.ShowError(error);
             }
             catch { }
             this.isLoading = false;
           })
      );
      
  }

  onDeleteClicked() {
    this.isLoading = true;
    this.subscription
      .add(
        this.businessUnitClient
          .deleteBusinessUnit(this.businessUnitGroup.value.uniqueId)
          .subscribe(() => {
            this.isPageEditable = false;
            this.snackBarService.ShowSuccess("Business unit sucessfully deleted");
            this.location.back();
            this.isLoading = false;
          },
          (error) => {
            try {
              console.error(error);
              this.snackBarService.ShowError(error);
             }
             catch { }
             this.isLoading = false;
          })
      );
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadRequest() {
    this.isLoading = true;
    this.subscription
    .add(
      this.facilityClient.searchFacility(new FacilitySearchQuery())
        .subscribe(result => {
          this.facilities = result.records;
        })
    );
    this.subscription
      .add(
        this.businessUnitClient.businessUnitDetails(this.buId)
          .subscribe(result => {
            this.businessUnitDetail = result.result;
            
            this.fillBusinessUnitGroup();
            this.isLoading = false;
          },
          (error) => {
            try {
              console.error(error);
              this.snackBarService.ShowError(error);
             }
             catch { }
             this.isLoading = false;
          })
      );
  }


  private fillBusinessUnitGroup() {
    this.businessUnitGroup.patchValue({
      code: this.businessUnitDetail.code,
      name: this.businessUnitDetail.name,
      isActive: this.businessUnitDetail.isActive != null ? this.businessUnitDetail.isActive : true,
      primaryFacilityId: (this.businessUnitDetail.primaryFacility)
        ? this.businessUnitDetail.primaryFacility.id
        : null,
        five9EmailStartDate: this.formatDate(this.businessUnitDetail.five9EmailStartDate),
        five9EmailEndDate: this.formatDate(this.businessUnitDetail.five9EmailEndDate),
        five9DistributionEmails: this.businessUnitDetail.five9DistributionEmails != null ? this.businessUnitDetail.five9DistributionEmails : "",
        newRequestDistributionEmails: this.businessUnitDetail.newRequestDistributionEmails != null ? this.businessUnitDetail.newRequestDistributionEmails : "",
        isAllowedNewServiceRequest : this.businessUnitDetail.isAllowedNewServiceRequest,
        isAllowedAddServiceRequest : this.businessUnitDetail.isAllowedAddServiceRequest,
        isAllowedGetQuoteRequest : this.businessUnitDetail.isAllowedGetQuoteRequest,
        isAllowedPickupRequest : this.businessUnitDetail.isAllowedPickupRequest,
        isAllowedContainerRequest : this.businessUnitDetail.isAllowedContainerRequest,
        isAllowedBillingRequest : this.businessUnitDetail.isAllowedBillingRequest,
        isAllowedContactMyAccountRequest : this.businessUnitDetail.isAllowedContactMyAccountRequest,
        isAllowedNewServiceNoPaymentRequest : this.businessUnitDetail.isAllowedNewServiceNoPaymentRequest,
    });
    this.invoicePresentmentGroup.patchValue({
      printAndReturnToGFLMailingAddressName: this.businessUnitDetail.printAndReturnToGFLMailingAddressName,
        printAndReturnToGFLMailingAddress1: this.businessUnitDetail.printAndReturnToGFLMailingAddress1,
        printAndReturnToGFLMailingAddress2: this.businessUnitDetail.printAndReturnToGFLMailingAddress2,
        printAndReturnToGFLMailingAddress3: this.businessUnitDetail.printAndReturnToGFLMailingAddress3,
        printAndReturnToGFLMailingAddress4: this.businessUnitDetail.printAndReturnToGFLMailingAddress4,
        printAndReturnToGFLMailingAddressCity: this.businessUnitDetail.printAndReturnToGFLMailingAddressCity,
        printAndReturnToGFLMailingAddressPostal: this.businessUnitDetail.printAndReturnToGFLMailingAddressPostal,
        printAndReturnToGFLMailingAddressNotes: this.businessUnitDetail.printAndReturnToGFLMailingAddressNotes,
        undeliverableMailReturnAddressName: this.businessUnitDetail.undeliverableMailReturnAddressName,
        undeliverableMailReturnAddressAddress1: this.businessUnitDetail.undeliverableMailReturnAddressAddress1,
        undeliverableMailReturnAddressAddress2: this.businessUnitDetail.undeliverableMailReturnAddressAddress2,
        undeliverableMailReturnAddressAddress3: this.businessUnitDetail.undeliverableMailReturnAddressAddress3,
        undeliverableMailReturnAddressAddress4: this.businessUnitDetail.undeliverableMailReturnAddressAddress4,
        undeliverableMailReturnAddressAddressCity: this.businessUnitDetail.undeliverableMailReturnAddressAddressCity,
        undeliverableMailReturnAddressAddressPostal: this.businessUnitDetail.undeliverableMailReturnAddressAddressPostal,
        undeliverableMailReturnAddressPhone: this.businessUnitDetail.undeliverableMailReturnAddressPhone,
        undeliverableMailReturnAddressEmail: this.businessUnitDetail.undeliverableMailReturnAddressEmail,
        undeliverableMailReturnAddressNotes: this.businessUnitDetail.undeliverableMailReturnAddressNotes,
    });
    this.businessUnitGroup.markAsUntouched();
  }

  private formatDate(input: Date) {
    if(input == null) return null;
    let dateInLocal = new Date(input);
    let d = new Date(dateInLocal.getUTCFullYear(), dateInLocal.getUTCMonth(), dateInLocal.getUTCDate());
    let datestring = d.getFullYear().toString().padStart(4, '0') + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    return datestring;
  }
}
