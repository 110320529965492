import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallIdService {
  private callIdSubject = new BehaviorSubject<string | null>(sessionStorage.getItem('callId'));
  callId$ = this.callIdSubject.asObservable();

  setCallId(callId: string | null) {
    if (callId) {
      sessionStorage.setItem('callId', callId);
    } else {
      sessionStorage.removeItem('callId');
    }
    this.callIdSubject.next(callId);
  }
}